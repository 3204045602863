import React from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Dropdown,
    Badge
} from "react-bootstrap";
import { connect } from "react-redux";
import { setComputerDetails,setComputerAddEditStatus } from "../../../store/actions/Computers"
import { useImmer } from "use-immer";
import IssueCard from "./Issues/IssueCard"
import IssueAddEdit from "./Issues/IssueAddEdit";
import AddEdit from "../ComputerForm/AddEdit";
import { useEffect } from "react";
import {setIssueAddStatus,deleteIssue} from "../../../store/actions/issues"
const DetailsView = ({
    onBack,
    Computer,
    setComputerDetails,
    issues,
    issues_status,
    setIssueAddStatus,
    deleteIssue,
    add_status,
    setComputerAddEditStatus,
    isAdmin
}) => {
    const onCancel = () => {
        var result = []
        setComputerDetails(result);
        onBack();
    }

    const[show,setshow]= useImmer(false)

    const initial_state_issues = {
        type:"Add",
        issue_id:"",
        title:"",
        description:"",
        priority:"",
        status:"",
        issued_by: "",
        assignedTo: "",
        reason: ""
    };

    const[getIssueDetails,SetStateAddEdit]= useImmer(initial_state_issues)
    const onEditIssue = (data) =>{
        SetStateAddEdit((draft) => {
            draft.type= "Edit";
            draft.title = data.title;
            draft.description = data.description;
            draft.priority = data.priority;
            draft.status = data.status;
            draft.issue_id = data.issue_id;
            draft.issued_by = data.issued_by;
            draft.assignedTo = data.assignedTo;
            draft.reason = data.ReasonToResolve;
        });
        setshow(true)
    }
    const onDeleteIssue = (issue_id) =>{
        var data ={
            issue_id: issue_id,
            computer_id: Computer.length > 0? Computer[0].ComputerID:"" 
        }
        deleteIssue(data)
    }
    const onIssueCancel = () =>{
        setshow(false)
        SetStateAddEdit(initial_state_issues)
    }
    const OnAddEdit = () =>{
        setshow(true)
    }
    useEffect(()=>{
        if(issues_status){
            SetStateAddEdit(initial_state_issues)
            setshow(false)
            setIssueAddStatus(false)
        }
    },[issues_status])


    const [getAddEditModel, setAddEditModel] = useImmer(false);
    useEffect(() => {
        if (add_status == true) {
            setAddEditModel(false);
            setComputerAddEditStatus(false);
        }
    }, [add_status])
    const onHideAddModel = () => {
        setAddEditModel(false)
    }





    return (
        <>
            {
                Computer.length > 0 && (
                    <React.Fragment>
                        <Row>
                            <Col className="d-flex justify-content-start">
                            <Button
                                className="btn btn-primary my-1"
                                onClick={onCancel}
                                title="Go back"
                            >
                                <i className="bi bi-arrow-left"></i> back
                            </Button>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                {isAdmin && (
                                    <Button
                                size="sm"
                                className="btn btn-primary m-1"
                                onClick={() => {
                                    setAddEditModel(true);
                                }}
                                title="Add new issues"
                            >
                              <i className="bi bi-pencil-fill"></i>  Edit Computer
                            </Button>
                                )}
                            <Button
                                size="sm"
                                className="btn btn-primary m-1"
                                onClick={OnAddEdit}
                                title="Add new issues"
                            >
                              <i className="bi bi-plus"></i>  Add new issue
                            </Button>
                            </Col>
                            
                        </Row>

                        <Card className="mb-4">
                            <Card.Body>
                                <Row>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <strong>Computer Name:</strong> {Computer[0].ComputerName}
                                        </div>
                                        <div>
                                            {
                                                Computer[0].Username != null && (<><strong>Assigned to: </strong>{Computer[0].Username}</>)
                                            }
                                        </div>
                                    </div>

                                    <Dropdown.Divider />
                                    <Col xs={12}>

                                        <div>
                                            <strong>RAM: </strong>{Computer[0].RAM}
                                        </div>
                                        <div>
                                            <strong>Processor: </strong>{Computer[0].Processor}
                                        </div>
                                        <div>
                                            <strong>Operating system: </strong>{Computer[0].OS}  {" - " + Computer[0].licenceType}
                                        </div>
                                        <div>
                                            <strong>System type: </strong>{Computer[0].SystemType}
                                        </div>

                                    </Col>

                                    <Dropdown.Divider />
                                    <div>
                                        <strong>Description: </strong>{Computer[0].Description}
                                    </div>
                                </Row>

                                <Row>
                                    <div>
                                        <strong>Software List: </strong>
                                        {
                                            Computer.map((data) => {
                                                return (
                                                    <>
                                                        {data.SoftwareName == null ? (
                                                            <></>
                                                        ) : (
                                                            <>
                                                                {data.SoftwareType === "Licensed" ? (
                                                                    <Badge className="m-1" bg="success"> {data.SoftwareName}</Badge>
                                                                ) : data.SoftwareType === "OpenSource" ? (
                                                                    <Badge className="m-1" bg="primary"> {data.SoftwareName}</Badge>
                                                                ) : (
                                                                    <Badge className="m-1" bg="danger"> {data.SoftwareName}</Badge>
                                                                )
                                                                }


                                                            </>
                                                        )}
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                                <Row>
                                    <div>
                                        <strong>Hardware List:</strong>
                                        {
                                            Computer.map((data) => {
                                                return (
                                                    <>
                                                        {data.HardwareName == null ? (
                                                            <></>
                                                        ) : (
                                                            <Badge className="m-1"> {data.HardwareName}</Badge>
                                                        )}
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                        {issues.length > 0 && (<>
                            <Dropdown.Divider />
                            <strong> Issues:</strong>
                            {
                                issues.map((data) => {
                                    return (
                                        <IssueCard
                                            issue_id={data.issue_id}
                                            title={data.title}
                                            description={data.description}
                                            priority={data.priority}
                                            status={data.status}
                                            issued_date={data.created_date}
                                            IssuedPerson_Name={data.IssuedPerson_Name}
                                            assignee_Name={data.assignee_Name}
                                            reason={data.ReasonToResolve}
                                            issue={data}
                                            editIssue={onEditIssue}
                                            deleteIssue={onDeleteIssue}
                                        />
                                    )
                                })

                            }

                        </>
                        )
                        }
                       
                    </React.Fragment>
                )
            }
             <IssueAddEdit 
             onShow={show}
             onclose ={onIssueCancel}
             computer_id={Computer.length > 0? Computer[0].ComputerID:""}
             issues={getIssueDetails}
             />
             <AddEdit
                type ={"Edit"}
                onShow={getAddEditModel}
                onHide={onHideAddModel}
            />
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        Computer: state.computers.computerDetails.length > 0 ? state.computers.computerDetails : [],
        issues: state.computers.issuesDetails.length > 0 ? state.computers.issuesDetails : [],
        issues_status:  state.computers.issue_add_edit_status,
        add_status: state.computers.add_status
    };
};
export default connect(mapStateToProps, { setComputerDetails,setIssueAddStatus,deleteIssue,setComputerAddEditStatus })(DetailsView);
