import { SET_ALL_USER_EXPORT, SET_ALL_SOFTWARE_EXPORT, SET_ALL_HARDWARE_EXPORT, SET_ALL_COMPUTER_EXPORT } from "../actions/Export";
import produce from "immer";

const INITIAL_STATE = {
    allUsers: [],
    allSoftwares: [],
    allHardwares: [],
    allComputers: [],
};

export const exportReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_ALL_USER_EXPORT:
            draft.allUsers = action.payload;
            break;
        case SET_ALL_SOFTWARE_EXPORT:
            draft.allSoftwares = action.payload;
            break;
        case SET_ALL_HARDWARE_EXPORT:
            draft.allHardwares = action.payload;
            break;
        case SET_ALL_COMPUTER_EXPORT:
            draft.allComputers = action.payload;
            break;
        default:
            break;
    }
}, INITIAL_STATE);