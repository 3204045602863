import InputFormControl from "../../common/FormControl/InputFormControlComponent/InputFormControlComponent";
import DateFormControl from "../../common/FormControl/DateFormControlComponent/DateFormControlComponent";
import SelectFormControl from "../../common/FormControl/SelectFormControlComponent/SelectFormControlComponent";
import FilterButtonComponent from "../../common/Button/FilterButtonComponent/FilterButtonComponent";
import Collapsible from "react-collapsible";
import React from "react";
import PropTypes from "prop-types";
import {Row,Col,Container} from "react-bootstrap";
const FilterComponent = ({ filters, onApply, onClear }) => {
    const renderSwitchFormControl = (filter,index,maxhight) => {
      switch(filter.type) {
        case 'select':
          return <SelectFormControl 
          maxhight={maxhight}
          key={index}
          placeholder={filter.placeholder}
          value={filter.value}
          name={filter.name}
          options={filter.options}
          onChange={filter.onChange}
        />;
        case 'text':
          return <InputFormControl 
          key={index}
          placeholder={filter.placeholder}
          value={filter.value}
          name={filter.name}
          onChange={filter.onChange}
        />
        case 'date':
          return <DateFormControl 
          key={index}
          placeholder={filter.placeholder}
          value={filter.value}
          name={filter.name}
          onChange={filter.onChange}
        />
        default:
          return null;
      }
    }
    const maxhight=120;
    return (
      <React.Fragment>
        <div className="collapsibleMobileModeTeam">
          <Collapsible trigger="">
            <nav className="nav d-block justify-content-between">
              <Row>
                <Col xs={12}>
                  {filters.map((filter,index) => {
                    return renderSwitchFormControl(filter,index,maxhight);
                  })}
                </Col>
                <Col xs={12} align="center">
                  <FilterButtonComponent onApply={onApply} onClear={onClear} />
                </Col>
              </Row>
            </nav>
          </Collapsible>
        </div>
        <Container fluid className="navTeamArtistBgColor">
            <div className="navFilterSection">
              <Row>
                <nav className="pt-2 nav d-flex justify-content-between navMenuFullWidth">
                  <Col xs={12} md={10}>
                    <Row className="ml-1">
                      {filters.map((filter,index) => {
                        return <Col key={index} xs={12} md={3} className="mb-2">
                          {renderSwitchFormControl(filter,index)}
                        </Col>
                      })}
                    </Row>
                  </Col>
                  <Col xs={12} md={2} align="right">
                    <FilterButtonComponent onApply={onApply} onClear={onClear} />
                  </Col>
                </nav>
              </Row>
            </div>
        </Container>
      </React.Fragment>
    );
  };
  
  FilterComponent.propTypes = {
    filters: PropTypes.array.isRequired,
    onApply: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
  };
  
export default FilterComponent;