import React from "react";
import { Spinner, Row, Col } from "react-bootstrap";
import { getNotifications } from "../../store/actions/alert"
import  NotificationCard  from "./NotificationCard";
import { connect } from "react-redux";
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';

const AlertComponent = ({notifications,getNotifications}) => {
    useEffect(() => {
       getNotifications();
    },[]);
    return (
    <>
        <InfiniteScroll
                    dataLength={notifications.length}
                    style={{ overflowX: "hidden" }}
                    loader={
                      <h4 style={{ textAlign: "center" }}>
                        <Spinner as="span" animation="border" role="status" />
                      </h4>
                    }
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        {notifications.length !== 0 ? (
                          <>
                            {notifications.length > 5 && (
                              <b>No more alerts found</b>
                            )
        
                            }</>
        
                        ) : (
                          <b>No Alerts Found</b>
                        )}
                      </p>
                    }
                  >
                    <Row>
                      {notifications.length > 0 &&
                          notifications.map((notification, idx) => {
                            return (
                              <Row>
                                <NotificationCard
                                  key={idx}
                                  content = {notification.Content_String}
                                  CreatedDate = {notification.CreatedDate}
                                />
                              </Row>
                            )
                        })
                      }
            </Row>
        </InfiniteScroll>
    </>
    );
  };
  const mapStateToProps = (state) => {
    return {
      notifications: state.alert.list
    };
  };
  export default connect(mapStateToProps, {
    getNotifications
  })(AlertComponent);