import React from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Container } from 'react-bootstrap';
import CardComponent from "./UserCard/Card"
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "../Loader/Loader"

const UserView = ({
    users,
    onShowAddModel,
    getUserDetailView,
    editUser,
    deleteUser,
    getnextUsers,
    hasmore
}) => {
    return (
        <>
                <Row xs={1} md={1} className="">
                    <Col>
                        <InfiniteScroll
                            dataLength={users.length}
                            next={getnextUsers}
                            style={{ overflowX: "hidden" }}
                            hasMore={hasmore}
                            loader={
                                <h4 style={{ textAlign: "center" }}>
                                    <Spinner as="span" animation="border" role="status" />
                                </h4>
                            }
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {users.length !== 0 ? (
                                       <>
                                       {users.length > 5 && (
                                           <b>No more Users found</b>
                                       )

                                       }</>
                                    ) : (
                                        <b>No Users Found</b>
                                    )}
                                </p>
                            }
                        >
                            {
                                users.map((user, idx) => {
                                    return (
                                        <CardComponent
                                            key={idx}
                                            s_no={idx + 1}
                                            UserID={user.UserID}
                                            UserName={user.Username}
                                            Email={user.Email}
                                            Role={user.RoleName}
                                            Organisation={user.OrgID}
                                            CreatedDate={user.CreatedDate}
                                            UpdatedDate={user.UpdatedDate}
                                            getUserDetailView={getUserDetailView}
                                            editUser={editUser}
                                            user={user}
                                            deleteUser={deleteUser}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(UserView);