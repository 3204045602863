import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { CSVLink } from "react-csv"

import SoftwareView from "./SoftwareView";
import AddEdit from "./SoftwareForm/AddEdit";
import SoftwareDetails from './SoftwareDetails/DetailView'
import DeletePopup from "../Delete/Delete";
import FilterComponent from "../Filter/FilterComponent";
import { useImmer } from "use-immer";
import { setDelete } from "../../store/actions/Delete";
import { getAllSoftwareExport} from "../../store/actions/Export";
import { getSoftwares, setSoftwareAddEditStatus, getNextSoftwares, deleteSoftware, getSoftwareDetail } from "../../store/actions/Softwares";

import "./Software.css";


const SoftwarePage = ({
    isAdmin,
    softwareDetails,
    getSoftwares,
    nextPage,
    hasmore,
    add_status,
    deleteFlag,
    setSoftwareAddEditStatus,
    deleteSoftware,
    getNextSoftwares,
    setDelete,
    allSoftwares,
    getAllSoftwareExport
}) => {
    const [getAddEditModel, setAddEditModel] = useImmer(false);
    useEffect(() => {
        getSoftwares()
        const data = {
            full_result: 1
          }
          getAllSoftwareExport(data);
    }, [])

    // Details view
    const [showSoftwareList, setShowSoftwareList] = useImmer(true);
    const [showSoftwareDetails, setShowSoftwareDetail] = useImmer(false);
    var initial_State = {
        software_details: "",
    };
    const [getsoftwareDetails, setSoftwareDetails] = useImmer(initial_State);

    const getSoftwareDetailView = (software) => {
        setSoftwareDetails((draft) => {
            draft.software_details = software;
        });
        setShowSoftwareList(false);
        setShowSoftwareDetail(true);
        setFilterStatus(false);
    }
    const onBack = () => {
        setShowSoftwareList(true);
        setShowSoftwareDetail(false);
        setFilterStatus(true);
    }


    // Edit flow
    var initial_State = {
        software_details: {
            softwareId: "",
            status: "",
            type: "Add",
            Description: "",
            SoftwareName: "",
            SerialNumber: ""
        }
    };
    const [stateAddEdit, SetStateAddEdit] = useImmer(initial_State);
    useEffect(() => {
        if (add_status = true) {
            setAddEditModel(false)
            setSoftwareAddEditStatus(false);
            SetStateAddEdit(initial_State)
        }
    }, [add_status])

    const onShowAddModel = () => {
        SetStateAddEdit((draft) => {
            draft.software_details.Description = "";
            draft.software_details.SerialNumber = "";
            draft.software_details.SoftwareName = "";
            draft.software_details.Softwaretype = "";
            draft.software_details.type = "Add";
        });
        setAddEditModel(true)
    }
    const onHideAddModel = () => {
        setAddEditModel(false)
        SetStateAddEdit(initial_State)
    }
    const onShowEditModel = (software) => {
        SetStateAddEdit((draft) => {

            draft.software_details.status = software.Status;
            draft.software_details.softwareId = software.SoftwareID;
            draft.software_details.Description = software.Description;
            draft.software_details.SerialNumber = software.SerialNumber;
            draft.software_details.SoftwareName = software.SoftwareName;
            draft.software_details.SoftwareType = software.SoftwareType;
            draft.software_details.type = "Edit";
        });
        setAddEditModel(true)
    }
    // Delete Screen
    const [show, setShow] = useImmer(false);
    const deleteInitialState = {
        id: ''
    }
    const [deleteState, setDeleteState] = useImmer(deleteInitialState)

    const onDeleteSoftware = (id) => {
        setShow(true);
        setDeleteState((draft) => {
            draft.id = id;
        })
    }
    const confirmDelete = () => {
        const data = {
            softwareId: deleteState.id
        }
        deleteSoftware(data);
    }
    const onCancel = () => {
        setShow(false);
    }

    useEffect(() => {
        if (deleteFlag) {
            setShow(false);
        }
        setDelete(false)
    }, [deleteFlag])

    // To get next Software
    const getnextSoftwares = () => {
        var data = {
            page: nextPage,
            ...getFilterValues()
        }
        getNextSoftwares(data)
    }

    // This for filter
    const [showFilterStatus, setFilterStatus] = useImmer(true);

    const handleFilterChange = (name, e) => {
        switch (name) {
            case "softwareType":
                setFilter((draft) => {
                    const licence_index = draft.findIndex((d) => d.name === "softwareType");
                    if (licence_index !== -1) {
                        // Set team value as current value
                        draft[licence_index].value = e;
                    }
                });
                break;
            case "keyword":
                setFilter((draft) => {
                    const search_index = draft.findIndex((d) => d.name === "keyword");
                    if (search_index !== -1) {
                    // Set venue value as current value
                    draft[search_index].value = e.target.value;
                    }
                });
                break;
            default:
                break;
        }
    };
    const filterInitialState = [
        {
            placeholder: "Search",
            type: "text",
            value: null,
            name: "keyword",
            options: [],
            onChange: handleFilterChange,
        },
        {
            placeholder: "Software type",
            type: "select",
            value: null,
            name: "softwareType",
            options: [
                {
                    id: "",
                    value: "",
                    label: "All"
                },
                {
                    id: "Licensed",
                    value: "Licensed",
                    label: "Licensed"
                },
                {
                    id: "OpenSource",
                    value: "OpenSource",
                    label: "OpenSource"
                },
                {
                    id: "Pirated",
                    value: "Pirated",
                    label: "Pirated"
                }

            ],
            onChange: handleFilterChange,
        }
    ]
    const [getFilter, setFilter] = useImmer(filterInitialState)
    const getFilterValues = () => {
        var filter_values = {};
        getFilter.forEach((f) => {
            if (f.value) {
                filter_values[f.name] = f.type === "select" ? f.value.id : f.value;            
            }
        });
        return filter_values;
    };
    const filterSubmit = () => {
        let keyVal = {
            ...getFilterValues(),
        };
        getSoftwares(keyVal);
    }
    const reset = () => {
        // Set all filter values to null
        setFilter((draft) => {
            draft.forEach((f) => (f.value = ""));
        });
        getSoftwares();
    }

// Export User
var exportInitialState = {
    allList : [],
    filteredList : []
}
const [getAllExport,SetAllExport] = useImmer(exportInitialState)
    useEffect(() => {
        var ExcelAllList = allSoftwares.map((software,idx) => {
            return { 
              S_No:idx+1,
              SoftwareName:software.SoftwareName,
              SerialNumber:software.SerialNumber,
              Description:software.Description
            };
          });
          SetAllExport((draft) => {
            draft.allList = ExcelAllList;
        })
        var ExcelfilteredList = softwareDetails.map((software,idx) => {
            return { 
                S_No:idx+1,
                SoftwareName:software.SoftwareName,
                SerialNumber:software.SerialNumber,
                Description:software.Description
            };
          });
          SetAllExport((draft) => {
            draft.filteredList = ExcelfilteredList;
        })
    }, [allSoftwares,softwareDetails])

    return (
        <>
            <DeletePopup
                confirmDelete={confirmDelete}
                show={show}
                onCancel={onCancel}
            />
            <AddEdit
                onShow={getAddEditModel}
                onHide={onHideAddModel}
                software={stateAddEdit.software_details}
            />
            <Row>
                <Col className="d-flex justify-content-end">
                    {isAdmin && (
                        <Button className="pull-right mb-2 m-1" variant="primary" size="sm" onClick={() => {
                            onShowAddModel();
                        }}>
                            <i className="bi bi-plus"></i> Add new
                        </Button>
                    )}

                    <CSVLink data={getAllExport.filteredList} filename={"softwaresList.xls"}>
                        <Button className="m-1" variant="primary" size="sm">
                            <i className="bi bi-download"></i> Export
                        </Button>
                    </CSVLink>
                    <CSVLink data={getAllExport.allList} filename={"softwaresList_all.xls"}>
                        <Button className="m-1" variant="primary" size="sm">
                            <i className="bi bi-download"></i> Export all
                        </Button>
                    </CSVLink>
                </Col>
            </Row>
            <Row>
                <Col>
                    { showFilterStatus &&
                        <FilterComponent
                            filters={getFilter}
                            onApply={filterSubmit}
                            onClear={reset}
                        />
                    }
                </Col>
            </Row>
            <>
                {showSoftwareList &&
                    <SoftwareView
                        isAdmin={isAdmin}
                        softwares={softwareDetails}
                        onShowAddModel={onShowAddModel}
                        getSoftwareDetailView={getSoftwareDetailView}
                        editSoftware={onShowEditModel}
                        deleteSoftware={onDeleteSoftware}
                        getnextSoftwares={getnextSoftwares}
                        hasmore={hasmore}
                    />
                }
                {showSoftwareDetails &&
                    <SoftwareDetails
                        Software={getsoftwareDetails.software_details}
                        onBack={onBack}
                    />
                }
            </>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.profile.RoleID == "1" ? true : false,
        softwareDetails: state.softwares.softwares.list,
        add_status: state.softwares.add_status,
        deleteFlag: state.delete.deleteFlag == "software" ? true : false,
        nextPage: state.softwares.softwares.page,
        hasmore: state.softwares.softwares.hasmore,
        allSoftwares: state.allList.allSoftwares.length > 0 ? state.allList.allSoftwares : [],
    };
};

export default connect(mapStateToProps, { getSoftwares, getNextSoftwares, deleteSoftware, setSoftwareAddEditStatus, setDelete, getAllSoftwareExport })(SoftwarePage);