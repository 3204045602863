import {
    FORGOT,
    FORGOT_SUCCESS,
    FORGOT_ERROR,
    forgetPasswordSuccess,
    forgetPasswordError,
    setforgetPasswordStatus
} from "../../actions/Forgot"
import { apiRequest } from "../../actions/api";
import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
export const ForgotFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === FORGOT) {
        dispatch(
            apiRequest(
                "POST",
                "/apiCall.php",
                null,
                action.payload,
                forgetPasswordSuccess,
                forgetPasswordError,
                false,
                "[Forgot]"
            )
        );
    }
};

export const processForgot = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === FORGOT_SUCCESS) {
        var result = action.payload.status
        dispatch(setforgetPasswordStatus(result))
        dispatch(
            setNotification(
                "Please check registered email",
                "success",
                notification_types.success
            )
        );
    }

    if (action.type === FORGOT_ERROR) {

    }
};



export const forgotMdl = [
    ForgotFlow,
    processForgot
]