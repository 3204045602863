export const GET_PROFILE                        = "[Profile] Get Profile";
export const GET_PROFILE_SUCCESS                = "[Profile] Get Profile success";
export const GET_PROFILE_ERROR                  = "[Profile] Get Profile error";
export const SET_PROFILE                        = '[Profile] Set Profile ';
export const UPDATE_PROFILE                        = "[Profile] Update Profile";
export const UPDATE_PROFILE_SUCCESS                = "[Profile] Update Profile success";
export const UPDATE_PROFILE_ERROR                  = "[Profile] Update Profile error";
export const SET_UPDATE_PROFILE                    = '[Profile] Set Update Profile ';

export const getProfile = (data) => ({
    type: GET_PROFILE,
    payload: data
  });
  export const getProfileSuccess = (data) => ({
    type: GET_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const getProfileError = (data) => ({
    type: GET_PROFILE_ERROR,
    payload: data,
  });
  
  export const setProfile = (data) => ({
    type: SET_PROFILE,
    payload: data,
  });

  export const updateProfile = (data) => ({
    type: UPDATE_PROFILE,
    payload: data
  });
  export const updateProfileSuccess = (data) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const updateProfileError = (data) => ({
    type: UPDATE_PROFILE_ERROR,
    payload: data,
  });
  
  export const setupdateProfile = (data) => ({
    type: SET_UPDATE_PROFILE,
    payload: data,
  });

  
  