export const GETHARDWARES                        = "[Hardwares] Hardwares";
export const GETHARDWARES_SUCCESS                = "[Hardwares] Hardwares success";
export const GETHARDWARES_ERROR                  = "[Hardwares] Hardwares error";
export const SET_HARDWARES                        = "[Hardwares] Hardwares set";

export const GET_NEXT_HARDWARES                        = "[Hardwares] nextHardwares ";
export const GET_NEXT_HARDWARES_SUCCESS                = "[Hardwares] nextHardwares success";
export const GET_NEXT_HARDWARES_ERROR                  = "[Hardwares] nextHardwares error";
export const SET_NEXT_HARDWARES                       = "[Hardwares] nextHardwares set";

export const GET_HARDWARE_DETAIL                  ="[Hardwares] get Hardware detail";
export const GET_HARDWARE_DETAIL_SUCCESS          ="[Hardwares] get Hardware detail success";
export const GET_HARDWARE_DETAIL_ERROR            ="[Hardwares] get Hardware detail error";
export const SET_HARDWARE_DETAIL                  ="[Hardwares] set Hardware detail";

export const ADD_HARDWARES                       = "[Hardwares] Hardwares add";
export const ADD_HARDWARES_SUCCESS               = "[Hardwares] Hardwares add success";
export const ADD_HARDWARES_ERROR                 = "[Hardwares] Hardwares add error";
export const SET_HARDWARES_ADD_STATUS            = "[Hardwares] Hardwares add set";
export const UPDATE_HARDWARES                       = "[Hardwares] Hardwares update";
export const UPDATE_HARDWARES_SUCCESS               = "[Hardwares] Hardwares update success";
export const UPDATE_HARDWARES_ERROR                 = "[Hardwares] Hardwares update error";
export const DELETE_HARDWARES                       = "[Hardwares] Hardwares delete";
export const DELETE_HARDWARES_SUCCESS               = "[Hardwares] Hardwares delete success";
export const DELETE_HARDWARES_ERROR                 = "[Hardwares] Hardwares delete error";
// For user login

export const getHardwares = (data) => ({
    type: GETHARDWARES,
    payload: data
  });
  export const getHardwaresSuccess = (data) => ({
    type: GETHARDWARES_SUCCESS,
    payload: data,
  });
  
  export const getHardwaresError = (data) => ({
    type: GETHARDWARES_ERROR,
    payload: data,
  });

  export const setHardwares = (data) => ({
    type: SET_HARDWARES,
    payload: data,
  });

  export const getNextHardwares = (data) => ({
    type: GET_NEXT_HARDWARES,
    payload: data
  });
  export const getNextHardwaresSuccess = (data) => ({
    type: GET_NEXT_HARDWARES_SUCCESS,
    payload: data,
  });
  
  export const getNextHardwaresError = (data) => ({
    type: GET_NEXT_HARDWARES_ERROR,
    payload: data,
  });

  export const setNextHardwares = (data) => ({
    type: SET_NEXT_HARDWARES,
    payload: data,
  });

  export const addHardware = (data) => ({
    type: ADD_HARDWARES,
    payload: data,
  });

  export const addHardwareSuccess = (data) => ({
    type: ADD_HARDWARES_SUCCESS,
    payload: data,
  });

  export const addHardwareError = (data) => ({
    type: ADD_HARDWARES_ERROR,
    payload: data,
  });
  export const setHardwareAddEditStatus = (data) => ({
    type: SET_HARDWARES_ADD_STATUS,
    payload: data,
  });

  export const getHardwareDetail = (data) => ({
    type: GET_HARDWARE_DETAIL,
    payload: data
  })
  export const getHardwareDetailSuccess = (data) => ({
    type: GET_HARDWARE_DETAIL_SUCCESS,
    payload: data
  })
  export const getHardwareDetailError = (data) => ({
    type: GET_HARDWARE_DETAIL_ERROR,
    payload: data
  })
  export const setHardwareDetail = (data) => ({
    type: SET_HARDWARE_DETAIL,
    payload: data
  })

  export const updateHardware = (data) => ({
    type: UPDATE_HARDWARES,
    payload: data,
  });

  export const updateHardwareSuccess = (data) => ({
    type: UPDATE_HARDWARES_SUCCESS,
    payload: data,
  });

  export const updateHardwareError = (data) => ({
    type: UPDATE_HARDWARES_ERROR,
    payload: data,
  });
  export const deleteHardware = (data) => ({
    type: DELETE_HARDWARES,
    payload: data,
  });

  export const deleteHardwareSuccess = (data) => ({
    type: DELETE_HARDWARES_SUCCESS,
    payload: data,
  });

  export const deleteHardwareError = (data) => ({
    type: DELETE_HARDWARES_ERROR,
    payload: data,
  });
