import {
    LIST_ISSUE,
    LIST_ISSUE_SUCCESS,
    LIST_ISSUE_ERROR,
    getIssuesSuccess,
    getIssuesError,
    setIssueslist,

    LIST_NEXT_ISSUE,
    LIST_NEXT_ISSUE_SUCCESS,
    LIST_NEXT_ISSUE_ERROR,
    getNextIssuesSuccess,
    getNextIssuesError,


    ADD_ISSUE,
    ADD_ISSUE_SUCCESS,
    ADD_ISSUE_ERROR,
    addIssueSuccess,
    addIssueError,
    setIssueAddStatus,
    UPDATE_ISSUE,
    UPDATE_ISSUE_SUCCESS,
    UPDATE_ISSUE_ERROR,
    updateIssueSuccess,
    updateIssueError,
    DELETE_ISSUE,
    DELETE_ISSUE_SUCCESS,
    DELETE_ISSUE_ERROR,
    deleteIssueSuccess,
    deleteIssueError
} from "../../actions/issues";
import {getDetailsForEditComputer} from "../../actions/Computers"
import { apiRequest } from "../../actions/api";
import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";



export const getIssuesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LIST_ISSUE) {
    dispatch(
      apiRequest(
        "GET",
        "/apiCall.php?apiCall=getAllIssues",
        action.payload,
        null,
        getIssuesSuccess,
        getIssuesError,
        false,
        "[Issue]"
      )
    );
  }
};

export const processgetIssues = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LIST_ISSUE_SUCCESS) {
    //if(action.payload.data.length){
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const result = {
        list: action.payload.data,
        page: action.payload.next,
        hasmore:is_hasmore
      }
      dispatch(setIssueslist(result));
    // } else {
    //   dispatch(setUserExists(false));
    // }
  }

  if (action.type === LIST_ISSUE_ERROR) {
   
  }
};

export const getNextIssuesFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LIST_NEXT_ISSUE) {
    dispatch(
      apiRequest(
        "GET",
        "/apiCall.php?apiCall=getAllIssues",
        action.payload,
        null,
        getNextIssuesSuccess,
        getNextIssuesError,
        false,
        "[Issue]"
      )
    );
  }
};

export const processgetNextIssues = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type === LIST_NEXT_ISSUE_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const NextList = action.payload.data
      const prevList = getState().issues.Issues.list;
      const currList = [...prevList, ...NextList];
      const newData = {
        list: currList,
        page:action.payload.next,
        hasmore:is_hasmore
      };
      dispatch(setIssueslist(newData));
  }

  if (action.type === LIST_NEXT_ISSUE_ERROR) {
   
  }
};

export const addissueFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ADD_ISSUE) {
        dispatch(
            apiRequest(
                "POST",
                "/apiCall.php?apiCall=addIssues",
                null,
                action.payload,
                addIssueSuccess,
                addIssueError,
                false,
                "[Issue]"
            )
        );
    }
};

export const processaddissue= ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ADD_ISSUE_SUCCESS) {
        var status =action.payload.status
        if(status == true){
          const data = {
            computerId: action.payload.data
        }
        dispatch(getDetailsForEditComputer(data));
            dispatch(setIssueAddStatus(true));
            dispatch(
              setNotification(
                "Issue added",
                "Success",
                notification_types.success
              )
            );
          }
    }

    if (action.type === ADD_ISSUE_ERROR) {

    }
};
export const updateissueFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === UPDATE_ISSUE) {
        dispatch(
            apiRequest(
                "POST",
                "/apiCall.php?apiCall=updateIssues",
                null,
                action.payload,
                updateIssueSuccess,
                updateIssueError,
                false,
                "[Issue]"
            )
        );
    }
};

export const processupdateissue= ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === UPDATE_ISSUE_SUCCESS) {
        var status =action.payload.status
        if(status == true){
            const data = {
              computerId: action.payload.data
          }
          dispatch(getDetailsForEditComputer(data));
            dispatch(setIssueAddStatus(true));
            dispatch(
              setNotification(
                "Issue updated",
                "Success",
                notification_types.success
              )
            );
          }
    }

    if (action.type === UPDATE_ISSUE_ERROR) {

    }
};
export const deleteIssueFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === DELETE_ISSUE) {
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php?apiCall=deleteIssue",
          action.payload,
          null,
          deleteIssueSuccess,
          deleteIssueError,
          false,
          "[Issue]"
        )
      );
    }
  };
  
  export const processdeleteIssue = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === DELETE_ISSUE_SUCCESS) {
        const data = {
          computerId: action.payload.data
      }
      dispatch(getDetailsForEditComputer(data));
      dispatch(setIssueAddStatus(true));
      dispatch(
        setNotification(
          "Issue deleted",
          "Success",
          notification_types.success
        )
      );
    }
  
    if (action.type === DELETE_ISSUE_ERROR) {
    }
  };


export const issuesMdl = [
    getIssuesFlow,
    processgetIssues,
    getNextIssuesFlow,
    processgetNextIssues,
    addissueFlow,
    processaddissue,
    updateissueFlow,
    processupdateissue,
    deleteIssueFlow,
    processdeleteIssue
];