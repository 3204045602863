import { Card, Row, Col, Dropdown, NavLink, Badge } from "react-bootstrap"
import { Link } from "react-router-dom";
import "./Dashboard.css"
const EmployeeDesignCard = ({ type, computerName, username, computerid, onComputerView, licenceType,openIssueCount }) => {
    return (
        <Card
            style={{ cursor: "pointer", }}
            className="text-center cards"
            onClick={(e) => {
                e.preventDefault();
                onComputerView(computerid);
            }}
        >
            <Card.Body >
                <Row>
               
                    {openIssueCount !=0 ?
                     <Col className="d-flex justify-content-start" title="Not closed issues">
                        <span><i className="bi bi-bug-fill customIcon"></i> 
                {"   "+openIssueCount }</span>
                </Col>
                    :
                    <Col className="d-flex justify-content-start" title="No issues">
                    <span><i className="bi bi-check2-circle customIcon"></i></span> 
                    </Col>
                    }
                
                <Col className="d-flex justify-content-end" title={licenceType}>
                    {licenceType === "Licensed" ? (
                        <i className="bi bi-circle-fill" style={{ color: "green", fontSize: 10 }}></i>
                    ) : licenceType === "OpenSource" ? (
                        <i className="bi bi-circle-fill" style={{ color: "blue", fontSize: 10 }}></i>
                    ) : (
                        <i className="bi bi-circle-fill" style={{ color: "red", fontSize: 10 }}></i>
                    )
                    }
                </Col>
                </Row>
                <Col>
                    <Row>
                        {type === "PC" ? (
                            <i className="bi bi-pc-display Computericon"></i>
                        ) : (<i className="bi bi-laptop Computericon"></i>)
                        }
                        <h4>{computerName}</h4>
                    </Row>
                </Col>
                <Dropdown.Divider />
                <i className="bi bi-person-fill"></i> {username}
            </Card.Body>
        </Card>
    )

}
export default EmployeeDesignCard