export const GETCOMPUTERS                        = "[Computers] Computers";
export const GETCOMPUTERS_SUCCESS                = "[Computers] Computers success";
export const GETCOMPUTERS_ERROR                  = "[Computers] Computers error";
export const SET_COMPUTERS                       = "[Computers] Computers set";

export const GET_NEXT_COMPUTERS                   = "[Computers] nextComputers ";
export const GET_NEXT_COMPUTERS_SUCCESS           = "[Computers] nextComputers success";
export const GET_NEXT_COMPUTERS_ERROR             = "[Computers] nextComputers error";
export const SET_NEXT_COMPUTERS                   = "[Computers] nextComputers set";

export const ADD_COMPUTERS                       = "[Computers] Computers Add";
export const ADD_COMPUTERS_SUCCESS               = "[Computers] Computers Add Success";
export const ADD_COMPUTERS_ERROR                 = "[Computers] Computers Add error";
export const SET_COMPUTER_ADD_STATUS             = "[Computers] Computers Add set";
export const UPDATE_COMPUTER                     = "[Computers] Computers update";
export const UPDATE_COMPUTER_SUCCESS             = "[Computers] Computers update success";
export const UPDATE_COMPUTER_ERROR               = "[Computers] Computers update error";
export const GET_COMPUTER_DETAILS                = "[Computers] Get computer details";
export const SET_COMPUTER_DETAILS                = "[Computers] Set computer details";
export const SET_ISSUES_DETAILS                  = "[Computers] Set issues details";
export const GET_COMPUTER_DETAILS_SUCCESS        = "[Computers] Get computer details Success";
export const GET_COMPUTER_DETAILS_ERROR          = "[Computers] Get computer details error";
export const DELETE_COMPUTERS                    = "[Computers] User delete";
export const DELETE_COMPUTERS_SUCCESS            = "[Computers] Computer delete success";
export const DELETE_COMPUTERS_ERROR              = "[Computers] Computer delete error";

// For user login

export const getComputers = (data) => ({
    type: GETCOMPUTERS,
    payload: data
  });
  export const getComputersSuccess = (data) => ({
    type: GETCOMPUTERS_SUCCESS,
    payload: data,
  });
  
  export const getComputersError = (data) => ({
    type: GETCOMPUTERS_ERROR,
    payload: data,
  });

  export const setComputers = (data) => ({
    type: SET_COMPUTERS,
    payload: data,
  });

  export const getNextComputers = (data) => ({
    type: GET_NEXT_COMPUTERS,
    payload: data
  });
  export const getNextComputersSuccess = (data) => ({
    type: GET_NEXT_COMPUTERS_SUCCESS,
    payload: data,
  });
  
  export const getNextComputersError = (data) => ({
    type: GET_NEXT_COMPUTERS_ERROR,
    payload: data,
  });

  export const setNextComputers = (data) => ({
    type: SET_NEXT_COMPUTERS,
    payload: data,
  });


  export const setComputerAddEditStatus = (data) => ({
    type: SET_COMPUTER_ADD_STATUS,
    payload: data,
  })

  export const addComputers = (data) => ({
    type: ADD_COMPUTERS,
    payload: data
  });

  export const addComputersSuccess = (data) => ({
    type: ADD_COMPUTERS_SUCCESS,
    payload: data
  })

  export const addComputersError = (data) => ({
    type: ADD_COMPUTERS_ERROR,
    payload: data
  })

  export const updateComputers = (data) => ({
    type: UPDATE_COMPUTER,
    payload: data
  });

  export const updateComputersSuccess = (data) => ({
    type: UPDATE_COMPUTER_SUCCESS,
    payload: data
  })

  export const updateComputersError = (data) => ({
    type: UPDATE_COMPUTER_ERROR,
    payload: data
  })

  export const getDetailsForEditComputer = (data) => ({
    type: GET_COMPUTER_DETAILS,
    payload: data
  })

  export const setComputerDetails = (data) => ({
    type: SET_COMPUTER_DETAILS,
    payload: data
  })
  export const setIssuesDetails = (data) => ({
    type: SET_ISSUES_DETAILS,
    payload: data
  })

  export const getComputerDetailsSuccess = (data) => ({
    type: GET_COMPUTER_DETAILS_SUCCESS,
    payload: data
  })
  export const getComputerDetailsError = (data) => ({
    type: GET_COMPUTER_DETAILS_ERROR,
    payload: data
  })

  export const deleteComputers = (data) => ({
    type: DELETE_COMPUTERS,
    payload: data
  })

  export const deleteComputersSuccess = (data) => ({
    type: DELETE_COMPUTERS_SUCCESS,
    payload: data
  })

  export const deleteComputersError = (data) => ({
    type: DELETE_COMPUTERS_ERROR,
    payload: data
  })

