import React from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import ProfileView from "./ProfileView";
function ProfilePage({ username, userEmail }) {
   
    return (
        <React.Fragment>
                <ProfileView
                    username={username}
                    userEmail={userEmail}
                />
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        username: state.profile.Username ? state.profile.Username : "",
        userEmail: state.profile.Email ? state.profile.Email : ""
    };
};
export default connect(mapStateToProps, null)(ProfilePage);
