import React from "react";
import { Router, Route, Routes } from "react-router-dom";
import {Container,Row,Col}from "react-bootstrap";
import Dashboard from "../Dashboard/Dashboard"
import Computer from "../Computer/ComputerPage"
import Hardware from "../Hardware/HardwarePage"
import Software from "../Software/SoftwarePage"
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import Footer from '../Footer/Footer';
import User from '../Users/UsersPage';
import Profile from '../Profile/ProfilePage';
import Issues from '../Issues/IssuesPage'
import Notification from '../Notifications/Alert'


const MainLayout = () => {
  return (
    <>
        <header className="container-fluid p-0 m-0">
          <Header />
        </header>
      <main>
        <section>
          <Container fluid>
            <Row>
                <Col
                  md={3}
                  lg={2}
                  className="d-none d-md-block my-4 pl-0"
                >
                  <SideBar />
                </Col>
             
              <Col md={9} lg={10} className="my-5 pt-4 ">
                  <Routes>
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route exact path="/computer" element={<Computer />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route exact path="/user" element={<User />} />
                    <Route exact path="/software" element={<Software />} />
                    <Route exact path="/hardware" element={<Hardware />} />
                    <Route exact path="/setting" element={<Computer />} />
                    <Route exact path="/issues" element={<Issues />} />
                    <Route exact path="/notification" element={<Notification />} />
                  </Routes>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
        <footer className="container-fluid p-0 m-0">
          <Footer />
        </footer>
    </>
  );
};

export default MainLayout;
