import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import {
	Modal,
	Offcanvas,
	Form,
	Container,
	Row,
	Col,
	Button
} from "react-bootstrap";
import { addSoftware, updateSoftware } from "../../../store/actions/Softwares"

const AddEdit = ({
	onShow,
	onHide,
	addSoftware,
	software,
	updateSoftware
}) => {

	var addinitial_state = {
		type: "Add",
		softwareId: "",
		status: "",
		softwareName: "",
		serialNumber: "",
		description: "",
		softwareType: "",
		error: {
			softwareName: "",
			serialNumber: "",
			description: "",
			softwareType:""
		},
	};
	// Set State value using immer
	const [software_form, setSoftware_form] = useImmer(addinitial_state);
	useEffect(() => {
		if (software) {
			setSoftware_form((draft) => {
				draft.status = software.status !== null ? software.status :"";
				draft.softwareId = software.softwareId;
				draft.description = software.Description;
				draft.serialNumber = software.SerialNumber;
				draft.softwareName = software.SoftwareName;
				draft.softwareType = software.SoftwareType;
				draft.type = software.type;
			});
		}
	}, [software])

	const onInputChange = (e) => {
		setSoftware_form((draft) => {
			draft[e.target.name] = e.target.value;
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (software_form.softwareName.length === 0) {
			setSoftware_form((draft) => {
				draft.error.softwareName = "Please enter the software name.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.softwareName = "";
			});
		}

		if (software_form.softwareName.length > 256) {
			setSoftware_form((draft) => {
				draft.error.softwareName = "Please enter upto 256 characters for software name.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.softwareName = "";
			});
		}
		if (software_form.softwareType == undefined || software_form.softwareType== 0) {
			setSoftware_form((draft) => {
				draft.error.softwareType = "Please select software type.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.softwareType = "";
			});
		}
		if (software_form.serialNumber.length === 0) {
			setSoftware_form((draft) => {
				draft.error.serialNumber = "Please enter the serial number.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.serialNumber = "";
			});
		}

		if (software_form.serialNumber.length > 256) {
			setSoftware_form((draft) => {
				draft.error.serialNumber = "Please enter upto 256 characters for serial number.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.serialNumber = "";
			});
		}
		if (software_form.description.length > 256) {
			setSoftware_form((draft) => {
				draft.error.description = "Please enter upto 256 characters for description.";
			});
			return false;
		} else {
			setSoftware_form((draft) => {
				draft.error.description = "";
			});
		}
		if (software_form.type == "Add") {
			const data = {
				softwareName: software_form.softwareName,
				serialNumber: software_form.serialNumber,
				description: software_form.description,
				softwareType: software_form.softwareType,
				apiCall: "addSoftware"
			};
			addSoftware(data);
		} else if (software_form.type == "Edit") {
			const data = {
				status: software_form.status,
				softwareId: software_form.softwareId,
				softwareName: software_form.softwareName,
				serialNumber: software_form.serialNumber,
				description: software_form.description,
				softwareType: software_form.softwareType,
				apiCall: "updateSoftware"
			};
			updateSoftware(data);
		}

	};
	const onclose = () => {
		setSoftware_form(addinitial_state)
		onHide()
	}
	return (
		<>
			<React.Fragment>
				<Offcanvas

					show={onShow}
					onHide={onclose}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					placement="end"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<h6 className="font-weight-bold">{software_form.type} software</h6>
						</Modal.Title>
					</Modal.Header>
					<Form>
						<Modal.Body>
							<Container>
								<Col>
									<Form.Group className="mb-3" controlId="formBasicFirstName">
										<Form.Label className="font-weight-bold requiredField">
											Software name
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Enter software name"
											value={software_form.softwareName}
											name="softwareName"
											className={software_form.error.softwareName.length > 0 ? "is-invalid" : ""}
											onChange={onInputChange}
											autoComplete="off"
										/>
										{software_form.error.softwareName.length > 0 && (
											<span className="invalid-feedback">{software_form.error.softwareName}</span>
										)}
									</Form.Group>
								</Col>
								<Col  >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Software type
                                            </Form.Label>
                                            <Form.Select name="softwareType"
                                                className={
                                                    software_form.error.softwareType.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                value={software_form.softwareType}
                                                onChange={onInputChange}>
                                                <option value="0">Select type</option>
                                                <option value='Licensed'>Licensed</option>
                                                <option value='OpenSource'>Open Source</option>
												<option value='Pirated'>Pirated</option>
                                            </Form.Select>
                                            {software_form.error.softwareType.length > 0 && (
                                                <span className="invalid-feedback">{software_form.error.softwareType}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
								<Col>
									<Form.Group className="mb-3 " controlId="formBasicLastName">
										<Form.Label className="font-weight-bold requiredField">
											Serial number
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Enter serial number"
											value={software_form.serialNumber}
											name="serialNumber"
											className={software_form.error.serialNumber.length > 0 ? "is-invalid" : ""}
											onChange={onInputChange}
											autoComplete="off"
										/>
										{software_form.error.serialNumber.length > 0 && (
											<span className="invalid-feedback">{software_form.error.serialNumber}</span>
										)}
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="formBasicLastName">
										<Form.Label className="font-weight-bold requiredField">
											Description
										</Form.Label>
										<Form.Control
											as="textarea"
											placeholder="Enter description"
											className={software_form.error.description.length > 0 ? "is-invalid" : ""}
											value={software_form.description}
											name="description"
											onChange={onInputChange}
											autoComplete="off"
										/>
										{software_form.error.description.length > 0 && (
											<span className="invalid-feedback">{software_form.error.description}</span>
										)}
									</Form.Group>
								</Col>
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button
								type="submit"
								className="btnModelSubmit"
								onClick={handleSubmit}
							>
								{software_form.type == "Add" ? ("Save") : ("Update")}
							</Button>

							<Button className="btnModelClose" onClick={onclose}>
								Close
							</Button>
						</Modal.Footer>
					</Form>
				</Offcanvas>
			</React.Fragment>
		</>
	);
};


const mapStateToProps = (state) => {
	return {
	};
};

export default connect(mapStateToProps,
	{ addSoftware, updateSoftware }
)(AddEdit);