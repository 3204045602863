import React from "react";
import { connect } from "react-redux";
import {Card,Col,Row,Button,ButtonGroup} from 'react-bootstrap';

const UserCard = ({ s_no,UserName,UserID,Email,Role,Organisation, CreatedDate, UpdatedDate, getUserDetailView, user, editUser, deleteUser}) => {
    return (
        <Card className="cards" >
            <Card.Header style={{ cursor: "pointer", }} onClick={(e) => {
                                    e.preventDefault();
                                    getUserDetailView(user)
                                }}>{UserName}</Card.Header>
            <Card.Body>
                <Card.Text>
                    <Row className="justify-content-between">
                        <Col sm={4} md={2} >{Email} </Col>
                        <Col sm={4} md={2} >{Role} </Col>
                        <Col className="order-last" sm={4} md={2} >
                            <ButtonGroup className="float-right">
                                <Button variant="outline-primary" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    getUserDetailView(user)
                                }}><i className="bi bi-eye-fill"></i></Button>
                                <Button variant="outline-dark" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    editUser(user);
                                }}><i className="bi bi-pencil-fill"></i></Button>
                                <Button variant="outline-danger" size="sm" onClick={(e)=> {
                                    e.preventDefault();
                                    deleteUser(UserID)}}><i className="bi bi-trash-fill"></i></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(UserCard);