export const LIST_ISSUE                  ="[Issue] list issues";
export const LIST_ISSUE_SUCCESS          ="[Issue] list issues Success";
export const LIST_ISSUE_ERROR            ="[Issue] list issues error";
export const SET_ISSUE_LIST              ="[Issue] set issues list";

export const LIST_NEXT_ISSUE             ="[Issue] list next issues";
export const LIST_NEXT_ISSUE_SUCCESS     ="[Issue] list next issues Success";
export const LIST_NEXT_ISSUE_ERROR       ="[Issue] list next issues error";

export const ADD_ISSUE                  ="[Issue] add issues";
export const ADD_ISSUE_SUCCESS          ="[Issue] add issues Success";
export const ADD_ISSUE_ERROR            ="[Issue] add issues error";
export const SET_ISSUEADD_STATUS        ="[Issue] add issues set status";

export const UPDATE_ISSUE               ="[Issue] update issues";
export const UPDATE_ISSUE_SUCCESS       ="[Issue] update issues Success";
export const UPDATE_ISSUE_ERROR         ="[Issue] update issues error";
export const SET_ISSUEUPDATE_STATUS     ="[Issue] update issues set status";

export const DELETE_ISSUE               ="[Issue] delete issues";
export const DELETE_ISSUE_SUCCESS       ="[Issue] delete issues Success";
export const DELETE_ISSUE_ERROR         ="[Issue] delete issues error";
export const SET_ISSUEDELETE_STATUS     ="[Issue] delete issues set status";

// For user registration

export const getIssues = (data) => ({
    type: LIST_ISSUE,
    payload: data
});

export const getIssuesSuccess = (data) => ({
    type: LIST_ISSUE_SUCCESS,
    payload: data
});

export const getIssuesError = (data) => ({
    type: LIST_ISSUE_ERROR,
    payload: data
});
export const setIssueslist = (data) => ({
    type: SET_ISSUE_LIST,
    payload: data  
})

export const getNextIssues = (data) => ({
    type: LIST_NEXT_ISSUE,
    payload: data 
});

export const getNextIssuesSuccess = (data) => ({
    type: LIST_NEXT_ISSUE_SUCCESS,
    payload: data
});

export const getNextIssuesError = (data) => ({
    type: LIST_NEXT_ISSUE_ERROR,
    payload: data
});

export const addIssue = (data) => ({
    type: ADD_ISSUE,
    payload: data
});

export const addIssueSuccess = (data) => ({
    type: ADD_ISSUE_SUCCESS,
    payload: data
});

export const addIssueError = (data) => ({
    type: ADD_ISSUE_ERROR,
    payload: data
});

export const setIssueAddStatus = (data) => ({
    type: SET_ISSUEADD_STATUS,
    payload: data
});


export const updateIssue = (data) => ({
    type: UPDATE_ISSUE,
    payload: data
});

export const updateIssueSuccess = (data) => ({
    type: UPDATE_ISSUE_SUCCESS,
    payload: data
});

export const updateIssueError = (data) => ({
    type: UPDATE_ISSUE_ERROR,
    payload: data
});

export const setIssueUpdateStatus = (data) => ({
    type: SET_ISSUEUPDATE_STATUS,
    payload: data
});

export const deleteIssue = (data) => ({
    type: DELETE_ISSUE,
    payload: data
});

export const deleteIssueSuccess = (data) => ({
    type: DELETE_ISSUE_SUCCESS,
    payload: data
});

export const deleteIssueError = (data) => ({
    type: DELETE_ISSUE_ERROR,
    payload: data
});

export const setIssuedeleteStatus = (data) => ({
    type: SET_ISSUEDELETE_STATUS,
    payload: data
});
