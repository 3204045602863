import {
    GETUSERS,
    GETUSERS_SUCCESS,
    GETUSERS_ERROR,
    ADD_USER_SUCCESS,
    ADD_USER,
    USER_EXISTS,
    GET_NEXT_USERS,
    GET_NEXT_USERS_SUCCESS,
    GET_NEXT_USERS_ERROR,
    getNextUsersSuccess,
    getNextUsersError,

    ADD_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_ERROR,
    UPDATE_USER_SUCCESS,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_ERROR,
    setUserExists,
    getUsersSuccess,
    getUsersError,
    setUsers,
    addUsersSuccess,
    addUsersError,
    updateUsersError,
    updateUsersSuccess,
    getUsers,
    setUserAddEditStatus,
    deleteUsersSuccess,
    deleteUsersError,
    UPDATE_PROFILE ,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    updateProfileSuccess,
    updateProfileError,
    setprofilestatus
  } from "../../actions/Users";
  import {getAllUserExport} from "../../actions/Export"
  import { setDelete } from "../../actions/Delete";
  import {getProfile} from "../../actions/auth"
  import { apiRequest } from "../../actions/api";
  import { notification_types }  from "../../../js/constants";
import { setNotification } from "../../actions/notification";

  export const getUsersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GETUSERS) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllUsers",
          action.payload,
          null,
          getUsersSuccess,
          getUsersError,
          false,
          "[User]"
        )
      );
    }
  };

  export const processgetUsers = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETUSERS_SUCCESS) {
      //if(action.payload.data.length){
        var is_hasmore =false
        if (action.payload.next){
          is_hasmore =true
        }
        const result = {
          list: action.payload.data,
          page: action.payload.next,
          hasmore:is_hasmore
        }
        
        dispatch(setUsers(result));
    }

    if (action.type === GETUSERS_ERROR) {
     
    }
  };

  export const getNextUsersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_USERS) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllUsers",
          action.payload,
          null,
          getNextUsersSuccess,
          getNextUsersError,
          false,
          "[users]"
        )
      );
    }
  };
  
  export const processgetNextUsers = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_USERS_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const NextList = action.payload.data
      const prevList = getState().users.users.list;
      const currtickets = [...prevList, ...NextList];
      const newData = {
        list: currtickets,
        page:action.payload.next,
        hasmore:is_hasmore
      };
      dispatch(setUsers(newData));
    }
  
    if (action.type === GET_NEXT_USERS_ERROR) {
  
    }
  };





  export const addUsersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    
    if(action.type === ADD_USER){
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php?apiCall=addUser",
          null,
          action.payload,
          addUsersSuccess,
          addUsersError,
          false,
          "[User]"
        )
      )

    }
  };


  export const processAddUsers = ({ dispatch }) => (next) => (action) => {
    next(action);
    if(action.type === ADD_USER_SUCCESS){
      if(action.payload.status===true){
        dispatch(getUsers());
        const data = {
          full_result: 1
        }
        dispatch(getAllUserExport(data))
        dispatch(setUserAddEditStatus(true));
        dispatch(
          setNotification(
            "User added",
            "success",
            notification_types.success
          )
        )
      }else{
        dispatch(
          setNotification(
            "Email already exists! Please try again.",
            "warning",
            notification_types.error
          )
        )

      }
     
    }
    if(action.type === ADD_USER_ERROR){

    }
  }

  export const updateUserFlow = ({dispatch}) => (next) => (action) => {
    next(action);
    if(action.type === UPDATE_USER){
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php?apiCall=updateUser",
          null,
          action.payload,
          updateUsersSuccess,
          updateUsersError,
          false,
          "[User]"
        )
      )
    }
  }

  export const processUpdateUser = ({dispatch}) => (next) => (action) => {
    next(action);
    
    if(action.type === UPDATE_USER_SUCCESS){
      dispatch(getUsers());
      const data = {
        full_result: 1
      }
      dispatch(getAllUserExport(data))
      dispatch(setUserAddEditStatus(true));
      dispatch(
        setNotification(
          "User Updated",
          "Success",
          notification_types.success
        )
      );
    }

    if(action.type === UPDATE_USER_ERROR){
      dispatch(setUserAddEditStatus(false));
    }
  }

  export const deleteUserFlow = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === DELETE_USERS){
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php?apiCall=deleteUser",
          null,
          action.payload,
          deleteUsersSuccess,
          deleteUsersError,
          false,
          "[User]"  
        )
      )
    }
  }

  export const processDeleteUser = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === DELETE_USERS_SUCCESS){
      dispatch(getUsers());
      const data = {
        full_result: 1
      }
      dispatch(getAllUserExport(data))
      dispatch(setUserAddEditStatus(true));
      dispatch(setDelete("user"));
      dispatch(
        setNotification(
          "User deleted",
          "Success",
          notification_types.success
        )
      )
    }

    if(action.type === DELETE_USERS_ERROR){
      dispatch(setUserAddEditStatus(false));
    }
  }
  export const updateProfileFlow = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === UPDATE_PROFILE){
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php",
          null,
          action.payload,
          updateProfileSuccess,
          updateProfileError,
          false,
          "[User]"  
        )
      )
    }
  }

  export const processUpdateProfile = ({dispatch}) => (next) => (action) => {
    next(action);
    if(action.type === UPDATE_PROFILE_SUCCESS){

     var result = action.payload.status
      if ( result == true ){
        dispatch(getProfile());
        dispatch(setprofilestatus(true))
        dispatch(
          setNotification(
            "Profile updated",
            "Success",
            notification_types.success
          )
        )
      }

    }

    if(action.type === UPDATE_PROFILE_ERROR){
     
    }
  }
  export const usersMdl = [
    getUsersFlow,
    processgetUsers,
    getNextUsersFlow,
    processgetNextUsers,
    addUsersFlow,
    processAddUsers,
    updateUserFlow,
    processUpdateUser,
    deleteUserFlow,
    processDeleteUser,
    updateProfileFlow,
    processUpdateProfile
  ];