import React from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap";

import { connect } from "react-redux";
import { useEffect } from "react";

import { getHardwareDetail } from "../../../store/actions/Hardwares";


function DetailsView({
    onBack,
    Hardware,
    getHardwareDetail,
    hardwareDetail
}) {

    useEffect(() => {
        var data = {
            hardwareId: Hardware.HardwareID
        }
        getHardwareDetail(data);
    }, [])

    return (
        <React.Fragment>
            <Button
                className="btn btn-primary my-1"
                onClick={onBack}
                title="Go back"
            >
                <i className="bi bi-arrow-left"></i> Back
            </Button>
            <Card className="mb-4">
                <Card.Body>
                    <Row>
                        <div>
                            <strong>Hardware name: </strong>{Hardware.HardwareName}
                        </div>
                        <Dropdown.Divider />

                        <div>
                            <strong>Serial number: </strong>{Hardware.SerialNumber}
                        </div>
                        <div>
                            <strong>Description: </strong>{Hardware.Description}
                        </div>
                    </Row>
                    <Dropdown.Divider />
                    <div>
                        <strong>Computer List:</strong><br />
                        {
                            hardwareDetail.map((data) => {
                                return(
                                    <>
                                        { 
                                            data.ComputerName == null ? (
                                                <></>
                                            ) : (
                                                data.licenceType === "Licensed"  ? (
                                                    <Badge bg="success" className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                ) : data.licenceType === "OpenSource" ? (
                                                    <Badge className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                ) : (
                                                    <Badge bg="danger" className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                )
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        hardwareDetail: state.hardwares.hardwareDetail ? state.hardwares.hardwareDetail : [],
    };
};
export default connect(mapStateToProps, { getHardwareDetail })(DetailsView);
