import React from "react";
import { connect } from "react-redux";
import { NavLink} from 'react-router-dom';
import {setLoginStatus} from "../../store/actions/Login"
import "./SideBar.css"

const SideBarComponent = ({ sideBar,setLoginStatus, role_id }) => {
    const logout = () => {
        setLoginStatus(false);
        document.cookie = "referesh_token=; path=/;";
    }
    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                    <NavLink className="nav-link " to="/dashboard">
                        <i className="bi bi-grid customIcon"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                {role_id == true && (
                    <li className="nav-item">
                        <NavLink className="nav-link " to="/user">
                            <i className="bi bi-people-fill customIcon"></i>
                            <span>Users</span>
                        </NavLink>
                    </li>
                )}
                {role_id == true && (
                <li className="nav-item">
                    <NavLink className="nav-link " to="/computer">
                        <i className="bi bi-pc-display customIcon"></i>
                        <span>Computers</span>
                    </NavLink>
                </li>
                )}
                {role_id == true && (
                <li className="nav-item">
                    <NavLink className="nav-link " to="/issues">
                        <i className="bi bi-bug-fill customIcon"></i>
                        <span>Issues</span>
                    </NavLink>
                </li>
                )}
                {role_id == true && (
                <li className="nav-item">
                    <NavLink className="nav-link " to="/hardware">
                        <i className="bi bi-cpu-fill customIcon"></i>
                        <span>Hardwares</span>
                    </NavLink>
                </li>)}
                {role_id == true && (
                <li className="nav-item">
                    <NavLink className="nav-link " to="/software">
                        <i className="bi bi-file-earmark-binary-fill customIcon"></i>
                        <span>Softwares</span>
                    </NavLink>
                </li>)}
                <li className="nav-item">
                    <NavLink className="nav-link " to="/profile">
                        <i className="bi bi-person-circle customIcon"></i>
                        <span>Profile</span>
                    </NavLink>
                </li>
                {role_id == true && (
                <li className="nav-item">
                    <NavLink className="nav-link " to="/notification">
                        <i className="bi bi-bell customIcon"></i>
                        <span>Alerts</span>
                    </NavLink>
                </li>
                )}
                <li className="nav-item">
                    <NavLink  className="nav-link" onClick={logout} to="/">
                        <i className="bi bi-box-arrow-left customIcon"></i>
                        <span>Logout</span>
                    </NavLink>
                </li>

            </ul>
        </aside>
    )
}

const mapStateToProps = (state) => {
    return {
        role_id: state.profile.RoleID == "1" ? true : false
    };
};

export default connect(mapStateToProps, {setLoginStatus})(SideBarComponent);