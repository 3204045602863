
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Login from '../src/Components/Login/Login.jsx'
import NotifyComponent from "./Components/NotifyComponent/NotifyComponent.jsx";
import { useEffect } from "react";
import { connect } from 'react-redux';
import MainLayout from './Components/Layouts/MainLayout.jsx';
import { loadAppState } from './store/actions/app';
import Register from './Components/Register/Register';
import Dashboard from './Components/Dashboard/Dashboard'
import Computer from './Components/Computer/ComputerPage'
import Hardware from './Components/Hardware/HardwarePage'
import Software from './Components/Software/SoftwarePage'
import User from './Components/Users/UsersPage'
import Forgot from './Components/ForgotPassword/ForgotPass';
import Profile from './Components/Profile/ProfilePage'
import AppLoader from './Components/AppLoader/AppLoader';
import Issues from './Components/Issues/IssuesPage'
import Notification from './Components/Notifications/Alert'
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = ({ loadAppState, isloading, islogged }) => {
  useEffect(() => {
    loadAppState();
  }, []);
  return (
    <>
      {isloading ? (
        <AppLoader />
      ) : (
        <>
          <NotifyComponent />
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot" element={<Forgot />} />
              {islogged && (
                <Route element={<MainLayout />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/computer" element={<Computer />} />
                  <Route path="/user" element={<User />} />
                  <Route path="/software" element={<Software />} />
                  <Route path="/hardware" element={<Hardware />} />
                  <Route path="/setting" element={<Computer />} />
                  <Route path="/issues" element={<Issues />} />
                  <Route path="/notification" element={<Notification />} />
                </Route>
              )}
            </Routes>
          </Router>
        </>
      )

      }

    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isloading: state.app.app_status,
    islogged: state.login.islogged

  };
}
export default connect(mapStateToProps, {
  loadAppState,
})(App);
