import {
  GETNOTIFICATION,
  GETNOTIFICATION_SUCCESS,
  GETNOTIFICATION_ERROR,
    getNotificationsSuccess,
    getNotificationsError,
    setNotifications,

    
  } from "../../actions/alert";
  import { apiRequest } from "../../actions/api";
  import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";

  export const getNotificationflow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GETNOTIFICATION) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=GetNotification",
          action.payload,
          null,
          getNotificationsSuccess,
          getNotificationsError,
          false,
          "[Notification]"
        )
      );
    }
  };

  export const processgetNotifications = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETNOTIFICATION_SUCCESS) {
        dispatch(setNotifications(action.payload.data));
    }

    if (action.type === GETNOTIFICATION_ERROR) {
     
    }
  };
  
  
  export const NotificationMdl = [
    getNotificationflow,
    processgetNotifications
  ];