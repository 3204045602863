import { SET_REGISTER_STATUS } from "../actions/Register";
import produce from "immer";

const INITIAL_STATE = {
    isRegistered: false
};

export const registerReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_REGISTER_STATUS:
            draft.isRegistered = action.payload;
            break;
        default:
            break;
    }
}, INITIAL_STATE);