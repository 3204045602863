import { Card ,Row,Col,ButtonGroup,Button} from "react-bootstrap";
const IssueCard = (
    { issue_id,
    title,
    description,
    priority,
    status,
    issue,
    issued_date,
    IssuedPerson_Name,
    assignee_Name,
    reason,
    editIssue,
    deleteIssue
  }) => {
    const dateFormat = (date) =>{
      // for date
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const currentdate = new Date(date)
      const formatted = currentdate.getDate() + ' ' + months[currentdate.getMonth()] + ', ' + currentdate.getFullYear()
      //for Time
      const Time = new Date(date)
        var h = Time.getHours()
        var m = Time.getMinutes()
        var s = Time.getSeconds()
        var formattedTime = h + ':' + m + ':' + s
        var [hour, min] = formattedTime.split(':')
        var ampm = hour >= 12 ? 'PM' : 'AM'
        hour = hour % 12
        hour = hour !== 0 ? hour : 12
        var formattedClockInTime = hour + ':' + min + ' ' + ampm
      return formatted +" "+ formattedClockInTime
    }
    return (
        <Card>
        <Card.Header className="d-flex justify-content-between">
            <div>
            {title}
            </div>
            <div>
            {status === "Open" ? (
                            <div style={{ color: "red", fontSize: 15 }}> {status}</div>
                        ) : status === "Inprogress" ? (
                          <div style={{ color: "blue", fontSize: 15 }}> {status}</div>
                        ) : status === "Closed" ?(
                          <div style={{ color: "green", fontSize: 15 }}> {status}</div>
                        ):(<></>)
                        }
            </div>
            </Card.Header>
        <Card.Body>
        <Row>
                <div>
                <strong> Issued by:  </strong>{IssuedPerson_Name} 
                </div>
              </Row>
              <Row>
                <div>
                <strong> Assign to:  </strong>{assignee_Name}
                </div>
              </Row>
              <Row>
                <div>
                <strong> Reason :  </strong>{reason}
                </div>
              </Row>
          <Row className="justify-content-between">
                        <Col ><strong> Description:  </strong>{description} </Col>
                        <Col className="order-last d-flex justify-content-end"  >
                            <ButtonGroup className="float-right">
                               <Button variant="outline-dark" size="sm"><i className="bi bi-pencil-fill"
                                onClick={(e) => {
                                        e.preventDefault();
                                         editIssue(issue);
                                         }}
                                       ></i></Button>
                                      {/* <Button variant="outline-danger" size="sm" onClick={(e) => {
                                         e.preventDefault();
                                          deleteIssue(issue_id)
                                    }}><i className="bi bi-trash-fill"></i></Button> */}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
          <Card.Text className="d-flex justify-content-between">
          <div>
           
            <strong> Issued date:  </strong> { dateFormat(issued_date)} 
            </div>
            <div>
            <strong>Priority: </strong>
            {priority}
            </div>
          </Card.Text>
          </Row>
        </Card.Body>
      </Card>
    )
}
export default IssueCard;