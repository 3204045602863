import {
    GETCOMPUTERS,
    GETCOMPUTERS_SUCCESS,
    GETCOMPUTERS_ERROR,

    GET_NEXT_COMPUTERS,
    GET_NEXT_COMPUTERS_SUCCESS,
    GET_NEXT_COMPUTERS_ERROR,
    getNextComputersSuccess,
    getNextComputersError,

    ADD_COMPUTERS,
    ADD_COMPUTERS_SUCCESS,
    ADD_COMPUTERS_ERROR,
    SET_COMPUTER_ADD_STATUS,
    UPDATE_COMPUTER,
    UPDATE_COMPUTER_SUCCESS,
    UPDATE_COMPUTER_ERROR,
    GET_COMPUTER_DETAILS,
    GET_COMPUTER_DETAILS_SUCCESS,
    GET_COMPUTER_DETAILS_ERROR,
    DELETE_COMPUTERS,
    DELETE_COMPUTERS_ERROR,
    DELETE_COMPUTERS_SUCCESS,
    getComputersSuccess,
    getComputersError,
    getComputers,
    setComputers,
    addComputers,
    addComputersSuccess,
    addComputersError,
    setComputerAddEditStatus,
    updateComputers,
    updateComputersSuccess,
    updateComputersError,
    getComputerDetails,
    setComputerDetails,
    getComputerDetailsSuccess,
    getComputerDetailsError,
    deleteComputers,
    deleteComputersSuccess,
    deleteComputersError,
    setIssuesDetails
  } from "../../actions/Computers";
  import {getAllComputerExport} from "../../actions/Export"
  import { setDelete } from "../../actions/Delete";
  import { apiRequest } from "../../actions/api";
  import { notification_types } from "../../../js/constants";
  import { setNotification } from "../../actions/notification";

  export const getComputersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GETCOMPUTERS) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllComputers",
          action.payload,
          null,
          getComputersSuccess,
          getComputersError,
          false,
          "[Computer]"
        )
      );
    }
  };

  export const processgetComputers = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETCOMPUTERS_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const result = {
        list: action.payload.data,
        page: action.payload.next,
        hasmore:is_hasmore
      }
        dispatch(setComputers(result));
    }

    if (action.type === GETCOMPUTERS_ERROR) {
     
    }
  };


  export const getNextComputersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_COMPUTERS) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllComputers",
          action.payload,
          null,
          getNextComputersSuccess,
          getNextComputersError,
          false,
          "[computers]"
        )
      );
    }
  };
  
  export const processgetNextComputers = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_COMPUTERS_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const NextList = action.payload.data
      const prevList = getState().computers.computers.list;
      const currtickets = [...prevList, ...NextList];
      const newData = {
        list: currtickets,
        page:action.payload.next,
        hasmore:is_hasmore
      };
      dispatch(setComputers(newData));
    }
  }
  export const addComputerFlow = ({dispatch}) => (next) => (action) => {
    next (action);

    if (action.type === ADD_COMPUTERS) {
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php?apiCall=addComputer",
          null,
          action.payload,
          addComputersSuccess,
          addComputersError,
          false,
          "[Computer]"
        )
      );
    }
  } 

  export const processAddComputer = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === ADD_COMPUTERS_SUCCESS){
      dispatch(setComputerAddEditStatus(true));
      const data = {
        full_result: 1
      }
      dispatch(getAllComputerExport(data))
      dispatch(getComputers());
      dispatch(
        setNotification(
          "Computer added",
          "Success",
          notification_types.success
        )
      )
    }

    if(action.type === ADD_COMPUTERS_ERROR){
      dispatch(setComputerAddEditStatus(false));
    }
  }

  export const updateComputersFlow = ({dispatch}) => (next) => (action) => {
    next(action);
    if(action.type === UPDATE_COMPUTER){
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php?apiCall=updateComputer",
          null,
          action.payload,
          updateComputersSuccess,
          updateComputersError,
          false,
          "[Computer]"
        )
      )
    }
  }

  export const processUpdateComputers = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === UPDATE_COMPUTER_SUCCESS){
      dispatch(setComputerAddEditStatus(true));
      const data = {
        full_result: 1
      }
      dispatch(getAllComputerExport(data))
      dispatch(getComputers());
      dispatch(
        setNotification(
          "Computer updated",
          "Success",
          notification_types.success
        )
      )
    }

    if(action.type === UPDATE_COMPUTER_ERROR){
      dispatch(setComputerAddEditStatus(false));
    }
  }

  export const getComputerDetailsFlow = ({dispatch}) => (next) => (action) => {
    next(action);
    
    if(action.type === GET_COMPUTER_DETAILS){
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php?apiCall=getComputerDetails",
          null,
          action.payload,
          getComputerDetailsSuccess,
          getComputerDetailsError,
          false,
          "[Computer]"
        )
      )
    }
  }

  export const processGetComputerDetails = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === GET_COMPUTER_DETAILS_SUCCESS){
      var details = action.payload.data.computerDetails
      var issues = action.payload.data.issues
      dispatch(setComputerDetails(details));
      dispatch(setIssuesDetails(issues));
      
    }

    if(action.type === GET_COMPUTER_DETAILS_ERROR){
    }
  }

  export const deleteComputerFlow = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === DELETE_COMPUTERS){
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php?apiCall=deleteComputer",
          null,
          action.payload,
          deleteComputersSuccess,
          deleteComputersError,
          false,
          "[Computer]"  
        )
      )
    }
  }

  export const processDeleteComputer = ({dispatch}) => (next) => (action) => {
    next(action);

    if(action.type === DELETE_COMPUTERS_SUCCESS){
      dispatch(getComputers());
      const data = {
        full_result: 1
      }
      dispatch(getAllComputerExport(data))
      dispatch(setComputerAddEditStatus(true));
      dispatch(setDelete("computer"));
      dispatch(
        setNotification(
          "Computer deleted",
          "Success",
          notification_types.success
        )
      )
    }

    if(action.type === DELETE_COMPUTERS_ERROR){
      dispatch(
        setNotification(
          "Computer delete error.Try again",
          "warning",
          notification_types.warning
        )
      )
    }
  }



  export const computersMdl = [
    getComputersFlow,
    processgetComputers,
    getNextComputersFlow,
    processgetNextComputers,
    addComputerFlow,
    processAddComputer,
    updateComputersFlow,
    processUpdateComputers,
    deleteComputerFlow,
    processDeleteComputer,
    getComputerDetailsFlow,
    processGetComputerDetails
  ];