import { SET_COMPUTERS, SET_COMPUTER_ADD_STATUS, SET_COMPUTER_DETAILS,SET_ISSUES_DETAILS } from "../actions/Computers";
import {SET_ISSUEADD_STATUS} from "../actions/issues"
import produce from "immer";

const INITIAL_STATE = {
    computers:{
      list: [],
      page: "",
      hasmore: false
    },
    computerDetails: [],
    issuesDetails:[],
    issue_add_edit_status:false,
    add_status: false,
};

export const computersReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_COMPUTERS:
      draft.computers.list = action.payload.list;
      draft.computers.page = action.payload.page;
      draft.computers.hasmore = action.payload.hasmore;
      break;
    case SET_COMPUTER_ADD_STATUS:
      draft.add_status = action.payload;
      break;
    case SET_COMPUTER_DETAILS:
      draft.computerDetails = action.payload;
      break;
      case SET_ISSUES_DETAILS:
        draft.issuesDetails = action.payload;
        break;
      case SET_ISSUEADD_STATUS:
      draft.issue_add_edit_status = action.payload;
      break; 
    default:
      break;
  }
}, INITIAL_STATE);