export const REGISTER               ="[Register] Register";
export const REGISTER_SUCCESS       ="[Register] Register Success";
export const REGISTER_ERROR         ="[Register] Register error";
export const SET_REGISTER_STATUS    ="[Register] Register set status";

// For user registration

export const register = (data) => ({
    type: REGISTER,
    payload: data
});

export const registerSuccess = (data) => ({
    type: REGISTER_SUCCESS,
    payload: data
});

export const registerError = (data) => ({
    type: REGISTER_ERROR,
    payload: data
});

export const setRegisterStatus = (data) => ({
    type: SET_REGISTER_STATUS,
    payload: data
});
