import React from "react";
import { connect } from "react-redux";
import { getUsers, getNextUsers, setUserAddEditStatus, deleteUsers } from "../../store/actions/Users";
import DetailsView from "./UserDetails/DetailView"
import { Button, Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import UserView from "./UserView";
import AddEdit from "./UserForm/AddEdit";
import { useImmer } from "use-immer";
import DeletePopup from "../Delete/Delete";
import { CSVLink } from "react-csv";
import SearchBox from "../SearchBox/SearchBox";
import { setDelete } from "../../store/actions/Delete";
import { getAllUserExport} from "../../store/actions/Export";
import FilterComponent from "../Filter/FilterComponent";

const UserPage = ({
    isAdmin,
    userDetails,
    getUsers,
    add_status,
    setUserAddEditStatus,
    deleteUsers,
    deleteFlag,
    hasmore,
    nextPage,
    getNextUsers,
    setDelete,
    allUsers,
    getAllUserExport
}) => {
    const [getAddEditModel, setAddEditModel] = useImmer(false);
    useEffect(() => {
        getUsers()
        const data = {
            full_result: 1
          }
          getAllUserExport(data);
    }, [])
    
// Export User
var exportInitialState = {
    allList : [],
    filteredList : []
}
const [getAllExport,SetAllExport] = useImmer(exportInitialState)
    useEffect(() => {
        var ExcelUserAllList = allUsers.map((user,idx) => {
            return { 
              S_No:idx+1,
              UserName:user.Username,
              Role:user.RoleName,
              Email:user.Email
            };
          });
          SetAllExport((draft) => {
            draft.allList = ExcelUserAllList;
        })
        var ExcelUserfilteredList = userDetails.map((user,idx) => {
            return { 
              S_No:idx+1,
              UserName:user.Username,
              Role:user.RoleName,
              Email:user.Email
            };
          });
          SetAllExport((draft) => {
            draft.filteredList = ExcelUserfilteredList;
        })
    }, [allUsers,userDetails])

    //User details 
    const [showUserList, setShowUserList] = useImmer(true);
    const [showUserDetails, setShowUserDetail] = useImmer(false);
    var initial_state = {
        userDetails: {
            type: "Add",
            userName: "",
            userEmail: "",
            password: "",
            conform_password: "",
            role: "",
            userId: "",
            status: ""
        },
    };
    var initial_state1 = {
        userDetails: []
    };
    const [getUserDetails, setUserDetails] = useImmer(initial_state1);
    const [stateAddEdit, SetStateAddEdit] = useImmer(initial_state);
    useEffect(() => {
        if (add_status == true) {
            setAddEditModel(false)
            setUserAddEditStatus(false);
            SetStateAddEdit(initial_state);
        }
    }, [add_status])

    const onShowAddModel = () => {
        SetStateAddEdit((draft) => {
            draft.userDetails.type = "Add";
            draft.userDetails.userName = ""
            draft.userDetails.userEmail = "";
            draft.userDetails.password = "";
            draft.userDetails.conform_password = "";
            draft.userDetails.role = "";
        })
        setAddEditModel(true)
    }
    const onHideAddModel = () => {
        setAddEditModel(false)
        SetStateAddEdit(initial_state);
    }
    const onShowEditModel = (user) => {
        SetStateAddEdit((draft) => {
            draft.userDetails.type = "Edit"
            draft.userDetails.userName = user.Username;
            draft.userDetails.userEmail = user.Email;
            draft.userDetails.password = user.Password;
            draft.userDetails.conform_password = user.Password;
            draft.userDetails.role = user.RoleID;
            draft.userDetails.userId = user.UserID;
            draft.userDetails.status = user.Status;
        })
        setAddEditModel(true)
    }

    const getUserDetailView = (user) => {
        setUserDetails((draft) => {
            draft.userDetails = user;
        });
        setShowUserList(false);
        setShowUserDetail(true);
        setFilterStatus(false);
    }
    const onBack = () => {
        setShowUserList(true);
        setShowUserDetail(false);
        setFilterStatus(true);
    }
    //For filter
    const [showFilterStatus, setFilterStatus] = useImmer(true);

    const handleFilterChange = (name, e) => {
        switch (name) {
            case "keyword":
                setFilter((draft) => {
                    const search_index = draft.findIndex((d) => d.name === "keyword");
                    if (search_index !== -1) {
                    // Set venue value as current value
                    draft[search_index].value = e.target.value;
                    }
                });
                break;
            default:
                break;
        }
    };
    const filterInitialState = [
        {
            placeholder: "Search",
            type: "text",
            value: null,
            name: "keyword",
            options: [],
            onChange: handleFilterChange,
        },
    ]
    const [getFilter, setFilter] = useImmer(filterInitialState)
    const getFilterValues = () => {
        var filter_values = {};
        getFilter.forEach((f) => {
            if (f.value) {
                filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
            }
        });
        return filter_values;
    };
    const filterSubmit = () => {
        let keyVal = {
            ...getFilterValues(),
        };
        getUsers(keyVal);
    }
    const reset = () => {
        // Set all filter values to null
        setFilter((draft) => {
            draft.forEach((f) => (f.value = ""));
        });
        getUsers();
    }


    //Delete User
    const [show, setShow] = useImmer(false);
    const deleteInitialState = {
        id: ''
    }
    const [deleteState, setDeleteState] = useImmer(deleteInitialState)

    const onDeleteUser = (id) => {
        setShow(true);
        setDeleteState((draft) => {
            draft.id = id;
        })
    }
    const confirmDelete = () => {
        const data = {
            userId: deleteState.id
        }
        deleteUsers(data);

    }
    const onCancel = () => {
        setShow(false);
    }

    useEffect(() => {
        if (deleteFlag) {
            setShow(false);
        }
        setDelete(false)
    }, [deleteFlag])

    const getnextUsers = () => {
        var data = {
            page: nextPage,
            ...getFilterValues(),
        }
        getNextUsers(data)
    }
    const searchSubmit = (search) => {
        const data = {
            keyword: search
        }
        getUsers(data)
    }

    return (
        <>
            <DeletePopup
                confirmDelete={confirmDelete}
                show={show}
                onCancel={onCancel}
            />
            <AddEdit
                onShow={getAddEditModel}
                onHide={onHideAddModel}
                user={stateAddEdit.userDetails}
            />
            <Row>
                <Col className="d-flex justify-content-end">
                    {isAdmin && (
                        <Button className="pull-right mb-2 m-1" variant="primary" size="sm" onClick={() => {
                            onShowAddModel();
                        }}>
                            <i className="bi bi-plus"></i> Add new
                        </Button>
                    )}
                    <CSVLink data={getAllExport.filteredList} filename={"usersList.xls"}>
                        <Button className="m-1" variant="primary" size="sm"><i className="bi bi-download"></i> Export</Button>
                    </CSVLink>
                    <CSVLink data={getAllExport.allList} filename={"usersList_all.xls"}>
                        <Button className="m-1" variant="primary" size="sm"><i className="bi bi-download"></i> Export all</Button>
                    </CSVLink>
                </Col>
            </Row>
            <Row>
                <Col>
                    { showFilterStatus &&
                        <FilterComponent
                            filters={getFilter}
                            onApply={filterSubmit}
                            onClear={reset}
                        />
                    }
                </Col>
            </Row>
            <>
                {
                    showUserList &&
                    <UserView
                        users={userDetails}
                        getUserDetailView={getUserDetailView}
                        editUser={onShowEditModel}
                        deleteUser={onDeleteUser}
                        getnextUsers={getnextUsers}
                        hasmore={hasmore}
                    />
                }
                {
                    showUserDetails &&
                    <DetailsView
                        User={getUserDetails.userDetails}
                        onBack={onBack}
                    />
                }
            </>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.profile.RoleID == "1" ? true : false,
        userDetails: state.users.users.list,
        allUsers: state.allList.allUsers.length > 0 ? state.allList.allUsers : [],
        hasmore: state.users.users.hasmore,
        nextPage: state.users.users.page,
        add_status: state.users.add_status,
        deleteFlag: state.delete.deleteFlag == "user" ? true : false,
    };
};

export default connect(mapStateToProps, {
    getUsers,
    getNextUsers,
    setUserAddEditStatus,
    deleteUsers,
    setDelete,
    getAllUserExport
})(UserPage);