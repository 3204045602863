
import {loginMdl} from "./Login"
import { appMdl } from "./app"
import {registerMdl} from "./Register"
import { computersMdl } from "./Computers"
import { hardwaresMdl } from "./Hardwares"
import {softwaresMdl} from "./Softwares"
import {authMdl} from "./auth"
import { usersMdl } from "./Users"
import {forgotMdl} from "./Forgot"
import { exportMdl } from "./Export"
import {issuesMdl} from "./issues"
import {NotificationMdl} from "./Alert"



export default function getFeatureMiddleware() {
  return [
    ...loginMdl,
    ...appMdl,
    ...registerMdl,
    ...computersMdl,
    ...hardwaresMdl,
    ...softwaresMdl,
    ...authMdl,
    ...usersMdl,
    ...forgotMdl,
    ...exportMdl,
    ...issuesMdl,
    ...NotificationMdl
  ]
}