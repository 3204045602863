
import { SET_LOGIN_STATUS,SET_TOKEN } from "../actions/Login";
import produce from "immer";

const INITIAL_STATE = {
  islogged:false,
  auth_tokens:null
};

export const loginReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_LOGIN_STATUS:
      draft.islogged = action.payload;
      break;
    case SET_TOKEN:
      draft.auth_tokens = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);