import { combineReducers } from "redux";
// import adminUserReducer from './adminUserReducer'
import {loginReducer} from "./LoginReducer";
import {registerReducer} from "./RegisterReducer";
import notificationReducer from "./notificationReducer";
import {computersReducer} from "./ComputersReducers"
import {authReducer} from "./Auth"
import {hardwaresReducer} from './HardwaresReducers'
import {softwaresReducer} from './SoftwaresReducers'
import { userReducer } from "./UsersReducer";
import { forgotReducer } from "./ForgotReducers";
import { appReducer } from "./appReducers";
import { deleteReducer } from "./DeleteReducer";
import {exportReducer} from "./ExportReducer";
import { issuesReducer } from "./IssuesReducers";
import { alertReducer } from "./AlertReducers";


export default combineReducers({
  login: loginReducer,
  register: registerReducer,
  forgot: forgotReducer,
  notification: notificationReducer,
  computers: computersReducer,
  hardwares: hardwaresReducer,
  softwares: softwaresReducer,
  profile: authReducer,
  users: userReducer,
  app: appReducer,
  delete: deleteReducer,
  allList: exportReducer,
  issues: issuesReducer,
  alert: alertReducer
});
