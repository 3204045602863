import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import PropTypes from "prop-types";

import "./FilterButtonComponent.css";

const FilterButtonComponent = ({ onApply, onClear }) => {
  return (
    <React.Fragment>
      <div>
        <ReactBootstrap.Button
          className="btnSubmitTeamArt mr-2"
          onClick={onApply}
        >
          Apply
        </ReactBootstrap.Button>{" "}
        <ReactBootstrap.Button className="btnClearTeamArt" onClick={onClear}>
          Clear
        </ReactBootstrap.Button>{" "}
      </div>
    </React.Fragment>
  );
};

FilterButtonComponent.propTypes = {
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterButtonComponent;
