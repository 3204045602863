import React from "react";
import { connect } from "react-redux";
import { Card, Col, Row, Button, ButtonGroup, Badge } from 'react-bootstrap';

const ComputerCard = ({ s_no, SoftwareName, SoftwareID, SerialNumber, Description, softwaretype, getSoftwareDetailView, software, editSoftware, deleteSoftware, isAdmin }) => {
    return (
        <Card className="cards">
            <Card.Header  style={{ cursor: "pointer", }} onClick={(e) => {
                                    e.preventDefault();
                                    getSoftwareDetailView(software);
                                }}>
                <Row >
                    <Col>
                        {SoftwareName}
                    </Col>
                    <Col className="d-flex justify-content-end" title={softwaretype}>
                        {softwaretype === "Licensed" ? (
                            <i class="bi bi-circle-fill" style={{ color: "green", fontSize: 10 }}></i>
                        ) : softwaretype === "OpenSource" ? (
                            <i class="bi bi-circle-fill" style={{ color: "blue", fontSize: 10 }}></i>
                        ) : (
                            <i class="bi bi-circle-fill" style={{ color: "red", fontSize: 10 }}></i>
                        )
                        }
                    </Col>

                </Row>

            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <Row className="justify-content-between">
                        <Col sm={4} md={10} > <span> Serial Number: {SerialNumber} </span></Col>

                        <Col className="order-last d-flex justify-content-end" sm={4} md={2} >
                            <ButtonGroup className="float-right">
                                <Button variant="outline-primary" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    getSoftwareDetailView(software);
                                }}><i className="bi bi-eye-fill"></i></Button>
                                {isAdmin && (
                                    <> <Button variant="outline-dark" size="sm" onClick={(e) => {
                                        e.preventDefault();
                                        editSoftware(software)
                                    }}><i className="bi bi-pencil-fill"></i></Button>
                                        <Button variant="outline-danger" size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            deleteSoftware(SoftwareID)
                                        }}><i className="bi bi-trash-fill"></i></Button>
                                    </>

                                )}
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(ComputerCard);