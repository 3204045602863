export const API_REQUEST = "[app] API Request";
export const API_START = "[app] API start";
export const API_END = "[app] API end";

export const apiRequest = (
  method,
  url,
  params,
  body,
  onSuccess,
  onError,
  //isProtected = false,
  feature = "global"
) => ({
  type: `${feature} ${API_REQUEST}`,
  payload: { method, url, params, body, onSuccess, onError },
  meta: { feature }
});


export const apiStart = feature => ({
  type: `${feature} ${API_START}`
});

export const apiEnd = feature => ({
  type: `${feature} ${API_END}`
});