import React from "react";
import { Container, Form, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Login/Login.css";
import "../../assets/css/style.css";
import "../../assets/img/favicon.ico";
import "../../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../../assets/vendor/boxicons/css/boxicons.min.css";
import "../../assets/vendor/quill/quill.snow.css";
import "../../assets/vendor/quill/quill.bubble.css";
import "../../assets/vendor/remixicon/remixicon.css";
import "../../assets/vendor/simple-datatables/style.css";
import { register, setRegisterStatus } from "../../store/actions/Register";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../assets/img/logo_sys.png';

const RegisterComponent = ({ register, isRegistered, setRegisterStatus}) => {
  const navigate = useNavigate();

  var initial_state = {
    organisationName: "",
    location: "",
    name: "",
    email: "",
    password: "",
    error: {
      organisationName: "",
      location: "",
      name: "",
      email: "",
      password: "",
    },
  };
  useEffect(() => {
    if(isRegistered){
      navigate('/')
      setRegisterStatus(false);
    }
  }, [isRegistered]);
  const [registrationForm, setRegistrationForm] = useImmer(initial_state);
  const MAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //To handle onchange function
  const handleRegistrationInputChange = (e) => {
    setRegistrationForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  //form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (registrationForm.organisationName.length === 0) {
      setRegistrationForm((draft) => {
        draft.error.organisationName = "Please enter organization name";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.organisationName = "";
      });
    }

    if (registrationForm.organisationName.length > 100) {
      setRegistrationForm((draft) => {
        draft.error.organisationName = "Please enter upto 100 characters for organisationName.";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.organisationName = "";
      });
    }

    if (registrationForm.location.length === 0) {
      setRegistrationForm((draft) => {
        draft.error.location = "Please enter location";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.location = "";
      });
    }

    if (registrationForm.location.length > 100) {
      setRegistrationForm((draft) => {
        draft.error.location = "Please enter upto 100 characters for location.";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.location = "";
      });
    }

    if (registrationForm.name.length === 0) {
      setRegistrationForm((draft) => {
        draft.error.name = "Please enter user name";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.name = "";
      });
    }

    if (registrationForm.name.length > 100) {
      setRegistrationForm((draft) => {
        draft.error.name = "Please enter upto 100 characters for user name.";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.name = "";
      });
    }

    if (registrationForm.email.length === 0) {
      setRegistrationForm((draft) => {
        draft.error.email = "Please enter email";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!registrationForm.email.match(MAIL_REGEX)) {
      setRegistrationForm((draft) => {
        draft.error.email = "Enter a valid email address";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.email = "";
      });
    }

    if (registrationForm.password.length === 0) {
      setRegistrationForm((draft) => {
        draft.error.password = "Please enter password";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.password = "";
      });
    }
    if (registrationForm.password.length < 6) {
      setRegistrationForm((draft) => {
        draft.error.password = "Password should be greater than 6 characters";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.password = "";
      });
    }
    if (registrationForm.password.length > 100) {
      setRegistrationForm((draft) => {
        draft.error.password = "Please enter upto 50 characters for password.";
      });
      return false;
    } else {
      setRegistrationForm((draft) => {
        draft.error.password = "";
      });
    }

    var organisationName = registrationForm.organisationName;
    var location = registrationForm.location;
    var name = registrationForm.name;
    var email = registrationForm.email;
    var password = registrationForm.password;

    let user = {
      apiCall: "userRegistration",
      organisationName: organisationName,
      location: location,
      name: name,
      email: email,
      password: password,
    };
    register(user);
  };
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="card loginCard">
              <div className="card-body">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="">
                    <h5 className="card-title text-center pb-0 fs-4">
                       Registration
                    </h5>
                  </div>
                  <form
                    className="row needs-validation"
                    noValidate
                    name="userRegisterFrm"
                    // onSubmit="return false;"
                  >
                    <div className="">
                      <Form.Group
                        className=""
                      >
                        <Form.Label className="requiredField form-label">
                          Organization name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="organisationName"
                          className={
                            registrationForm.error.organisationName.length > 0
                              ? "is-invalid"
                              : ""
                          }
                          placeholder="Enter organization name"
                          onChange={handleRegistrationInputChange}
                          autoComplete="off"
                        />
                        {registrationForm.error.organisationName.length > 0 && (
                          <span className="invalid-feedback">
                            {registrationForm.error.organisationName}
                          </span>
                        )}
                      </Form.Group>
                    </div>

                    <div className="">
                      <Form.Group
                        className=""
                      >
                      <Form.Label className="requiredField form-label">
                        Location
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="location"
                        className={registrationForm.error.location.length > 0 ? "is-invalid" : ""}
                        placeholder="Enter location"
                        onChange={handleRegistrationInputChange}
                        id="orgLocation"
                        required
                        />
                        {registrationForm.error.location.length>0 && (
                        <div className="invalid-feedback">
                          {registrationForm.error.location}
                        </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="">
                      <Form.Group
                        className="mb-2 mt4"
                      >
                      <Form.Label className="requiredField form-label">
                        User name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className={registrationForm.error.name.length>0 ? "is-invalid": ""}
                        placeholder = "Enter user name"
                        onChange={handleRegistrationInputChange}
                        id="userName"
                        required
                      />
                      {registrationForm.error.name.length>0 && (
                      <div className="invalid-feedback">
                        {registrationForm.error.name}
                      </div>
                      )}
                      </Form.Group>
                    </div>

                    <div className="">
                      <Form.Group
                        className="mb-2 mt4"
                      >
                      <Form.Label className="requiredField form-label">
                        Email
                      </Form.Label>
                      <div className="input-group">
                        <span className="input-group-text" id="inputGroupPrepend">
                          @
                        </span>
                        <Form.Control
                          type="email"
                          name="email"
                          className={registrationForm.error.email.length>0 ? "is-invalid":""}
                          onChange={handleRegistrationInputChange}
                          placeholder="Enter email"
                          id="email"
                          required
                        />
                        {registrationForm.error.email.length>0 &&(
                        <div className="invalid-feedback">
                          {registrationForm.error.email}
                        </div>
                        )}
                      </div>
                      </Form.Group>
                    </div>

                    <div className="">
                      <Form.Group
                        className="mb-2 mt4"
                      >
                      <Form.Label className="requiredField form-label">
                        Password
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        className={registrationForm.error.password.length > 0 ? "is-invalid" : ""}
                        onChange={handleRegistrationInputChange}
                        placeholder="Enter password"
                        id="password"
                        required
                      />
                      {registrationForm.error.password.length > 0 &&(
                      <div className="invalid-feedback">
                        {registrationForm.error.password}
                      </div>
                      )}
                      </Form.Group>
                    </div>

                    <div className="row">
                      <div className="col d-flex justify-content-end">
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Register
                      </Button>
                      </div>
                      <div className="col">
                      <Button variant="outline-primary" className="registerlink"><NavLink to="/" className="">Back</NavLink></Button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class=" flex justify-center">
                  <div className="text-center">
                  <img src={logo} alt="logo" className="loginLogo"/>
                  </div>
                  <div class="row">
                    <p>Kickstart to a new world of tremendous experience controlling computer and hardware resources. This System-Hub opens the 360-degree management of computers, software, hardware, users, issues on the computer and what not!!!!!.</p><br /><br />
                    <p>Try our System-Hub for free up to an unlimited number of users and computers added to your organisation.</p><br /><br />
                    <p>Email to reach our System-Hub Admin crew @ <a href = "mailto: developers@rajasri.net">developers@rajasri.net</a> for further queries.</p>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    isRegistered: state.register.isRegistered
  };
};

export default connect(mapStateToProps, {register, setRegisterStatus})(RegisterComponent);
