import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    loginSuccess,
    loginError,
    setLoginStatus,
    setToken,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    refreshSuccess,
    refreshError,
    refresh
  } from "../../actions/Login";
  import {appLoadingStatus} from "../../actions/app"
  import {getProfile} from "../../actions/auth";
  import { apiRequest } from "../../actions/api";
  import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
import {getAllUserExport,getAllHardwareExport,getAllSoftwareExport,getAllComputerExport} from "../../actions/Export"
import { getSoftwares } from "../../actions/Softwares";
import { getComputers } from "../../actions/Computers";
import { getHardwares } from "../../actions/Hardwares";
import { getUsers } from "../../actions/Users";

const processUserDetails = (data) => {
  let tokens = {
    access: {
      token: data.access_token,
    },
    refresh: {
      token: data.refresh_token,
    },
  };
  return tokens
}
  export const loginFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === LOGIN) {
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php",
          null,
          action.payload,
          loginSuccess,
          loginError,
          false,
          "[Login]"
        )
      );
    }
  };

  export const processLogin = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === LOGIN_SUCCESS) {
      var result = action.payload
      if(result.status==true){
        dispatch(setLoginStatus(result.status));
        var tokens =processUserDetails(result.data);
        dispatch(setToken(tokens));
        dispatch(getProfile());
        
        dispatch(
          setNotification(
            "Welcome back",
            "success",
            notification_types.success
          )
        );
        let timer_id = setTimeout(() => {
      dispatch(refresh())
    }, 240000)
      }else{
        dispatch(setToken(null));
        dispatch(
          setNotification(
            "Invalid Credentials",
            "failure",
            notification_types.warning
          )
        ); 
      }
     
    }

    if (action.type === LOGIN_ERROR) {
      dispatch(
        setNotification(
          "Login Error",
          "failure",
          notification_types.warning
        )
      ); 
    }
  };
  export const refreshFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === REFRESH_TOKEN) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=refreshToken",
          null,
          null,
          refreshSuccess,
          refreshError,
          false,
          "[auth] refresh"
        )
      );
    }
  };


export const processRefresh = ({ dispatch }) => (next) => (action) => {
  next(action);
  var result = action.payload
  if (action.type === REFRESH_TOKEN_SUCCESS) {
    if (result.status){
      dispatch(setLoginStatus(true));
    }
    var tokens =processUserDetails(result.data);
        dispatch(setToken(tokens));
        dispatch(getProfile());
        dispatch(appLoadingStatus(false))
        let timer_id = setTimeout(() => {
          dispatch(refresh())
        }, 240000)
  }

  if (action.type === REFRESH_TOKEN_ERROR) {
    dispatch(setToken(null));
    dispatch(appLoadingStatus(false));
  }
};
  export const loginMdl = [
    loginFlow,
    processLogin,
    refreshFlow,
    processRefresh
  ];