import { SET_NOTIFICATION } from "../actions/alert";
import produce from "immer";
const INITIAL_STATE = {
  list: []
    
};
export const alertReducer = produce((draft, action) => {
    switch (action.type) {
      case SET_NOTIFICATION:
        draft.list = action.payload;
        break;
      default:
        break;
    }
  }, INITIAL_STATE);