import {
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    registerSuccess,
    registerError,
    setRegisterStatus
} from "../../actions/Register"
import { apiRequest } from "../../actions/api";
import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";
import { LOGIN_ERROR } from "../../actions/Login";

export const registerFlow = ({dispatch}) => (next) => (action) =>{
    next(action);

    if(action.type === REGISTER){
        dispatch(
            apiRequest(
                "POST",
                "/apiCall.php",
                null,
                action.payload,
                registerSuccess,
                registerError,
                false,
                "[Register]"
            )
        );
    }
};

export const processRegister = ({ dispatch }) => (next) => (action) => {
    next(action);

    if(action.type === REGISTER_SUCCESS) {
        var status =action.payload.status
        if (status==true){
            dispatch(setRegisterStatus(true));
            dispatch(
                setNotification(
                    "User registered",
                    "success",
                    notification_types.success
                )
            );
        }else if(status == "user_exist"){
            dispatch(
                setNotification(
                    "user already exist",
                    "user already exist",
                    notification_types.error
                )
            );
        }
       
    }

    if(action.type === REGISTER_ERROR){
        dispatch(
            setNotification(
                "error",
                "error",
                notification_types.warning
            )
        );
    }
}

export const registerMdl = [
    registerFlow,
    processRegister
];