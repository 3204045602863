import React from "react";
import { connect } from "react-redux";
import { Card, Col, Row, Button, ButtonGroup } from 'react-bootstrap';

const ComputerCard = ({ s_no, ComputerId, ComputerName, OS, SystemType, licenceType, getComputerDetailView, computer, editComputer, deleteComputer, Username, isAdmin }) => {
    return (
        <Card className="cards">
            <Card.Header style={{ cursor: "pointer", }} onClick={(e) => {
                                    e.preventDefault();
                                    getComputerDetailView(ComputerId, 'details');
                                }}>
                <Row >
                    <Col>
                        {ComputerName}
                    </Col>
                    <Col className="d-flex justify-content-end" title={licenceType}>
                        {licenceType === "Licensed" ? (
                            <i class="bi bi-circle-fill" style={{ color: "green", fontSize: 10 }}></i>
                        ) : licenceType === "OpenSource" ? (
                            <i class="bi bi-circle-fill" style={{ color: "blue", fontSize: 10 }}></i>
                        ) : (
                            <i class="bi bi-circle-fill" style={{ color: "red", fontSize: 10 }}></i>
                        )
                        }
                    </Col>

                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <Row className="justify-content-between">
                        <Col sm={4} md={2} > {OS} </Col>
                        <Col sm={4} md={2} >{SystemType} </Col>
                        <Col sm={4} md={2} >{Username} </Col>
                        <Col className="order-last d-flex justify-content-end" sm={4} md={2} >
                            <ButtonGroup className="float-right">
                                <Button variant="outline-primary" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    getComputerDetailView(ComputerId, 'details');
                                }}><i className="bi bi-eye-fill"></i></Button>
                                {
                                    isAdmin && (
                                        <>
                                            <Button variant="outline-dark" size="sm"><i className="bi bi-pencil-fill"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    editComputer(ComputerId, 'edit');
                                                }}
                                            ></i></Button>
                                            <Button variant="outline-danger" size="sm" onClick={(e) => {
                                                e.preventDefault();
                                                deleteComputer(ComputerId)
                                            }}><i className="bi bi-trash-fill"></i></Button>
                                        </>
                                    )
                                }

                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(ComputerCard);