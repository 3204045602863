import React from "react";
import { Container, Form, Card, Spinner, Row, Col } from "react-bootstrap";
import "../Login/Login.css";
import "../../assets/css/style.css";
import "../../assets/img/favicon.ico";
import { getDetailsForEditComputer } from "../../store/actions/Computers";
import { getAllUserExport, getAllSoftwareExport, getAllHardwareExport, getAllComputerExport } from "../../store/actions/Export";
import ComputerDetails from "../Computer/ComputerDetails/DetailView"
import "../../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../../assets/vendor/boxicons/css/boxicons.min.css";
import "../../assets/vendor/quill/quill.snow.css";
import "../../assets/vendor/quill/quill.bubble.css";
import "../../assets/vendor/remixicon/remixicon.css";
import "../../assets/vendor/simple-datatables/style.css";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getUsers } from "../../store/actions/Users"
import { getComputers, getNextComputers } from "../../store/actions/Computers"
import { getSoftwares } from "../../store/actions/Softwares"
import { getHardwares } from "../../store/actions/Hardwares"
import "./Dashboard.css"
import DesignCard from "./DesignCard"
import EmployeeDesignCard from "./EmployeeDesignCard"
import { useImmer } from "use-immer";
import FilterComponent from "../Filter/FilterComponent";
import InfiniteScroll from 'react-infinite-scroll-component';

const DashboardComponent = ({
  computerDetails,
  hasmore,
  nextPage,
  getComputers,
  getSoftwares,
  getHardwares,
  computers,
  getDetailsForEditComputer,
  getAllUserExport,
  getAllSoftwareExport,
  getAllHardwareExport,
  getAllComputerExport,
  role_id,
  UserList,
  getNextComputers,
  user_id
}) => {
  //Page Onload
  useEffect(() => {
    if(role_id){
      getComputers();
    } else {
      const userdata = {
        assignedTo: user_id
      }
      getComputers(userdata);
    }
    const data = {
      full_result: 1
    }
    getAllComputerExport(data);
    getAllUserExport(data);
  }, [user_id]);
  const [showComputerDetails, setshowComputerDetails] = useImmer(false)
  const [showComputerList, setshowComputerList] = useImmer(true)
  const computerView = (id) => {
    const data = {
      computerId: id
    }
    getDetailsForEditComputer(data);
    setshowComputerDetails(true);
    setshowComputerList(false);
  }
  const onBack = () => {
    setshowComputerDetails(false);
    setshowComputerList(true);
  }
  // To get next Software
  const getNextComputerList = () => {
    var data = {
      page: nextPage
    }
    getNextComputers(data)
  }
// This section for Filter functionality
  const handleFilterChange = (name, e) => {
    switch (name) {
      case "systemType":
        setFilter((draft) => {
          const systemtype_index = draft.findIndex((d) => d.name === "systemType");
          if (systemtype_index !== -1) {
            // Set systemType value as current value
            draft[systemtype_index].value = e;
          }
        });
        break;
      case "keyword":
        setFilter((draft) => {
          const search_index = draft.findIndex((d) => d.name === "keyword");
          if (search_index !== -1) {
            // Set venue value as current value
            draft[search_index].value = e.target.value;
          }
        });
        break;
      case "RAM":
        setFilter((draft) => {
          const RAM_index = draft.findIndex((d) => d.name === "RAM");
          if (RAM_index !== -1) {
            // Set venue value as current value
            draft[RAM_index].value = e.target.value;
          }
        });
        break;
      case "licenceType":
        setFilter((draft) => {
          const licence_index = draft.findIndex((d) => d.name === "licenceType");
          if (licence_index !== -1) {
            // Set team value as current value
            draft[licence_index].value = e;
          }
        });
        break;
      case "assignedTo":
        setFilter((draft) => {
          const user_index = draft.findIndex((d) => d.name === "assignedTo");
          if (user_index !== -1) {
            // Set assignedTo value as current value
            draft[user_index].value = e;
          }
        });
        break;
      default:
        break;
    }
  };
  const filterInitialState = [
    {
      placeholder: "Search",
      type: "text",
      value: "",
      name: "keyword",
      options: [],
      onChange: handleFilterChange,
    },
    {
      placeholder: "System type ",
      type: "select",
      value: null,
      name: "systemType",
      options: [
        {
          id: "PC",
          value: "PC",
          label: "PC"
        },
        {
          id: "Laptop",
          value: "Laptop",
          label: "Laptop"
        }

      ],
      onChange: handleFilterChange,
    },
    {
      placeholder: "License type",
      type: "select",
      value: null,
      name: "licenceType",
      options: [
        {
          id: "Licensed",
          value: "Licensed",
          label: "Licensed"
        },
        {
          id: "OpenSource",
          value: "OpenSource",
          label: "OpenSource"
        },
        {
          id: "Pirated",
          value: "Pirated",
          label: "Pirated"
        }

      ],
      onChange: handleFilterChange,
    },
    {
      placeholder: "User",
      type: "select",
      value: null,
      name: "assignedTo",
      options: [],
      onChange: handleFilterChange,
    }
  ]
  const [getFilter, setFilter] = useImmer(filterInitialState)

  // This function for create filter format
  const getFilterValues = () => {
    var filter_values = {};
    getFilter.forEach((f) => {
      if (f.value) {
        filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
      }
    });
    return filter_values;
  };
  const filterSubmit = () => {
    let keyVal = {
      ...getFilterValues(),
    };
    getComputers(keyVal);
  }
  const reset = () => {
    // Set all filter values to null
    setFilter((draft) => {
      draft.forEach((f) => (f.value = ""));
    });
    getComputers();
  }

  useEffect(() => {
    const userOption = UserList.map((user) => ({
      id: user.UserID,
      value: user.UserID,
      label: user.Username,
    }));
    setFilter((draft) => {
      const systemtype_index = draft.findIndex((d) => d.name === "assignedTo");
      if (systemtype_index !== -1) {
        // Set systemType value as current value
        draft[systemtype_index].options = userOption;
      }
    });

  }, [UserList])
  return (
    <>
      {showComputerDetails &&
        <ComputerDetails
          onBack={onBack}
          isAdmin={role_id}
        />
      }
      {showComputerList && role_id == true &&
        <>
          <FilterComponent
            filters={getFilter}
            onApply={filterSubmit}
            onClear={reset}
          />
          <InfiniteScroll
            dataLength={computers.length}
            next={getNextComputerList}
            style={{ overflowX: "hidden" }}
            hasMore={hasmore}
            loader={
              <h4 style={{ textAlign: "center" }}>
                <Spinner as="span" animation="border" role="status" />
              </h4>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                {computers.length !== 0 ? (
                  <>
                    {computers.length > 5 && (
                      <b>No more computers found</b>
                    )

                    }</>

                ) : (
                  <b>No Computers Found</b>
                )}
              </p>
            }
          >
            <Row>
              {computers.map((computer, idx) => {
                return (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <DesignCard
                      key={idx}
                      type={computer.SystemType}
                      computerName={computer.ComputerName}
                      computerid={computer.ComputerID}
                      username={computer.Username}
                      licenceType={computer.licenceType}
                      openIssueCount={computer.openIssueCount}
                      onComputerView={computerView}
                    />

                  </Col>
                )
              })}
            </Row>
          </InfiniteScroll>
        </>

      } 
      {showComputerList && role_id == false &&
      <>
      <InfiniteScroll
            dataLength={computers.length}
            next={getNextComputerList}
            style={{ overflowX: "hidden" }}
            hasMore={hasmore}
            loader={
              <h4 style={{ textAlign: "center" }}>
                <Spinner as="span" animation="border" role="status" />
              </h4>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                {computers.length !== 0 ? (
                  <>
                    {computers.length > 5 && (
                      <b>No more computers found</b>
                    )

                    }</>

                ) : (
                  <b>No Computers Found</b>
                )}
              </p>
            }
            >
            <Row>
              {computers.map((computer, idx) => {
                return (
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <EmployeeDesignCard
                      key={idx}
                      type={computer.SystemType}
                      computerName={computer.ComputerName}
                      computerid={computer.ComputerID}
                      username={computer.Username}
                      licenceType={computer.licenceType}
                      openIssueCount={computer.openIssueCount}
                      onComputerView={computerView}
                    />

                  </Col>
                )
              })}
            </Row>
          </InfiniteScroll>
      </>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    computers: state.computers.computers.list,
    hasmore: state.computers.computers.hasmore,
    nextPage: state.computers.computers.page,
    UserList: state.allList.allUsers.length > 0 ? state.allList.allUsers : [],
    role_id: state.profile.RoleID == "1" ? true : false,
    user_id: state.profile.UserID,
  };
};

export default connect(mapStateToProps, {
  getUsers,
  getComputers,
  getNextComputers,
  getSoftwares,
  getHardwares,
  getDetailsForEditComputer,
  getAllUserExport,
  getAllSoftwareExport,
  getAllHardwareExport,
  getAllComputerExport
})(DashboardComponent);