import { SET_FORGOT_STATUS } from "../actions/Forgot";
import produce from "immer";

const INITIAL_STATE = {
    forgotStatus: false
};

export const forgotReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_FORGOT_STATUS:
            draft.forgotStatus = action.payload;
            break;
        default:
            break;
    }
}, INITIAL_STATE);