import React, { useEffect} from "react";
import { useImmer } from "use-immer";
import { Card } from "react-bootstrap"
import "./NotificationCard.css"
import CardHeader from "react-bootstrap/esm/CardHeader";
const NotificationCard = ({ content}) => {

// This Funtion for create content
var initial_state = {
  header: "",
  finalDate: "",
  finalRuleName:"",
  EventDescription:"",
  DocTitle:"",
  AuditLog:""
};
// Set State value using immer
const [getContent, setContent] = useImmer(initial_state);

function contentCreater (conten) {
  // Date //
var Dat = conten.split("Date:")
var Date2 = Dat[1].split("Rule Name:")
var finalDate = Date2[0]
// RuleName //
var Rul = conten.split("Rule Name:")
var Rule2 = Rul[1].split("Description:")
var finalRule = Rule2[0]
// Description //
var Des = conten.split("Description:")
var Desc2 = Des[1].split("Document Title:")
var finalDesc = Desc2[0]
// Document Title //
var Titl = conten.split("Document Title:")
var Title2 = Titl[1].split("Audit Log:")
var finalTitle = Title2[0]
// Audit Log //
var Al = conten.split("Audit Log:")
var finallog = Al[1]
setContent((draft) => {
  draft.header = Dat[0];
  draft.finalDate = finalDate;
  draft.finalRuleName = finalRule;
  draft.EventDescription = finalDesc;
  draft.DocTitle = finalTitle;
  draft.AuditLog = finallog;
});
}
useEffect (()=>{
  contentCreater(content)
},[content])
  return (
    <React.Fragment>
    <Card>
      <Card.Body>
        <Card.Text style={{ fontSize: '13px' }}>
          <div>
            <strong>Date:</strong> {getContent.finalDate}
          </div>
          <div>
          <strong>Rule Name:</strong> {getContent.finalRuleName}
          </div>
          <div>
          <strong>Description: </strong>{getContent.EventDescription}
          </div>
          <div>
          <strong>Document Title: </strong>{getContent.DocTitle}
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
    </React.Fragment>
  )
};
export default NotificationCard;
