import React from "react";
import { Navbar, Nav } from "react-bootstrap"
import { connect } from "react-redux";
import { Container,NavDropdown,Offcanvas } from 'react-bootstrap/Container';
import HeaderNavbar from "../SideBar/NavBar"
import logo from '../../assets/img/logo_sys.png';

const HeaderComponent = ({ Username }) => {
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <HeaderNavbar />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
        <img src={logo} alt="logo" height="60px"/>
        </div>
      </div>
      <nav className="header-nav ms-auto d-none d-md-block">
        <ul className="d-flex align-items-center">
          <li className="nav-item pe-3">
          Welcome, {Username}
          </li>
        </ul>
      </nav>
    </header>
  )
}

const mapStateToProps = (state) => {
  return {
    Username: state.profile.Username ? state.profile.Username : ""

  };
};
export default connect(mapStateToProps, null)(HeaderComponent);