import React from "react";
import {connect} from "react-redux";
import "./Footer.css"

const FooterComponent = ({ footer }) => {
    const date = new Date()
    var year = date.toLocaleString('default', { year: 'numeric' })

  return (
    <>
      <footer id="footer" className="footer">
        <div className="copyright">
       <span>&copy; {year } Rajasri Systems, All rights reserved.</span>
        </div>
      </footer>
      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(FooterComponent);
