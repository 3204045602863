import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Modal,
    Form,
    Container,
    Row,
    Col,
    Button,
    Offcanvas
} from "react-bootstrap";
import { connect } from "react-redux";
import { addHardware, updateHardware } from "../../../store/actions/Hardwares"
const AddEdit = ({
    onShow,
    onHide,
    addHardware,
    updateHardware,
    hardware
}) => {
    var addinitial_state = {
        type: "Add",
        hardwareId: "",
        status: "",
        hardwareName: "",
        serialNumber: "",
        description: "",
        error: {
            hardwareName: "",
            serialNumber: "",
            description: ""
        },
    };
    // Set State value using immer
    const [hardware_form, sethardware_form] = useImmer(addinitial_state);

    useEffect(() => {
        if (hardware) {
            sethardware_form((draft) => {
                draft.status = hardware.status !== null ? hardware.status :"";
                draft.hardwareId = hardware.hardwareId;
                draft.description = hardware.Description !== null ? hardware.Description :"";
                draft.serialNumber = hardware.SerialNumber !== null ? hardware.SerialNumber :"";
                draft.hardwareName = hardware.HardwareName !== null ? hardware.HardwareName :"";
                draft.type = hardware.type;
            });
        }
    }, [hardware])

    const onInputChange = (e) => {
        sethardware_form((draft) => {
            draft[e.target.name] = e.target.value;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (hardware_form.hardwareName.length === 0) {
            sethardware_form((draft) => {
                draft.error.hardwareName = "Please enter the hardware name.";
            });
            return false;
        } else {
            sethardware_form((draft) => {
                draft.error.hardwareName = "";
            });
        }

        if (hardware_form.hardwareName.length > 256) {
            sethardware_form((draft) => {
              draft.error.hardwareName = "Please enter upto 256 characters for hardware name.";
            });
            return false;
          } else {
            sethardware_form((draft) => {
              draft.error.hardwareName = "";
            });
          }

        if (hardware_form.serialNumber.length === 0) {
            sethardware_form((draft) => {
                draft.error.serialNumber = "Please enter the serial number.";
            });
            return false;
        } else {
            sethardware_form((draft) => {
                draft.error.serialNumber = "";
            });
        }

        if (hardware_form.serialNumber.length > 256) {
            sethardware_form((draft) => {
              draft.error.serialNumber = "Please enter upto 256 characters for serial number.";
            });
            return false;
          } else {
            sethardware_form((draft) => {
              draft.error.serialNumber = "";
            });
          }
        if (hardware_form.description.length > 256) {
            sethardware_form((draft) => {
              draft.error.description = "Please enter upto 256 characters for description.";
            });
            return false;
          } else {
            sethardware_form((draft) => {
              draft.error.description = "";
            });
          }

        if (hardware_form.type == "Add") {
            const data = {
                hardwareName: hardware_form.hardwareName,
                serialNumber: hardware_form.serialNumber,
                description: hardware_form.description,
                apiCall: "addHardware"
            };
            addHardware(data);
        } else if (hardware_form.type == "Edit") {
            const data = {
                status: hardware_form.status,
                hardwareId: hardware_form.hardwareId,
                hardwareName: hardware_form.hardwareName,
                serialNumber: hardware_form.serialNumber,
                description: hardware_form.description,
                apiCall: "updateHardware"
            };
            updateHardware(data);
        }

    };
    const onclose = () =>{
        sethardware_form(addinitial_state)
        onHide()
    }
    return (
        <>
            <React.Fragment>
                <Offcanvas

                    show={onShow}
                    onHide={onclose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    placement="end"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h6 className="font-weight-bold">{hardware_form.type} hardware</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Container>
                                <Col >
                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Hardware name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter hardware name"
                                            value={hardware_form.hardwareName}
                                            name="hardwareName"
                                            className={hardware_form.error.hardwareName.length > 0 ? "is-invalid" : ""}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {hardware_form.error.hardwareName.length > 0 && (
                                            <span className="invalid-feedback">{hardware_form.error.hardwareName}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicLastName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Serial number
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter serial number"
                                            value={hardware_form.serialNumber}
                                            name="serialNumber"
                                            className={hardware_form.error.serialNumber.length > 0 ? "is-invalid" : ""}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {hardware_form.error.serialNumber.length > 0 && (
                                            <span className="invalid-feedback">{hardware_form.error.serialNumber}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicLastName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Description
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter description"
                                            className={hardware_form.error.description.length > 0 ? "is-invalid" : ""}
                                            value={hardware_form.description}
                                            name="description"
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {hardware_form.error.description.length > 0 && (
                                            <span className="invalid-feedback">{hardware_form.error.description}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="btnModelSubmit"
                                onClick={handleSubmit}
                            >
                                {hardware_form.type == "Add" ? ("Save") : ("Update")}
                            </Button>

                            <Button className="btnModelClose" onClick={onclose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Offcanvas>
            </React.Fragment>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps,
    { addHardware, updateHardware }
)(AddEdit);
