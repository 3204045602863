export const FORGOT               ="[Forgot] Forgot";
export const FORGOT_SUCCESS       ="[Forgot] Forgot Success";
export const FORGOT_ERROR         ="[Forgot] Forgot error";
export const SET_FORGOT_STATUS    ="[Forgot] Forgot set status";

// For user registration

export const forgetPassword = (data) => ({
    type: FORGOT,
    payload: data
});

export const forgetPasswordSuccess = (data) => ({
    type: FORGOT_SUCCESS,
    payload: data
});

export const forgetPasswordError = (data) => ({
    type: FORGOT_ERROR,
    payload: data
});

export const setforgetPasswordStatus = (data) => ({
    type: SET_FORGOT_STATUS,
    payload: data
});
