import { SET_HARDWARES,SET_HARDWARES_ADD_STATUS,SET_HARDWARE_DETAIL } from "../actions/Hardwares";
import produce from "immer";

const INITIAL_STATE = {
    hardwares:{
      list: [],
      page: "",
      hasmore: false
    },
    add_status:false,
    hardwareDetail: []
};

export const hardwaresReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_HARDWARES:
      draft.hardwares.list = action.payload.list;
      draft.hardwares.page = action.payload.page;
      draft.hardwares.hasmore = action.payload.hasmore;
      break;
    case SET_HARDWARES_ADD_STATUS:
      draft.add_status = action.payload;
      break;
    case SET_HARDWARE_DETAIL:
      draft.hardwareDetail = action.payload;
      break;  
    default:
  }
}, INITIAL_STATE);