import React from "react";
import { useImmer } from "use-immer";
import { NavLink } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { forgetPassword, setforgetPasswordStatus } from "../../store/actions/Forgot"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Forgot = ({ forgetPassword,setforgetPasswordStatus, mailSent }) => {
    const navigate = useNavigate();
    var initial_state = {
        email: "",
        error: {
            email: ""
        }
    }
    const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [state, setState] = useImmer(initial_state)
    const inputhandler = (input_email) => {
        setState((draft) => {
            draft.email = input_email.target.value
        })
    }
    const submit = (e) => {
        e.preventDefault();
        if (state.email.length === 0) {
            setState((draft) => {
                draft.error.email = "Please enter the email.";
            });
            return false;
        } else {
            setState((draft) => {
                draft.error.email = "";
            });
        }

        if (!state.email.match(MAIL_REGEX)) {
            setState((draft) => {
                draft.error.email = "Please enter valid email.";
            });
            return false;
        } else {
            setState((draft) => {
                draft.error.email = "";
            });
        }
        var data = {
            apiCall: "forgotPassword",
            email: state.email
        }
        forgetPassword(data)
    }
    useEffect(() => {
        if (mailSent) {
            navigate('/')
            setforgetPasswordStatus(false); 
        }
    }, [mailSent])
    return (
        <main>
            <div className="container">

                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                                        <img src="assets/img/logo.png" alt="" />
                                        <span className="">System Hub</span>
                                    </a>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Forgot password?</h5>
                                            <p className="text-center small">Enter your email to receive password</p>
                                        </div>

                                        <form className="row g-3 needs-validation" novalidate>

                                            < Form.Group
                                                className="mb-2 mt-4"
                                                controlId="formBasicName"
                                            >
                                                < Form.Label className="requiredField form-label">
                                                    Email
                                                </ Form.Label>
                                                < Form.Control
                                                    type="text"
                                                    name="email"
                                                    value={state.email}
                                                    className={state.error.email.length > 0 ? "is-invalid" : ""}
                                                    placeholder="Enter email"
                                                    onChange={inputhandler}
                                                    autoComplete="off"
                                                />
                                                {state.error.email.length > 0 && (
                                                    <span className="invalid-feedback">{state.error.email}</span>
                                                )}
                                            </ Form.Group>

                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" onClick={submit}>Send password</button>
                                            </div>
                                            <div className="col-12">
                                                <p className="small mb-0"><NavLink to="/">Back to login!</NavLink></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}
const mapStateToProps = (state) => {
    return {
        mailSent: state.forgot.forgotStatus
    };
};

export default connect(mapStateToProps,
    { forgetPassword,setforgetPasswordStatus }
)(Forgot);