export const LOAD_APP = "[app] Load";
export const LOAD_APP_STATUS = "[app] Loading Status";

export const loadAppState = () => ({
    type: LOAD_APP,
  });
  export const appLoadingStatus = (data) => ({
    type: LOAD_APP_STATUS,
    payload: data
  });
  