import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

import "./SelectFormControlComponent.css";

const SelectFormControl = ({ options, onChange, value, placeholder, name, maxhight }) => {
  return (
    <React.Fragment>
      <div>
        <Select
          className="basic-single"
          classNamePrefix={name}
          placeholder={placeholder}
          isClearable={false}
          value={value}
          isSearchable={true}
          name={name}
          options={options}
          maxMenuHeight={maxhight}
          onChange={(e) => {onChange(name,e)}}
        />
      </div>
    </React.Fragment>
  );
};

SelectFormControl.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

// Specifies the default values for props:
SelectFormControl.defaultProps = {
  value: null
};

export default SelectFormControl;
