export const GETUSERS                        = "[Users] Users";
export const GETUSERS_SUCCESS                = "[Users] Users success";
export const GETUSERS_ERROR                  = "[Users] Users error";
export const SET_USERS                       = "[Users] Users set";

export const GET_NEXT_USERS              = "[Users] nextUsers ";
export const GET_NEXT_USERS_SUCCESS      = "[Users] nextUsers success";
export const GET_NEXT_USERS_ERROR        = "[Users] nextUsers error";
export const SET_NEXT_USERS              = "[Users] nextUsers set";

export const USER_EXISTS                     = "[Users] Users exist set";
export const ADD_USER                        = "[Users] Add users";
export const ADD_USER_SUCCESS                = "[Users] Add user success";
export const ADD_USER_ERROR                  = "[Users] Add user success";
export const UPDATE_USER                     = "[Users] Update user";
export const UPDATE_USER_SUCCESS             = "[Users] User update success";
export const UPDATE_USER_ERROR               = "[Users] User update error";
export const SET_USER_ADD_STATUS             = "[Users] User Add Set";
export const DELETE_USERS                    = "[Users] User delete";
export const DELETE_USERS_SUCCESS            = "[Users] User delete success";
export const DELETE_USERS_ERROR              = "[Users] User delete error";
export const UPDATE_PROFILE                  = "[Profile] User profile Update"
export const UPDATE_PROFILE_SUCCESS          = "[Profile] User profile Update success"
export const UPDATE_PROFILE_ERROR            = "[Profile] User profile Update error"
export const SET_PROFILE_STATUS              = "[Profile] User status Update status"

// For user login

export const getUsers = (data) => ({
    type: GETUSERS,
    payload: data
  });
  export const getUsersSuccess = (data) => ({
    type: GETUSERS_SUCCESS,
    payload: data,
  });
  
  export const getUsersError = (data) => ({
    type: GETUSERS_ERROR,
    payload: data,
  });

  export const setUsers = (data) => ({
    type: SET_USERS,
    payload: data,
  });

  export const setUserExists = (data) => ({
    type: USER_EXISTS,
    payload: data,
  });


  export const getNextUsers = (data) => ({
    type: GET_NEXT_USERS,
    payload: data
  });
  export const getNextUsersSuccess = (data) => ({
    type: GET_NEXT_USERS_SUCCESS,
    payload: data,
  });
  
  export const getNextUsersError = (data) => ({
    type: GET_NEXT_USERS_ERROR,
    payload: data,
  });

  export const setNextUsers = (data) => ({
    type: SET_NEXT_USERS,
    payload: data,
  });



  export const addUsers = (data) => ({
    type: ADD_USER,
    payload: data,
  })

  export const addUsersSuccess = (data) => ({
    type: ADD_USER_SUCCESS,
    payload: data,
  })

  export const addUsersError = (data) => ({
    type: ADD_USER_ERROR,
    payload: data,
  })

  export const updateUsers = (data) => ({
    type: UPDATE_USER,
    payload: data,
  })

  export const updateUsersError = (data) => ({
    type: UPDATE_USER_ERROR,
    payload: data,
  })

  export const updateUsersSuccess = (data) => ({
    type: UPDATE_USER_SUCCESS,
    payload: data,
  })

  export const setUserAddEditStatus = (data) => ({
    type: SET_USER_ADD_STATUS,
    payload: data,
  })

  export const deleteUsers = (data) => ({
    type: DELETE_USERS,
    payload: data
  })

  export const deleteUsersSuccess = (data) => ({
    type: DELETE_USERS_SUCCESS,
    payload: data
  })

  export const deleteUsersError = (data) => ({
    type: DELETE_USERS_ERROR,
    payload: data
  })
  export const updateProfile = (data) => ({
    type: UPDATE_PROFILE,
    payload: data
  })
  export const updateProfileSuccess = (data) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data
  })
  export const updateProfileError = (data) => ({
    type: UPDATE_PROFILE_ERROR,
    payload: data
  })
  export const setprofilestatus = (data) =>({
    type: SET_PROFILE_STATUS,
    payload: data
  })