import React from "react";
import { Navbar, Nav } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux";
import { Container, NavDropdown, Offcanvas } from 'react-bootstrap';
import { setLoginStatus } from "../../store/actions/Login"
import "./NavBar.css"
import { useImmer } from "use-immer"
const HeaderNavbar = ({ role_id, Username, setLoginStatus }) => {
    const [show, setShow] = useImmer(false);

    const toggleOffCanvas = () => {
        setShow(!show);
    };
    const logout = () => {
        setLoginStatus(false);
        document.cookie = "referesh_token=; path=/;";
    }
    return (
        <>
            <Navbar bg="light" expand={"md"} className="d-lg-none d-md-none">
                <Container fluid>
                    <Navbar.Toggle aria-controls="offcan" onClick={toggleOffCanvas} />
                    <Navbar.Offcanvas
                        id={"offcan"}
                        aria-labelledby={"offcan"}
                        placement="start"
                        backdrop={true}
                        show={show}
                        onHide={toggleOffCanvas}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={"offcan"}>
                                Welcome, {Username}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className=" d-md-none">

                                <aside id="sidebar" className="">
                                    <ul className="sidebar-nav" id="sidebar-nav">
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/dashboard" onClick={toggleOffCanvas}>
                                                <i className="bi bi-grid customIcon"></i>
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </li>

                                        {role_id == "1" && (
                                            <li className="nav-item">
                                                <NavLink className="nav-link " to="/user" onClick={toggleOffCanvas}>
                                                    <i className="bi bi-people-fill customIcon"></i>
                                                    <span>Users</span>
                                                </NavLink>
                                            </li>
                                        )}
                                        {role_id == "1" && (
                                            <li className="nav-item">
                                            <NavLink className="nav-link " to="/computer" onClick={toggleOffCanvas}>
                                                <i className="bi bi-pc-display customIcon"></i>
                                                <span>Computers</span>
                                            </NavLink>
                                        </li>
                                         )}
                                        
                                        {role_id == "1" && (
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/issues" onClick={toggleOffCanvas}>
                                                <span><i className="bi bi-bug-fill customIcon"></i></span>
                                                <span>Issues</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {role_id == "1" && (
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/hardware" onClick={toggleOffCanvas}>
                                                <i className="bi bi-cpu-fill customIcon"></i>
                                                <span>Hardwares</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        {role_id == "1" && (
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/software" onClick={toggleOffCanvas}>
                                                <i className="bi bi-file-earmark-binary-fill customIcon"></i>
                                                <span>Softwares</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/profile" onClick={toggleOffCanvas}>
                                                <i className="bi bi-person-circle customIcon"></i>
                                                <span>Profile</span>
                                            </NavLink>
                                        </li>
                                        {role_id == "1" && (
                                        <li className="nav-item">
                                            <NavLink className="nav-link " to="/notification" onClick={toggleOffCanvas}>
                                                <i className="bi bi-person-circle customIcon"></i>
                                                <span>Alerts</span>
                                            </NavLink>
                                        </li>
                                        )}
                                        <li className="nav-item">
                                            <NavLink className="nav-link" onClick={logout} to="/">
                                                <i className="bi bi-box-arrow-left "></i>
                                                <span>Logout</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        role_id: state.profile.RoleID == "1" ? true : false,
        Username: state.profile.Username ? state.profile.Username : ""
    };
};
export default connect(mapStateToProps, { setLoginStatus })(HeaderNavbar);