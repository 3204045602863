import { notification_types } from "../../js/constants";

export const SET_NOTIFICATION = "[ui] set notification";
export const REMOVE_NOTIFICATION = "[ui] remove notification";

export const setNotification = (
  message,
  feature = "global",
  type = notification_types.info
) => ({
  type: `${feature} ${SET_NOTIFICATION}`,
  payload: message,
  meta: { feature, type },
});

export const removeNotification = (notificationId, feature) => ({
  type: `${feature} ${REMOVE_NOTIFICATION}`,
  payload: notificationId,
  meta: { feature }
});
