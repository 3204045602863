import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { Col, Button, Row } from "react-bootstrap";
import { CSVLink} from "react-csv";

import ComputerView from "./ComputerView";
import ComputerDetails from "./ComputerDetails/DetailView"
import AddEdit from "./ComputerForm/AddEdit";
import SearchBox from "../SearchBox/SearchBox";
import FilterComponent from "../Filter/FilterComponent";
import DeletePopup from "../Delete/Delete";

import { getComputers, setComputerAddEditStatus, deleteComputers, getNextComputers, getDetailsForEditComputer } from "../../store/actions/Computers";
import { getAllUserExport, getAllSoftwareExport, getAllHardwareExport, getAllComputerExport } from "../../store/actions/Export";
import { setDelete } from "../../store/actions/Delete";

import "./Computer.css";

const ComputerPage = ({
    computerDetails,
    getComputers,
    add_status,
    deleteComputers,
    allComputers,
    getDetailsForEditComputer,
    setComputerAddEditStatus,
    deleteFlag,
    getNextComputers,
    hasmore,
    nextPage,
    setDelete,
    getAllUserExport,
    getAllSoftwareExport,
    getAllHardwareExport,
    getAllComputerExport,
    isAdmin,
    UserList
}) => {
    const [getAddEditModel, setAddEditModel] = useImmer(false);
    useEffect(() => {
        getComputers()
        const data = {
            full_result: 1
        }
        getAllUserExport(data)
        getAllSoftwareExport(data)
        getAllHardwareExport(data)
        getAllComputerExport(data)
    }, [])
    // Details view
    const [showComputerList, setShowComputerList] = useImmer(true);
    const [showComputerDetails, setShowComputerDetail] = useImmer(false);

    var initial_State = {
        computer_details: {
            type: "Add"
        },
    };

    var initial_State1 = {
        computer_details: []
    }

    const [getcomputerDetails, setComputerDetails] = useImmer(initial_State1);
    const [stateAddEdit, SetStateAddEdit] = useImmer(initial_State);
    useEffect(() => {
        if (add_status == true) {
            setAddEditModel(false);
            setComputerAddEditStatus(false);
            SetStateAddEdit(initial_State);
        }
    }, [add_status])

    const onShowAddModel = () => {
        SetStateAddEdit((draft) => {
            draft.computer_details.type = "Add";
        })
        setAddEditModel(true)
    }
    const onHideAddModel = () => {
        setAddEditModel(false)
        SetStateAddEdit(initial_State);
    }

    const getComputerDetailView = () => {
        setShowComputerList(false);
        setShowComputerDetail(true);
        setFilterStatus(false);
    }
    const onBack = () => {
        setShowComputerList(true);
        setShowComputerDetail(false);
        setFilterStatus(true);
    }
    const getEditComputerDetails = (id, show) => {
        const data = {
            computerId: id
        }
        getDetailsForEditComputer(data);
        if (show == 'edit') {
            SetStateAddEdit((draft) => {
                draft.computer_details.type = "Edit";
            })
            setAddEditModel(true)
        } else if (show == 'details') {
            getComputerDetailView();
        }
    }

    //Delete Functionality
    const [show, setShow] = useImmer(false);
    const deleteInitialState = {
        id: ''
    }
    const [deleteState, setDeleteState] = useImmer(deleteInitialState)

    const onDeleteComputer = (id) => {
        setShow(true);
        setDeleteState((draft) => {
            draft.id = id;
        })
    }
    const confirmDelete = () => {
        const data = {
            computerId: deleteState.id
        }
        deleteComputers(data);

    }
    const onCancel = () => {
        setShow(false);
    }

    useEffect(() => {
        if (deleteFlag) {
            setShow(false);
        }
        setDelete(false)
    }, [deleteFlag])

    // To get next Software
    const getNextComputerList = () => {
        var data = {
            page: nextPage,
            ...getFilterValues(),
        }
        getNextComputers(data)
    }
    const searchSubmit = (search) => {
        const data = {
            keyword: search
        }
        getComputers(data)
    }

    //For filter
    const [showFilterStatus, setFilterStatus] = useImmer(true);

    const handleFilterChange = (name, e) => {
        switch (name) {
          case "systemType":
            setFilter((draft) => {
              const systemtype_index = draft.findIndex((d) => d.name === "systemType");
              if (systemtype_index !== -1) {
                // Set systemType value as current value
                draft[systemtype_index].value = e;
              }
            });
            break;
          case "keyword":
            setFilter((draft) => {
              const search_index = draft.findIndex((d) => d.name === "keyword");
              if (search_index !== -1) {
                // Set venue value as current value
                draft[search_index].value = e.target.value;
              }
            });
            break;
          case "RAM":
            setFilter((draft) => {
              const RAM_index = draft.findIndex((d) => d.name === "RAM");
              if (RAM_index !== -1) {
                // Set venue value as current value
                draft[RAM_index].value = e.target.value;
              }
            });
            break;
          case "licenceType":
            setFilter((draft) => {
              const licence_index = draft.findIndex((d) => d.name === "licenceType");
              if (licence_index !== -1) {
                // Set team value as current value
                draft[licence_index].value = e;
              }
            });
            break;
          case "assignedTo":
            setFilter((draft) => {
              const user_index = draft.findIndex((d) => d.name === "assignedTo");
              if (user_index !== -1) {
                // Set assignedTo value as current value
                draft[user_index].value = e;
              }
            });
            break;
          default:
            break;
        }
      };
      const filterInitialState = [
        {
          placeholder: "Search",
          type: "text",
          value: "",
          name: "keyword",
          options: [],
          onChange: handleFilterChange,
        },
        {
          placeholder: "System type ",
          type: "select",
          value: null,
          name: "systemType",
          options: [
            {
              id: "PC",
              value: "PC",
              label: "PC"
            },
            {
              id: "Laptop",
              value: "Laptop",
              label: "Laptop"
            }
    
          ],
          onChange: handleFilterChange,
        },
        {
          placeholder: "License type",
          type: "select",
          value: null,
          name: "licenceType",
          options: [
            {
              id: "Licensed",
              value: "Licensed",
              label: "Licensed"
            },
            {
              id: "OpenSource",
              value: "OpenSource",
              label: "OpenSource"
            },
            {
              id: "Pirated",
              value: "Pirated",
              label: "Pirated"
            }
    
          ],
          onChange: handleFilterChange,
        },
        {
          placeholder: "User",
          type: "select",
          value: null,
          name: "assignedTo",
          options: [],
          onChange: handleFilterChange,
        }
      ]
      const [getFilter, setFilter] = useImmer(filterInitialState)  
      const getFilterValues = () => {
        var filter_values = {};
        getFilter.forEach((f) => {
          if (f.value) {
            filter_values[f.name] = f.type === "select" ? f.value.id : f.value;
          }
        });
        return filter_values;
      };
      const filterSubmit = () => {
        let keyVal = {
          ...getFilterValues(),
        };
        getComputers(keyVal);
      }
      const reset = () => {
        // Set all filter values to null
        setFilter((draft) => {
          draft.forEach((f) => (f.value = ""));
        });
        getComputers();
      }

      useEffect(() => {
        const userOption = UserList.map((user) => ({
          id: user.UserID,
          value: user.UserID,
          label: user.Username,
        }));
        setFilter((draft) => {
          const systemtype_index = draft.findIndex((d) => d.name === "assignedTo");
          if (systemtype_index !== -1) {
            // Set systemType value as current value
            draft[systemtype_index].options = userOption;
          }
        });
    
      }, [UserList])    

    // Export User
var exportInitialState = {
    allList : [],
    filteredList : []
}
const [getAllExport,SetAllExport] = useImmer(exportInitialState)
    useEffect(() => {
        var ExcelAllList = allComputers.map((computer,idx) => {
            return { 
                S_No:idx+1,
                ComputerName:computer.ComputerName,
                OS:computer.OS,
                Description:computer.Description,
                SystemType:computer.SystemType,
                RAM:computer.RAM,
                Processor:computer.Processor,
                AssignedTo:computer.Username
            };
          });

          SetAllExport((draft) => {
            draft.allList = ExcelAllList;
        })
        var ExcelfilteredList = computerDetails.map((computer,idx) => {
            return { 
                S_No:idx+1,
                ComputerName:computer.ComputerName,
                OS:computer.OS,
                Description:computer.Description,
                SystemType:computer.SystemType,
                RAM:computer.RAM,
                Processor:computer.Processor,
                AssignedTo:computer.Username
            };
          });
          SetAllExport((draft) => {
            draft.filteredList = ExcelfilteredList;
        })
    }, [allComputers,computerDetails])
    return (
        <>
            <DeletePopup
                confirmDelete={confirmDelete}
                show={show}
                onCancel={onCancel}
            />
            <AddEdit
                type ={stateAddEdit.computer_details.type}
                onShow={getAddEditModel}
                onHide={onHideAddModel}
            />
            <Row>
                <Col className="d-flex justify-content-end">
                    {isAdmin && (
                        <Button className="pull-right mb-2 m-1" variant="primary" size="sm" onClick={() => {
                        onShowAddModel();
                    }}>
                        <i className="bi bi-plus"></i> Add new
                    </Button>
                    )}
                    
                    <CSVLink data={getAllExport.filteredList} filename={"computerList.xls"}>
                        <Button className="m-1" variant="primary" size="sm"><i className="bi bi-download"></i> Export</Button>
                    </CSVLink>
                    <CSVLink data={getAllExport.allList} filename={"computerList_all.xls"}>
                        <Button className="m-1" variant="primary" size="sm"><i className="bi bi-download"></i> Export all</Button>
                    </CSVLink>
                </Col>
            </Row>
            <Row>
                <Col>
                  { showFilterStatus && isAdmin &&
                    <FilterComponent
                        filters={getFilter}
                        onApply={filterSubmit}
                        onClear={reset}
                    />
                  }
                </Col>
            </Row>
            <>
                {showComputerList &&
                    <ComputerView
                        computers={computerDetails}
                        getComputerDetailView={getEditComputerDetails}
                        editComputer={getEditComputerDetails}
                        deleteComputer={onDeleteComputer}
                        getnextComputers={getNextComputerList}
                        hasmore={hasmore}
                        isAdmin={isAdmin}
                    />
                }
                {showComputerDetails &&
                    <ComputerDetails
                        Computer={getcomputerDetails.computer_details}
                        onBack={onBack}
                        isAdmin={isAdmin}
                    />
                }
            </>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.profile.RoleID == "1" ? true : false,
        computerDetails: state.computers.computers.list,
        allComputers: state.allList.allComputers,
        hasmore: state.computers.computers.hasmore,
        nextPage: state.computers.computers.page,
        add_status: state.computers.add_status,
        deleteFlag: state.delete.deleteFlag == "computer" ? true : false,
        UserList: state.allList.allUsers.length > 0 ? state.allList.allUsers : [],
    };
};

export default connect(mapStateToProps, {
    getComputers,
    deleteComputers,
    getNextComputers,
    getDetailsForEditComputer,
    setDelete,
    setComputerAddEditStatus,
    getAllUserExport,
    getAllSoftwareExport,
    getAllHardwareExport,
    getAllComputerExport
})(ComputerPage);