import {
  LOAD_APP
} from "../../actions/app";
import {refresh} from "../../actions/Login"
import {appLoadingStatus} from "../../actions/app"

// this middleware only care about the getBooks action
export const loadAppFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === LOAD_APP) {
    dispatch(appLoadingStatus(true))
    dispatch(refresh())
  }
};
export const appMdl = [loadAppFlow];
