import React from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Container } from 'react-bootstrap';
import CardComponent from "./ComputersCard/Card"
import "./Computer.css";
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from "../Loader/Loader";


const ComputerView = ({
    computers,
    onShowAddModel,
    getComputerDetailView,
    editComputer,
    deleteComputer,
    getnextComputers,
    hasmore,
    isAdmin
}) => {
    return (
        <>
                <Row xs={1} md={1} className="">
                    <Col>
                        <InfiniteScroll
                            dataLength={computers.length}
                            next={getnextComputers}
                            style={{ overflowX: "hidden" }}
                            hasMore={hasmore}
                            loader={
                                <h4 style={{ textAlign: "center" }}>
                                    <Spinner as="span" animation="border" role="status" />
                                </h4>
                            }
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {computers.length !== 0 ? (
                                        <>
                                            {computers.length > 5 && (
                                                <b>No more computers found</b>
                                            )

                                            }</>

                                    ) : (
                                        <b>No Computers Found</b>
                                    )}
                                </p>
                            }
                        >
                            {
                                computers.map((computer, idx) => {
                                    return (
                                        <CardComponent
                                            key={idx}
                                            s_no={idx + 1}
                                            ComputerName={computer.ComputerName}
                                            ComputerId={computer.ComputerID}
                                            OS={computer.OS}
                                            Username={computer.Username}
                                            SystemType={computer.SystemType}
                                            licenceType={computer.licenceType}
                                            Status={computer.Status}
                                            getComputerDetailView={getComputerDetailView}
                                            computer={computer}
                                            editComputer={editComputer}
                                            deleteComputer={deleteComputer}
                                            isAdmin={isAdmin}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(ComputerView);