import React from "react";
import { useImmer } from "use-immer";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { Modal,Container } from "react-bootstrap";

const DeletePopup = ({ confirmDelete, show, onCancel }) => {
  return (
    <>
      <Modal show={show} >
        <Modal.Body className="delete">
          <Modal.Title className="text-danger">Alert!</Modal.Title>
          <p className="text-danger">Are you sure you want to delete?</p>
          <div class="d-grid gap-1 d-flex justify-content-end">
          <Button
          className="ml-3"
            variant="danger"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              confirmDelete(true);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" size="sm" onClick={onCancel}>
            Cancel
          </Button>
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(DeletePopup);
