import React, { useEffect} from "react";
import { useImmer } from "use-immer";
import { connect } from "react-redux";
import {
    Modal,
    Form,
    Container,
    Row,
    Col,
    Button,
    Offcanvas
} from "react-bootstrap";
import { addComputers, updateComputers,setComputerAddEditStatus } from "../../../store/actions/Computers";
import Select from 'react-select';
import "./AddEdit.css";
const AddEdit = ({
    type,
    add_status,
    addComputers,
    updateComputers,
    onShow,
    onHide,
    UserList,
    SoftwareList,
    HardwareList,
    computerDetails
}) => {
    var initial_state = {
        type: "Add",
        softwaresOptions: [],
        hardwaresOptions: [],
        // userOptions:[],
        computerName: "",
        description: "",
        assignedTo: "",
        RAM: "",
        processor: "",
        OS: "",
        licenceType: "",
        systemType: "",
        hardwares: "",
        existingHardwares:[],
        softwares: "",
        existingSoftwares: [],
        error: {
            computerName: "",
            description: "",
            assignedTo: "",
            RAM: "",
            processor: "",
            OS: "",
            licenceType: "",
            systemType: "",
            hardwares: "",
            existingHardwares: "",
            softwares: "",
            existingSoftwares: "",
        },
    };
    // Set State value using immer
    const [computer_form, setAddressForm] = useImmer(initial_state);
    useEffect(()=>{
        if(add_status ==true){
            setComputerAddEditStatus(false);
            setAddressForm(initial_state);
        }

    },[add_status])
    var optionState = {
        hardwareOptions: [],
        softwareOptions: []
    }
    const [options, setOptions] = useImmer(optionState);
    const onInputChange = (e) => {
        setAddressForm((draft) => {
            draft[e.target.name] = e.target.value;
        });
    };

    // TODO : Need to optimize this function repetation 
    const onSoftwareChange = (e) => {
        setAddressForm((draft) => {
            draft.softwares = e;
        });
    };
    const onExistingSoftwareChange = (e) => {
        setAddressForm((draft) => {
            draft.existingSoftwares = e;
        });
    };
    const onHardwareChange = (e) => {
        setAddressForm((draft) => {
            draft.hardwares = e;
        });
    };
    const onExistingHardwareChange = (e) => {
        setAddressForm((draft) => {
            draft.existingHardwares = e;
        });
    };
    useEffect(() => {
        const softwareOption = SoftwareList.map((software) => ({
            id: software.SoftwareID,
            value: software.SoftwareName,
            label: software.SoftwareName,
        }));
        setOptions((draft) => {
            draft.softwaresOptions = softwareOption;
        });

        const hardwareOption = HardwareList.map((hardware) => ({
            id: hardware.HardwareID,
            value: hardware.HardwareName,
            label: hardware.HardwareName,
        }));
        setOptions((draft) => {
            draft.hardwaresOptions = hardwareOption;
        });
    }, [SoftwareList, HardwareList]);
    useEffect(() => {
        var len = computerDetails.length;
if (type=="Edit"){
    if (len) {
        var existingHardwares = [];
        var existingSoftwares = [];
        var temp = {
            id: "",
            value: "",
            label: ""
        }
        for (var i = 0; i < len; i++) {
            if(computerDetails[i].ExistingHardware != null){
                temp.id = computerDetails[i].ExistingHardware;
                temp.value = computerDetails[i].HardwareName;
                temp.label = computerDetails[i].HardwareName;
                existingHardwares.push(temp);

                temp = {
                    id: "",
                    value: "",
                    label: ""
                }
            }
            if(computerDetails[i].ExistingSoftware != null){
                temp.id = computerDetails[i].ExistingSoftware;
                temp.value = computerDetails[i].SoftwareName;
                temp.label = computerDetails[i].SoftwareName;
                existingSoftwares.push(temp);

                temp = {
                    id: "",
                    value: "",
                    label: ""
                }
            }
        }
        setAddressForm((draft) => {
            draft.computerId = computerDetails[0].ComputerID;
            draft.type = "Edit";
            draft.computerName = computerDetails[0].ComputerName;
            draft.description = computerDetails[0].Description;
            draft.assignedTo = computerDetails[0].AssignedTo;
            draft.RAM = computerDetails[0].RAM;
            draft.processor = computerDetails[0].Processor;
            draft.OS = computerDetails[0].OS;
            draft.licenceType = computerDetails[0].licenceType;
            draft.systemType = computerDetails[0].SystemType;
            draft.existingHardwares = existingHardwares;
            draft.existingSoftwares = existingSoftwares;
        })
    }
}    
    }, [type,computerDetails])
    const onCloseEdit = () => {
        setAddressForm(initial_state);
        onHide();
    }

    const Submit = async (e) => {
        e.preventDefault();
        if (computer_form.computerName.length === 0) {
            setAddressForm((draft) => {
                draft.error.computerName = "Please enter Computer name";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.computerName = "";
            })
        }

        if (computer_form.computerName.length > 256) {
            setAddressForm((draft) => {
              draft.error.computerName = "Please enter upto 256 characters for computer name.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.computerName = "";
            });
          }
        if (computer_form.systemType == 0) {
            setAddressForm((draft) => {
                draft.error.systemType = "Please select a system type.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.systemType = "";
            })
        }
        if (computer_form.RAM.length === 0) {
            setAddressForm((draft) => {
                draft.error.RAM = "Please enter RAM.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.RAM = "";
            })
        }

        if (computer_form.RAM.length > 256) {
            setAddressForm((draft) => {
              draft.error.RAM = "Please enter upto 256 characters for RAM.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.RAM = "";
            });
          }

        if (computer_form.OS.length === 0) {
            setAddressForm((draft) => {
                draft.error.OS = "Please enter OS.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.OS = "";
            })
        }
        if (computer_form.OS.length > 256) {
            setAddressForm((draft) => {
              draft.error.OS = "Please enter upto 256 characters for OS.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.OS = "";
            });
          }
          if (computer_form.licenceType == undefined ||computer_form.licenceType== 0) {
            setAddressForm((draft) => {
                draft.error.licenceType = "Please select a license type.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.licenceType = "";
            })
        }
        if (computer_form.processor.length === 0) {
            setAddressForm((draft) => {
                draft.error.processor = "Please enter Processor.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.processor = "";
            })
        }
        if (computer_form.processor.length > 256) {
            setAddressForm((draft) => {
              draft.error.processor = "Please enter upto 256 characters for processor.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.processor = "";
            });
          }

        if (computer_form.assignedTo.length === 0) {
            setAddressForm((draft) => {
                draft.error.assignedTo = "Please select an assignee.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.assignedTo = "";
            })
        }
        if (computer_form.description.length > 256) {
            setAddressForm((draft) => {
              draft.error.description = "Please enter upto 256 characters for description.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.description = "";
            });
          }
        function idArray(array) {
            var arrayId = [];
            var len = array.length;
            for (var idx = 0; idx < len; idx++) {
                if (array[idx].id != null) {
                    arrayId.push(array[idx].id);
                }
            }
            return arrayId;
        }

        function nameArray(name) {
            name = (name != "") ? name.split(",") : [];
            return name;
        }

        if(computer_form.type == "Add"){
            const data = {
                apiCall: "addComputer",
                computerName: computer_form.computerName,
                description: computer_form.description,
                assignedTo: computer_form.assignedTo,
                RAM: computer_form.RAM,
                processor: computer_form.processor,
                OS: computer_form.OS,
                licenceType: computer_form.licenceType,
                systemType: computer_form.systemType,
                hardwares: computer_form.hardwares.target?nameArray(computer_form.hardwares.target.value): []  ,
                existingHardwares: computer_form.existingHardwares? idArray(computer_form.existingHardwares) : [] ,
                softwares: computer_form.softwares.target ? nameArray(computer_form.softwares.target.value) : [],
                existingSoftwares: computer_form.existingSoftwares ? idArray(computer_form.existingSoftwares): [],
            };
            addComputers(data);
        } else if(computer_form.type == "Edit"){
            const data = {
                apiCall: "updateComputer",
                computerId: computer_form.computerId,
                computerName: computer_form.computerName,
                description: computer_form.description,
                assignedTo: computer_form.assignedTo,
                RAM: computer_form.RAM,
                processor: computer_form.processor,
                OS: computer_form.OS,
                licenceType: computer_form.licenceType, 
                systemType: computer_form.systemType,
                hardwares: computer_form.hardwares.target?nameArray(computer_form.hardwares.target.value): []  ,
                existingHardwares: computer_form.existingHardwares? idArray(computer_form.existingHardwares) : [] ,
                softwares: computer_form.softwares.target ? nameArray(computer_form.softwares.target.value) : [],
                existingSoftwares: computer_form.existingSoftwares ? idArray(computer_form.existingSoftwares): [],
            };
            updateComputers(data);
        }
    };
    return (
        <>
            <React.Fragment >
                <Offcanvas
                    show={onShow}
                    onHide={onCloseEdit}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    placement="end"
                    responsive="lg"
                    className="offCanvas-scroll"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h6 className="font-weight-bold">{computer_form.type} computer</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Computer name
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter computer name"
                                                value={computer_form.computerName}
                                                name="computerName"
                                                className={
                                                    computer_form.error.computerName.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                        	{computer_form.error.computerName.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.computerName}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                System type
                                            </Form.Label>
                                            <Form.Select name="systemType"
                                                className={
                                                    computer_form.error.systemType.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                value={computer_form.systemType}
                                                onChange={onInputChange}>
                                                <option value="0">Select type</option>
                                                <option value='PC'>PC</option>
                                                <option value='Laptop'>Laptop</option>
                                            </Form.Select>
                                            {computer_form.error.systemType.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.systemType}</span>
											)}
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                RAM
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter RAM"
                                                value={computer_form.RAM}
                                                name="RAM"
                                                className={
                                                    computer_form.error.RAM.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {computer_form.error.RAM.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.RAM}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Operating systems
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter operating systems"
                                                value={computer_form.OS}
                                                name="OS"
                                                className={
                                                    computer_form.error.OS.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {computer_form.error.OS.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.OS}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                    <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                License type
                                            </Form.Label>
                                            <Form.Select name="licenceType"
                                                className={
                                                    computer_form.error.licenceType.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                value={computer_form.licenceType}
                                                onChange={onInputChange}>
                                                <option value="0">Select type</option>
                                                <option value='Licensed'>Licensed</option>
                                                <option value='OpenSource'>Open Source</option>
												<option value='Pirated'>Pirated</option>
                                            </Form.Select>
                                            {computer_form.error.licenceType.length > 0 && (
                                                <span className="invalid-feedback">{computer_form.error.licenceType}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Processor
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter processor"
                                                value={computer_form.processor}
                                                name="processor"
                                                className={
                                                    computer_form.error.processor.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {computer_form.error.processor.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.processor}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Assigned to
                                            </Form.Label>
                                            <Form.Select name="assignedTo"
                                                className={
                                                    computer_form.error.assignedTo.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                value={computer_form.assignedTo}
                                                onChange={onInputChange}>
                                                <option value='0'>Select</option>
                                                {UserList.map((user) => (
                                                    <option value={user.UserID}>{user.Username}</option>
                                                ))}
                                            </Form.Select>
                                            {computer_form.error.assignedTo.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.assignedTo}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Description
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Enter description"
                                                value={computer_form.description}
                                                name="description"
                                                className={
                                                    computer_form.error.description.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {computer_form.error.description.length > 0 && (
												<span className="invalid-feedback">{computer_form.error.description}</span>
											)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Softwares
                                            </Form.Label>
                                            <Form.Control
                                                name="softwares"
                                                as="textarea"
                                                placeholder="Eg: SQL, VS Code"
                                                style={{ height: '100px' }}
                                                onChange={onSoftwareChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Existing softwares
                                            </Form.Label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                hideSelectedOptions={true}
                                                value={computer_form.existingSoftwares}
                                                className={
                                                    computer_form.error.existingSoftwares.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onExistingSoftwareChange}
                                                options={options.softwaresOptions}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Hardwares
                                            </Form.Label>
                                            <Form.Control
                                                name="hardwares"
                                                as="textarea"
                                                placeholder="Eg: RAM, HDD "
                                                style={{ height: '100px' }}
                                                onChange={onHardwareChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Existing hardwares
                                            </Form.Label>
                                            <Select
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                hideSelectedOptions={true}
                                                value={computer_form.existingHardwares}
                                                className={
                                                    computer_form.error.existingHardwares.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                onChange={onExistingHardwareChange}
                                                options={options.hardwaresOptions}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="btnModelSubmit"
                                onClick={Submit}
                            >
                              {computer_form.type =="Add"?("Save"):("Update")}
                            </Button>

                            <Button className="btnModelClose" onClick={onCloseEdit}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Offcanvas>
            </React.Fragment>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        add_status: state.computers.add_status,
        UserList: state.allList.allUsers.length > 0 ? state.allList.allUsers : [],
        SoftwareList: state.allList.allSoftwares.length > 0 ? state.allList.allSoftwares : [],
        HardwareList: state.allList.allHardwares.length > 0 ? state.allList.allHardwares : [],
        computerDetails: state.computers.computerDetails.length > 0 ? state.computers.computerDetails : []
    };
};
export default connect(mapStateToProps, { addComputers, updateComputers, setComputerAddEditStatus })(AddEdit);
