import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Modal,
    Form,
    Container,
    Row,
    Col,
    Button,
    Offcanvas
} from "react-bootstrap";
import { connect } from "react-redux";
import {addIssue,updateIssue} from "../../../../store/actions/issues"
const AddEdit = ({
    onShow,
    onclose,
    addIssue,
    computer_id,
    updateIssue,
    issues,
    UserList,
    computerList,
    issues_status
}) => {
  const  initial_State = {
    issue_id:"",
    type:"Add",
    title:"",
    description:"",
    priority:"",
    status:"",
    assignedTo:"",
    ComputerID:"",
    issued_by: "",
    reason: "",
    error:{
        title:"",
        description:"",
        priority:"",
        status:"",
        assignedTo:"",
        ComputerID: "",
        reason: ""
    }
  }
    const [getform,setform] = useImmer (initial_State)
useEffect (()=>{
    setform((draft) => {
        draft.type= issues.type;
        draft.issue_id= issues.issue_id;
        draft.title = issues.title;
        draft.description = issues.description;
        draft.priority = issues.priority;
        draft.status = issues.status;
        draft.assignedTo = issues.assignedTo;
        draft.issued_by = issues.issued_by
        draft.ComputerID = computer_id;
        draft.reason = issues.reason;
    });
},[issues])
    const onInputChange = (e) => {
        setform((draft) => {
            draft[e.target.name] = e.target.value;
        });
    };
    const handleSubmit = (e) =>{
        e.preventDefault();
        if (getform.title.length === 0) {
            setform((draft) => {
                draft.error.title = "Please enter issue title";
            });
            return false;
        } else {
            setform((draft) => {
                draft.error.title = "";
            })
        }
        if (getform.title.length > 50) {
            setform((draft) => {
              draft.error.title = "Please enter upto 50 characters for title.";
            });
            return false;
          } else {
            setform((draft) => {
              draft.error.title = "";
            });
          }
          if (getform.description.length > 200) {
            alert(getform.description.length);
            setform((draft) => {
              draft.error.description = "Please enter upto 200 characters for description.";
            });
            return false;
          } else {
            setform((draft) => {
              draft.error.description = "";
            });
          }
        if(getform.type === "Add"){
            const data = {
                title:getform.title,
                description:getform.description,
                priority:getform.priority,
                status:getform.status, 
                computer_id: computer_id ? computer_id :getform.ComputerID,
                assignedTo:getform.assignedTo,
                reason: getform.reason
            }
            addIssue(data)
            setdesablebutton(true)
        }else if (getform.type === "Edit"){
            const data = {
                issue_id:getform.issue_id,
                title:getform.title,
                description:getform.description,
                priority:getform.priority,
                status:getform.status, 
                computer_id:computer_id ? computer_id : getform.ComputerID,
                assignedTo:getform.assignedTo,
                issued_by:getform.issued_by,
                reason: getform.reason
            }
            updateIssue(data)
            setdesablebutton(true)
        } 
    }
    const onHide = () => {
		setform(initial_State)
		onclose()
	}
    const [getdesablebutton,setdesablebutton] = useImmer (false)
    useEffect (()=>{
        if(issues_status)
        {
            setdesablebutton(false)  
        }
    },[issues_status])
    return (
        <>
            <React.Fragment>
                <Offcanvas
                    show={onShow}
                    onHide={onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    placement="end"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                           <h6 className="font-weight-bold">{getform.type} issue</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Container>
                                { !computer_id &&
                                <Col xs={12} >
                                <Form.Group className="mb-3">
                                    <Form.Label className="font-weight-bold requiredField">
                                        Computer Name
                                    </Form.Label>
                                    <Form.Select name="ComputerID"
                                        className={
                                            getform.error.ComputerID.length > 0
                                            ?"is-invalid"
                                            :""
                                        }
                                        value={getform.ComputerID}
                                        onChange={onInputChange}>
                                        <option value='0'>Select</option>
                                        {computerList.map((computer) => (
                                            <option value={computer.ComputerID}>{computer.ComputerName}</option>
                                        ))}
                                    </Form.Select>
                                    {getform.error.ComputerID.length > 0 && (
                                        <span className="invalid-feedback">{getform.error.ComputerID}</span>
                                    )}
                                </Form.Group>
                            </Col>}
                                
                                <Col >
                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Issue title
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter issue title"
                                            value={getform.title}
                                            name="title"
                                           className={getform.error.title.length > 0 ? "is-invalid" : ""}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {getform.error.title.length > 0 && (
                                            <span className="invalid-feedback">{getform.error.title}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicLastName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Description
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter description"
                                            className={getform.error.description.length > 0 ? "is-invalid" : ""}
                                            value={getform.description}
                                            name="description"
                                             onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {getform.error.description.length > 0 && (
                                            <span className="invalid-feedback">{getform.error.description}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} >
                                        <Form.Group className="mb-3">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Priority
                                            </Form.Label>
                                            <Form.Select name="priority"
                                                className={
                                                    getform.error.priority.length > 0
                                                    ?"is-invalid"
                                                    :""
                                                }
                                                value={getform.priority}
                                                onChange={onInputChange}
                                                >
                                                <option value="0">Select type</option>
                                                <option value='Low'>Low</option>
                                                <option value='Medium'>Medium</option>
                                                <option value='High'>High</option>
                                                <option value='Urgent'>Urgent</option>
                                            </Form.Select>
                                            {getform.error.priority.length > 0 && (
												<span className="invalid-feedback">{getform.error.priority}</span>
											)}
                                        </Form.Group>
                                </Col>
                                <Col xs={12} >
                                    <Form.Group className="mb-3">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Status
                                        </Form.Label>
                                        <Form.Select name="status"
                                            className={
                                                getform.error.status.length > 0
                                                ?"is-invalid"
                                                :""
                                            }
                                            value={getform.status}
                                            onChange={onInputChange}
                                            >
                                            <option value="0">Select type</option>
                                            <option value="Open">Open</option>
                                            <option value="Inprogress">Inprogress</option>
                                            <option value="Closed">Closed</option>
                                            <option value="UnResolved">Not able to resolve now</option>
                                            
                                        </Form.Select>
                                        {getform.error.status.length > 0 && (
                                            <span className="invalid-feedback">{getform.error.status}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} >
                                    <Form.Group className="mb-3">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Assigned to
                                        </Form.Label>
                                        <Form.Select name="assignedTo"
                                            className={
                                                getform.error.assignedTo.length > 0
                                                ?"is-invalid"
                                                :""
                                            }
                                            value={getform.assignedTo}
                                            onChange={onInputChange}>
                                            <option value='0'>Select</option>
                                            {UserList.map((user) => (
                                                <option value={user.UserID}>{user.Username}</option>
                                            ))}
                                        </Form.Select>
                                        {getform.error.assignedTo.length > 0 && (
                                            <span className="invalid-feedback">{getform.error.assignedTo}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                {getform.type == "Edit" &&
                                <Col >
                                <Form.Group className="mb-2" controlId="formBasicFirstName">
                                    <Form.Label className="font-weight-bold requiredField">
                                        Reason To Resolve
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter the reason"
                                        value={getform.reason}
                                        name="reason"
                                       className={getform.error.reason.length > 0 ? "is-invalid" : ""}
                                        onChange={onInputChange}
                                        autoComplete="off"
                                    />
                                    {getform.error.reason.length > 0 && (
                                        <span className="invalid-feedback">{getform.error.reason}</span>
                                    )}
                                </Form.Group>
                            </Col>
                                }
                                
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                disabled = {getdesablebutton}
                                type="submit"
                                className="btnModelSubmit"
                                onClick={handleSubmit}
                            >
                                {getdesablebutton ? "Loading..." : getform.type == "Add" ? ("Save") : ("Update")}
                            </Button>
                            {!getdesablebutton && 
                             <Button className="btnModelClose" onClick={onHide}>
                             Close
                         </Button>
                            }
                        </Modal.Footer>
                    </Form>
                </Offcanvas>
            </React.Fragment>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        UserList: state.allList.allUsers.length > 0 ? state.allList.allUsers.filter((user) => user.RoleName == "Administrator") : [],
        computerList: state.allList.allUsers.length > 0 ? state.allList.allComputers : [],
        issues_status:  state.computers.issue_add_edit_status
    };
};

export default connect(mapStateToProps,
{ addIssue, updateIssue}
)(AddEdit);
