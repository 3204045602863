import {
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR,
    getProfileSuccess,
    getProfileError,
    setProfile
  } from "../../actions/auth";
  import { apiRequest } from "../../actions/api";
  import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";

  export const getprofileFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_PROFILE) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getUserDetails",
          action.payload,
          null,
          getProfileSuccess,
          getProfileError,
          false,
          "[Login]"
        )
      );
    }
  };

  export const processgetProfile = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GET_PROFILE_SUCCESS) {
      var result = action.payload.data
      const userinfo ={
      UserID: result[0].UserID,
      Username: result[0].Username,
      Email: result[0].Email,
      Password:result[0].Password,
      RoleID: result[0].RoleID,
      OrgID: result[0].OrgID,
      Status: result[0].Status,
      CreatedBy: result[0].CreatedBy,
      UpdatedBy: result[0].UpdatedBy,
      CreatedDate: result[0].CreatedDate,
      UpdatedDate:result[0].UpdatedDate
      }
      dispatch(setProfile(userinfo))
    }

    if (action.type === GET_PROFILE_ERROR) {
     
    }
  };
  export const authMdl = [
    getprofileFlow,
    processgetProfile
  ];