import React from "react";
import { connect } from "react-redux";
import { Col, Row, Container } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import CardComponent from "./SoftwareCard/Card"
import Spinner from "../Loader/Loader";

import "./Software.css";

const SoftwareView = ({
    softwares,
    getSoftwareDetailView,
    editSoftware,
    deleteSoftware,
    getnextSoftwares,
    hasmore,
    isAdmin
}) => {
    return (
        <>
                <Row xs={1} md={1} className="">
                    <Col>
                        <InfiniteScroll
                            dataLength={softwares.length}
                            next={getnextSoftwares}
                            style={{ overflowX: "hidden" }}
                            hasMore={hasmore}
                            loader={
                                <h4 style={{ textAlign: "center" }}>
                                    <Spinner as="span" animation="border" role="status" />
                                </h4>
                            }
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {softwares.length !== 0 ? (

                                        <>
                                            {softwares.length > 5 && (
                                                <b>No more softwares found</b>
                                            )

                                            }</>
                                    ) : (
                                        <b>No softwares Found</b>
                                    )}
                                </p>
                            }
                        >
                            {
                                softwares.map((software, idx) => {
                                    return (
                                        <CardComponent
                                            key={idx}
                                            s_no={idx + 1}
                                            SoftwareID={software.SoftwareID}
                                            SoftwareName={software.SoftwareName}
                                            softwaretype={software.SoftwareType}
                                            SerialNumber={software.SerialNumber}
                                            Status={software.Status == "1" ? "Active" : "Inactive"}
                                            Description={software.Description}
                                            getSoftwareDetailView={getSoftwareDetailView}
                                            software={software}
                                            editSoftware={editSoftware}
                                            deleteSoftware={deleteSoftware}
                                            isAdmin={isAdmin}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null )(SoftwareView);