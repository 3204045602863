export const LOGIN                        = "[Login] Login";
export const LOGIN_SUCCESS                = "[Login] Login success";
export const LOGIN_ERROR                  = "[Login] Login error";
export const SET_LOGIN_STATUS             = '[Login] Login set status';
export const SET_TOKEN                    = '[Login] Set Token';
export const REFRESH_TOKEN                = "[auth] Refresh";
export const REFRESH_TOKEN_SUCCESS        = "[auth] Refresh success";
export const REFRESH_TOKEN_ERROR          = "[auth] Refresh error";
// For user login

export const login = (data) => ({
    type: LOGIN,
    payload: data
  });
  export const loginSuccess = (data) => ({
    type: LOGIN_SUCCESS,
    payload: data,
  });
  
  export const loginError = (data) => ({
    type: LOGIN_ERROR,
    payload: data,
  });
  
  export const setLoginStatus = (data) => ({
    type: SET_LOGIN_STATUS,
    payload: data,
  });
  export const setToken = (data) => ({
    type: SET_TOKEN,
    payload: data,
  });
  export const refresh = () => ({
    type: REFRESH_TOKEN
  });
  
  export const refreshSuccess = (data) => ({
    type: REFRESH_TOKEN_SUCCESS,
    payload: data,
  });
  
  export const refreshError = (data) => ({
    type: REFRESH_TOKEN_ERROR,
    payload: data,
  });
  