import React from "react";
import { connect } from "react-redux";
import "./Hardware.css";
import { getHardwares, getNextHardwares, setHardwareAddEditStatus, deleteHardware } from "../../store/actions/Hardwares";
import { useEffect } from "react";
import HardwareView from "./HardwareView";
import AddEdit from "./HardwareForm/AddEdit";
import { useImmer } from "use-immer";
import HardwareDetails from './HardwareDetails/DetailView'
import { Col, Button, Row } from "react-bootstrap";
import DeletePopup from "../Delete/Delete";
import { CSVLink } from "react-csv";
import SearchBox from "../SearchBox/SearchBox"
import {setDelete} from "../../store/actions/Delete"
import { getAllHardwareExport} from "../../store/actions/Export";
import FilterComponent from "../Filter/FilterComponent";

const HardwarePage = ({
    hardwareDetails,
    getHardwares,
    add_status,
    deleteHardware,
    getAllHardwareExport,
    deleteFlag,
    hasmore,
    nextPage,
    getNextHardwares,
    setDelete,
    allHardwares,
    isAdmin
}) => {
    const [getAddEditModel, setAddEditModel] = useImmer(false);
    useEffect(() => {
        const data = {
            full_result: 1
          }
          getAllHardwareExport(data);
        getHardwares();
    }, [])

    // Edit flow
    var initial_State = {
        hardware_details: {
            hardwareId: "",
            status: "",
            type: "Add",
            Description: "",
            HardwareName: "",
            SerialNumber: ""
        }
    };
    const [stateAddEdit, SetStateAddEdit] = useImmer(initial_State);
    useEffect(() => {
        if (add_status = true) {
            setAddEditModel(false)
            setHardwareAddEditStatus(false);
            SetStateAddEdit(initial_State)
        }
    }, [add_status])

    const onShowAddModel = () => {
        SetStateAddEdit((draft) => {
            draft.hardware_details.Description = "";
            draft.hardware_details.SerialNumber = "";
            draft.hardware_details.HardwareName = "";
            draft.hardware_details.type = "Add";
        });
        setAddEditModel(true)
    }
    const onHideAddModel = () => {
        setAddEditModel(false)
        SetStateAddEdit(initial_State)
    }
    const onShowEditModel = (hardware) => {
        SetStateAddEdit((draft) => {
            draft.hardware_details.status = hardware.Status;
            draft.hardware_details.hardwareId = hardware.HardwareID;
            draft.hardware_details.Description = hardware.Description;
            draft.hardware_details.SerialNumber = hardware.SerialNumber;
            draft.hardware_details.HardwareName = hardware.HardwareName;
            draft.hardware_details.type = "Edit";
        });
        setAddEditModel(true)
    }

    // Details view
    const [showHardwareList, setShowHardwareList] = useImmer(true);
    const [showHardwareDetails, setShowHardwareDetail] = useImmer(false);
    var initial_State_details = {
        hardware_details: "",
    };
    const [getHardwareDetails, setHardwareDetails] = useImmer(initial_State_details);

    const getHardwareDetailView = (hardware) => {
        setHardwareDetails((draft) => {
            draft.hardware_details = hardware;
        });
        setShowHardwareList(false);
        setShowHardwareDetail(true);
        setFilterStatus(false);
    }
    const onBack = () => {
        setShowHardwareList(true);
        setShowHardwareDetail(false);
        setFilterStatus(true);
    }
    // Delete Screen

    // New delete screen
    const [show, setShow] = useImmer(false);
    const deleteInitialState = {
        id: ''
    }
    const [deleteState, setDeleteState] = useImmer(deleteInitialState)

    const onDeleteHardware = (id) => {
        setShow(true);
        setDeleteState((draft) => {
            draft.id = id;
        })
    }
    const confirmDelete = () => {
        const data = {
            hardwareId: deleteState.id
        }
        deleteHardware(data);

    }
    const onCancel = () => {
        setShow(false);
    }

    useEffect(() => {
        if (deleteFlag) {
            setShow(false);
        }
        setDelete(false)
    }, [deleteFlag])

    // To get next Software
    const getnextHardwares = () => {
        var data = {
            page: nextPage,
            ...getFilterValues()
        }
        getNextHardwares(data)
    }

    const searchSubmit = (search) => {
        const data = {
            keyword: search
        }
        getHardwares(data)
    }
// Export User
var exportInitialState = {
    allList : [],
    filteredList : []
}
const [getAllExport,SetAllExport] = useImmer(exportInitialState)
    useEffect(() => {
        var ExcelAllList = allHardwares.map((hardware,idx) => {
            return { 
                S_No:idx+1,
                HardwareName:hardware.HardwareName,
                SerialNumber:hardware.SerialNumber,
                Description:hardware.Description
            };
          });
          SetAllExport((draft) => {
            draft.allList = ExcelAllList;
        })
        var ExcelfilteredList = hardwareDetails.map((hardware,idx) => {
            return { 
                S_No:idx+1,
                HardwareName:hardware.HardwareName,
                SerialNumber:hardware.SerialNumber,
                Description:hardware.Description
            };
          });
          SetAllExport((draft) => {
            draft.filteredList = ExcelfilteredList;
        })
    }, [allHardwares,hardwareDetails])

    //For filter
    const [showFilterStatus, setFilterStatus] = useImmer(true);

    const handleFilterChange = (name, e) => {
        switch (name) {
            case "keyword":
                setFilter((draft) => {
                    const search_index = draft.findIndex((d) => d.name === "keyword");
                    if (search_index !== -1) {
                    // Set venue value as current value
                    draft[search_index].value = e.target.value;
                    }
                });
                break;
            default:
                break;
        }
    };

    const filterInitialState = [
        {
            placeholder: "Search",
            type: "text",
            value: null,
            name: "keyword",
            options: [],
            onChange: handleFilterChange,
        },
    ]
    const [getFilter, setFilter] = useImmer(filterInitialState)

    const getFilterValues = () => {
        var filter_values = {};
        getFilter.forEach((f) => {
            if (f.value) {
                filter_values[f.name] = f.type === "select" ? f.value.id : f.value;            
            }
        });
        return filter_values;
    };

    const filterSubmit = () => {
        let keyVal = {
            ...getFilterValues(),
        };
        getHardwares(keyVal);
    }

    const reset = () => {
        // Set all filter values to null
        setFilter((draft) => {
            draft.forEach((f) => (f.value = ""));
        });
        getHardwares();
    }



    return (
        <>
            <DeletePopup
                confirmDelete={confirmDelete}
                show={show}
                onCancel={onCancel}
            />
            <AddEdit
                onShow={getAddEditModel}
                onHide={onHideAddModel}
                hardware={stateAddEdit.hardware_details}
            />
            <Row>
                <Col className="d-flex justify-content-end">
                    {isAdmin && (
                        <Button className="pull-right mb-2 m-1" variant="primary" size="sm" onClick={() => {
                            onShowAddModel();
                        }}>
                            <i className="bi bi-plus"></i> Add new
                        </Button>
                    )}

                    <CSVLink data={getAllExport.filteredList} filename={"hardwaresList.xls"}>
                        <Button variant="primary" size="sm" className="m-1"> <i className="bi bi-download"></i> Export</Button>
                    </CSVLink>
                    <CSVLink data={getAllExport.filteredList} filename={"hardwaresList_all.xls"}>
                        <Button variant="primary" size="sm" className="m-1"><i className="bi bi-download"></i> Export all</Button>
                    </CSVLink>

                </Col>
            </Row>
            <Row>
                <Col>
                    { showFilterStatus &&
                        <FilterComponent
                            filters={getFilter}
                            onApply={filterSubmit}
                            onClear={reset}
                        />
                    }
                </Col>
            </Row>
            <>
                {showHardwareList &&
                    <HardwareView
                        hardwares={hardwareDetails}
                        onShowAddModel={onShowAddModel}
                        getHardwareDetailView={getHardwareDetailView}
                        editHardware={onShowEditModel}
                        deleteHardware={onDeleteHardware}
                        getnextHardwares={getnextHardwares}
                        hasmore={hasmore}
                        isAdmin={isAdmin}
                    />
                }
                {showHardwareDetails &&
                    <HardwareDetails
                        Hardware={getHardwareDetails.hardware_details}
                        onBack={onBack}
                    />
                }
            </>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isAdmin: state.profile.RoleID == "1" ? true : false,
        hardwareDetails: state.hardwares.hardwares.list,
        allHardwares: state.allList.allHardwares.length > 0 ? state.allList.allHardwares : [],
        add_status: state.hardwares.add_status,
        deleteFlag: state.delete.deleteFlag == "hardware" ? true : false,
        hasmore: state.hardwares.hardwares.hasmore,
        nextPage: state.hardwares.hardwares.page
    };
};

export default connect(mapStateToProps, { getHardwares, deleteHardware, getNextHardwares,setDelete, getAllHardwareExport })(HardwarePage);

