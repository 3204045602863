import React from "react";
import { connect } from "react-redux";
import { Card, Col, Row, Button, ButtonGroup, Badge } from 'react-bootstrap';

const HardwareCard = ({ s_no, HardwareName, HardwareID, SerialNumber, Status, Description, hardware, getHardwareDetailView, editHardware, deleteHardware, isAdmin }) => {
    return (
        <Card className="cards">
            <Card.Header style={{ cursor: "pointer", }} onClick={(e) => {
                                    e.preventDefault();
                                    getHardwareDetailView(hardware);
                                }}>{HardwareName}</Card.Header>
            <Card.Body>
                <Card.Text>
                    <Row className="justify-content-between">
                        <Col sm={4} md={2} > <span> Serial Number: {SerialNumber} </span></Col>
                        <Col sm={4} md={2} >{Description} </Col>
                        <Col className="order-last d-flex justify-content-end" sm={4} md={2} >
                            <ButtonGroup className="float-right">
                                <Button variant="outline-primary" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    getHardwareDetailView(hardware);
                                }}><i className="bi bi-eye-fill"></i></Button>
                                {isAdmin && (
                                    <>
                                        <Button variant="outline-dark" size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            editHardware(hardware);
                                        }}><i className="bi bi-pencil-fill"></i></Button>
                                        <Button variant="outline-danger" size="sm" onClick={(e) => {
                                            e.preventDefault();
                                            deleteHardware(HardwareID)
                                        }}><i className="bi bi-trash-fill"></i></Button>
                                    </>
                                )}

                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(HardwareCard);