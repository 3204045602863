import { SET_ISSUE_LIST } from "../actions/issues";
import produce from "immer";

const INITIAL_STATE = {
  Issues: {
    list:[],
    page:"",
    hasmore:false
  },
};

export const issuesReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_ISSUE_LIST:
      draft.Issues.list = action.payload.list;
      draft.Issues.page = action.payload.page;
      draft.Issues.hasmore = action.payload.hasmore;
      break;
    default:
      break;
  }
}, INITIAL_STATE);