import produce from "immer";
import { SET_DELETE } from "../actions/Delete";

const INITIAL_STATE = {
    deleteFlag:""
}

export const deleteReducer = produce((draft,action) => {
    switch (action.type) {
        case SET_DELETE:
            draft.deleteFlag = action.payload;
            break;
        default:
            break;
    }
}, INITIAL_STATE)