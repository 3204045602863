import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Offcanvas,
    Modal,
    Form,
    Container,
    Row,
    Col,
    Button
} from "react-bootstrap";
import { addUsers, updateUsers } from "../../../store/actions/Users";
import { connect } from "react-redux";

const AddEdit = ({
    user,
    updateUsers,
    addUsers,
    onShow,
    onHide
}) => {
    //Page Onload

    var initial_state = {
        type: "Add",
        userName: "",
        userEmail: "",
        password: "",
        conform_password: "",
        role: "",
        userId: "",
        status: "",
        error: {
            userName: "",
            userEmail: "",
            password: "",
            conform_password: "",
            role: "",
        },
    };
    // Set State value using immer
    const [address_form, setAddressForm] = useImmer(initial_state);
    useEffect(() => {
        if (user) {
            setAddressForm((draft) => {
                draft.userName = user.userName;
                draft.userEmail = user.userEmail;
                draft.password = user.password;
                draft.conform_password = user.conform_password;
                draft.role = user.role;
                draft.userId = user.userId;
                draft.type = user.type;
                draft.status = user.status;
            })
        }
    }, [user]);

    const MAIL_REGEX =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //To handle onchange function
    const onInputChange = (e) => {
        setAddressForm((draft) => {
            draft[e.target.name] = e.target.value;
        });
    };
    //form submit
    const Submit = async (e) => {
        e.preventDefault();
        if (address_form.userName.length === 0) {
            setAddressForm((draft) => {
                draft.error.userName = "Please enter User name.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.userName = "";
            })
        }

        if (address_form.userName.length > 256) {
            setAddressForm((draft) => {
              draft.error.userName = "Please enter upto 256 characters for User name.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.userName = "";
            });
          }

        if (address_form.userEmail.length === 0) {
            setAddressForm((draft) => {
                draft.error.userEmail = "Please enter email.";
            })
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.userEmail = ""
            })
        }

        if (address_form.userEmail.length > 256) {
            setAddressForm((draft) => {
              draft.error.userEmail = "Please enter upto 256 characters for email.";
            });
            return false;
          } else {
            setAddressForm((draft) => {
              draft.error.userEmail = "";
            });
          }

        if (!address_form.userEmail.match(MAIL_REGEX)) {
            setAddressForm((draft) => {
                draft.error.userEmail = "Enter a valid email."
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.userEmail = "";
            });
        }

        if (address_form.password) {
            if (address_form.password.length === 0) {
                setAddressForm((draft) => {
                    draft.error.password = "Please enter password.";
                })
                return false;
            } else {
                setAddressForm((draft) => {
                    draft.error.password = ""
                })
            }
    
            if (address_form.password.length > 256) {
                setAddressForm((draft) => {
                  draft.error.password = "Please enter upto 256 characters for password.";
                });
                return false;
              } else {
                setAddressForm((draft) => {
                  draft.error.password = "";
                });
              }

              
            if (address_form.password !== address_form.conform_password) {
                setAddressForm((draft) => {
                    draft.error.conform_password = "Password mismatch. ";
                });
                return false;
            }else {
                setAddressForm((draft) => {
                  draft.error.conform_password = "";
                });
              }
            }
        if (address_form.role.length === 0) {
            setAddressForm((draft) => {
                draft.error.role = "Select a role.";
            });
            return false;
        } else {
            setAddressForm((draft) => {
                draft.error.role = "";
            })
        }
        if (address_form.type == "Add") {
            const data = {
                apiCall: "addUser",
                userName: address_form.userName,
                userEmail: address_form.userEmail,
                password: address_form.password,
                role: address_form.role
            };
         addUsers(data);
        } else if (address_form.type == "Edit") {
            const data = {
                apiCall: "updateUser",
                userName: address_form.userName,
                userEmail: address_form.userEmail,
                password: address_form.password,
                role: address_form.role,
                userId: address_form.userId,
                status: address_form.status
            }
          updateUsers(data);
        }
    };
    // This sec for Password view 
    const [statePassView, setStatePassView] = useImmer(false)
    const onPasswordView = () => {
        setStatePassView(!statePassView)
    }
    const onclose = () =>{
        setAddressForm(initial_state)
        onHide()
    }
    return (
        <>
            <React.Fragment>
                <Offcanvas
                    show={onShow}
                    onHide={onclose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    placement="end"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h6 className="font-weight-bold">{address_form.type} user</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="formBasicUserName">
                                            <Form.Label className="font-weight-bold requiredField">
                                                User name
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter user name"
                                                value={address_form.userName}
                                                name="userName"
                                                className={
                                                    address_form.error.userName.length > 0
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {
                                                address_form.error.userName.length > 0 && (
                                                    <span className="invalid-feedback">
                                                        {address_form.error.userName}
                                                    </span>
                                                )
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} >
                                        <Form.Group className="mb-3" controlId="formBasicUserEmail">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter email"
                                                value={address_form.userEmail}
                                                name="userEmail"
                                                className={
                                                    address_form.error.userEmail.length > 0
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {
                                                address_form.error.userEmail.length > 0 && (
                                                    <span className="invalid-feedback">
                                                        {address_form.error.userEmail}
                                                    </span>
                                                )
                                            }

                                        </Form.Group>
                                    </Col>
                                    {address_form.type == "Add" && (
<>
                                        <Col xs={12}>
                                            <Form.Group className="mb-2" controlId="formBasicFirstName">
                                                <Form.Label className="font-weight-bold requiredField">
                                                    Password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Enter password"
                                                    value={address_form.password}
                                                    name="password"
                                                    className={address_form.error.password.length > 0 ? "is-invalid" : ""}
                                                    onChange={onInputChange}
                                                    autoComplete="off"
                                                />
                                                {address_form.error.password.length > 0 && (
                                                    <span className="invalid-feedback">{address_form.error.password}</span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                        <Form.Group className="mb-2" controlId="formBasicFirstName">
                                            <Form.Label className="font-weight-bold requiredField">
                                            Confirm password
                                            </Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Enter confirm password"
                                                value={address_form.conform_password}
                                                name="conform_password"
                                                className={address_form.error.conform_password.length > 0 ? "is-invalid" : ""}
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            />
                                            {address_form.error.conform_password.length > 0 && (
                                                <span className="invalid-feedback">{address_form.error.conform_password}</span>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    </>
                                    )}
                                    <Col xs={12}>
                                        <Form.Group className="mb-3" controlId="formBasicRole">
                                            <Form.Label className="font-weight-bold requiredField">
                                                Role
                                            </Form.Label>
                                            <Form.Select
                                                type="text"
                                                placeholder="Role"
                                                value={address_form.role}
                                                name="role"
                                                className={
                                                    address_form.error.role.length > 0
                                                        ? "is-invalid"
                                                        : ""
                                                }
                                                onChange={onInputChange}
                                                autoComplete="off"
                                            >
                                                <option value="0">Select role</option>
                                                <option value="1">Admin</option>
                                                <option value="2">Employee</option>
                                            </Form.Select>
                                            {
                                                address_form.error.role.length > 0 && (
                                                    <span className="invalid-feedback">
                                                        {address_form.error.role}
                                                    </span>
                                                )
                                            }

                                        </Form.Group>
                                    </Col>
                                </Row>
                                {address_form.type == "Edit" && (
                                    <>
                                        <a href="#" onClick={onPasswordView}>Click to change password </a>
                                        {statePassView && (
                                            <>
                                                <Row>
                                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                                        <Form.Label className="font-weight-bold requiredField">
                                                            Password
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Enter your password..."
                                                            value={address_form.password}
                                                            name="password"
                                                            className={address_form.error.password.length > 0 ? "is-invalid" : ""}
                                                            onChange={onInputChange}
                                                            autoComplete="off"
                                                        />
                                                        {address_form.error.password.length > 0 && (
                                                            <span className="invalid-feedback">{address_form.error.password}</span>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                                        <Form.Label className="font-weight-bold requiredField">
                                                            Confirm password
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Enter your conform password..."
                                                            value={address_form.conform_password}
                                                            name="conform_password"
                                                            className={address_form.error.conform_password.length > 0 ? "is-invalid" : ""}
                                                            onChange={onInputChange}
                                                            autoComplete="off"
                                                        />
                                                        {address_form.error.conform_password.length > 0 && (
                                                            <span className="invalid-feedback">{address_form.error.conform_password}</span>
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                            </>

                                        )
                                        }
                                    </>
                                )

                                }

                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                type="submit"
                                className="btnModelSubmit"
                                onClick={Submit}
                            >
                                {address_form.type == "Add" ? ("Save") : ("Update")}
                            </Button>

                            <Button className="btnModelClose" onClick={onclose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Offcanvas>
            </React.Fragment>
        </>
    );
};

const mapStateToProps = (state) => {
    return {

    };
};

export default connect(mapStateToProps, { addUsers, updateUsers })(AddEdit);
