export const ALL_USER_EXPORT               ="[AllUser] user all export";
export const ALL_USER_EXPORT_SUCCESS       ="[AllUser] user all export Success";
export const ALL_USER_EXPORT_ERROR         ="[AllUser] user all export error";
export const SET_ALL_USER_EXPORT           ="[AllUser] user all export set status";

export const ALL_SOFTWARE_EXPORT               ="[AllSoftware] software all export";
export const ALL_SOFTWARE_EXPORT_SUCCESS       ="[AllSoftware] software all export Success";
export const ALL_SOFTWARE_EXPORT_ERROR         ="[AllSoftware] software all export error";
export const SET_ALL_SOFTWARE_EXPORT           ="[AllSoftware] software all export set status";

export const ALL_HARDWARE_EXPORT               ="[AllHardware] hardware all export";
export const ALL_HARDWARE_EXPORT_SUCCESS       ="[AllHardware] hardware all export Success";
export const ALL_HARDWARE_EXPORT_ERROR         ="[AllHardware] hardware all export error";
export const SET_ALL_HARDWARE_EXPORT           ="[AllHardware] hardware all export set status";

export const ALL_COMPUTER_EXPORT               ="[AllComputer] computer all export";
export const ALL_COMPUTER_EXPORT_SUCCESS       ="[AllComputer] computer all export Success";
export const ALL_COMPUTER_EXPORT_ERROR         ="[AllComputer] computer all export error";
export const SET_ALL_COMPUTER_EXPORT           ="[AllComputer] computer all export set status";

export const getAllUserExport = (data) => ({
    type: ALL_USER_EXPORT,
    payload: data
});

export const getAllUserExportSuccess = (data) => ({
    type: ALL_USER_EXPORT_SUCCESS,
    payload: data
});

export const getAllUserExportError = (data) => ({
    type: ALL_USER_EXPORT_ERROR,
    payload: data
});

export const setAllUserExport = (data) => ({
    type: SET_ALL_USER_EXPORT,
    payload: data
});

export const getAllSoftwareExport = (data) => ({
    type: ALL_SOFTWARE_EXPORT,
    payload: data
});

export const getAllSoftwareExportSuccess = (data) => ({
    type: ALL_SOFTWARE_EXPORT_SUCCESS,
    payload: data
});

export const getAllSoftwareExportError = (data) => ({
    type: ALL_SOFTWARE_EXPORT_ERROR,
    payload: data
});

export const setAllSoftwareExport = (data) => ({
    type: SET_ALL_SOFTWARE_EXPORT,
    payload: data
});

export const getAllHardwareExport = (data) => ({
    type: ALL_HARDWARE_EXPORT,
    payload: data
});

export const getAllHardwareExportSuccess = (data) => ({
    type: ALL_HARDWARE_EXPORT_SUCCESS,
    payload: data
});

export const getAllHardwareExportError = (data) => ({
    type: ALL_HARDWARE_EXPORT_ERROR,
    payload: data
});

export const setAllHardwareExport = (data) => ({
    type: SET_ALL_HARDWARE_EXPORT,
    payload: data
});

export const getAllComputerExport = (data) => ({
    type: ALL_COMPUTER_EXPORT,
    payload: data
});

export const getAllComputerExportSuccess = (data) => ({
    type: ALL_COMPUTER_EXPORT_SUCCESS,
    payload: data
});

export const getAllComputerExportError = (data) => ({
    type: ALL_COMPUTER_EXPORT_ERROR,
    payload: data
});

export const setAllComputerExport = (data) => ({
    type: SET_ALL_COMPUTER_EXPORT,
    payload: data
});