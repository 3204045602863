import React from "react";
import {
    Card,
    Row,
    Button,
    Dropdown,
    Container
} from "react-bootstrap";
import { connect } from "react-redux";
function DetailsView({
    onBack,
    User
}) {
    return (
        <React.Fragment>
            <Button
                className="btn btn-primary my-1"
                onClick={onBack}
                title="Go back"
            >
                <i className="bi bi-arrow-left"></i> Back
            </Button>
            <Card>
                <Card.Body>
                    <Container>
                        <Row>
                            <div>
                                <strong> User name : </strong>{User.Username}
                            </div>
                            <Dropdown.Divider />
                            <div>
                                <strong> Email : </strong> {User.Email}
                            </div>
                            <div>
                                <strong> Role :</strong> {User.RoleName}
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {};
}
export default connect(mapStateToProps, null)(DetailsView);