import React from "react";
import { useEffect } from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { setprofilestatus } from "../../store/actions/Users"
import { useImmer } from "use-immer";
import ProfileEditModel from "./ProfileEditModel";
function Profile({ username, userEmail, editProfileStatus, setprofilestatus }) {

    const [state, setState] = useImmer(false)
    const onShowEditModel = (e) => {
        setState(true)
    }
    const onHideFn = () => {
        setState(false)
    }
    useEffect(() => {
        if (editProfileStatus) {
            setState(false)
            setprofilestatus(false)
        }
    }, [editProfileStatus])

    return (
        <>
            {
                state == true ? (
                    <ProfileEditModel
                        onShow={state}
                        onHide={onHideFn}
                        username={username}
                        email={userEmail}
                    />
                ) : (<></>)
            }

            <Card className="mb-4">
                <Card.Body>
                    <Row>
                        <div className="d-flex justify-content-between">
                            <div>
                                <strong>Profile</strong>
                            </div>
                            <div>
                                <Button className="pull-right mb-2" variant="primary" size="sm" onClick={(e) => {
                                    e.preventDefault();
                                    onShowEditModel()
                                }} >
                                    <i className="bi bi-pencil-fill"></i> Edit
                                </Button>
                            </div>
                        </div>
                        <Dropdown.Divider />
                        <div>
                            <strong>Name:</strong> {username}
                        </div>
                        <div>
                            <strong>Email:</strong> {userEmail}
                        </div>
                    </Row>
                </Card.Body>
            </Card>

        </>

    );
}
const mapStateToProps = (state) => {
    return {
        editProfileStatus: state.users.editProfileStatus
    };
};

export default connect(mapStateToProps, { setprofilestatus }
)(Profile);
