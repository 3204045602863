import React from "react";
import { connect } from "react-redux";
import { Col, Button, Row } from "react-bootstrap";
import IssueView from "../Issues/IssuesView"
import ComputerDetails from "../Computer/ComputerDetails/DetailView"
import { getIssues, getNextIssues, setIssueAddStatus, deleteIssue } from "../../store/actions/issues"
import {getDetailsForEditComputer} from "../../store/actions/Computers"
import IssueAddEdit from "../Computer/ComputerDetails/Issues/IssueAddEdit";
import { getAllUserExport, getAllComputerExport } from "../../store/actions/Export";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import FilterComponent from "../Filter/FilterComponent";
const IssuesPage = ({
    getIssues,
    issuesList,
    hasmore,
    nextPage,
    getNextIssues,
    getDetailsForEditComputer,
    deleteIssue,
    issues_status,
    setIssueAddStatus,
    getAllUserExport,
    getAllComputerExport,
    UserList,
    role_id
}) => {
    useEffect(() => {
        getIssues()
        const data = {
            full_result: 1
        }
        getAllUserExport(data)
        getAllComputerExport(data)
    }, [])
    const getnextIssuesList = () => {
        var data = {
            page: nextPage,
            ...getFilterValues()
        }
        getNextIssues(data)
    }
    const [showIssueList, setShowIssueList] = useImmer(true);
    const [showComputerDetails, setShowComputerDetail] = useImmer(false);
    const getComputerDetailView = (id) => {
        const data = {
            computerId: id
        }
        getDetailsForEditComputer(data);
        setShowIssueList(false);
        setShowComputerDetail(true);
    }
    const onBack = () => {
        setShowIssueList(true);
        setShowComputerDetail(false);
    }
    const initial_state_issues = {
        type:"Add",
        issue_id:"",
        computer_id:"",
        title:"",
        description:"",
        priority:"",
        status:"",
        issued_by:"",
        assignedTo:"",
        reason: ""
    };

    const[getIssueDetails,SetStateAddEdit]= useImmer(initial_state_issues)
    const[show,setshow]= useImmer(false)
    const onEditIssue = (data) =>{
        SetStateAddEdit((draft) => {
            draft.type= "Edit";
            draft.title = data.title;
            draft.description = data.description;
            draft.priority = data.priority;
            draft.status = data.status;
            draft.issue_id = data.issue_id;
            draft.computer_id = data.computer_id;
            draft.issued_by = data.issued_by;
            draft.assignedTo = data.assignedTo;
            draft.reason = data.ReasonToResolve;
        });
        setshow(true)
    }
    const onIssueCancel = () =>{
        setshow(false)
        SetStateAddEdit(initial_state_issues)
    }
    const onDeleteIssue = (data) =>{
        var data ={
            issue_id: data.issue_id,
            computer_id: data.computer_id 
        }
        deleteIssue(data)
    }
    useEffect(()=>{
        if(issues_status){
            setshow(false)
            getIssues()
            setIssueAddStatus(false)
        }
    },[issues_status])
    const handleFilterChange = (name, e) => {
        switch (name) {
            case "issueType":
                setFilter((draft) => {
                    const issue_index = draft.findIndex((d) => d.name === "issueType");
                    if (issue_index !== -1) {
                        // Set team value as current value
                        draft[issue_index].value = e;
                    }
                });
                break;
            case "keyword":
                setFilter((draft) => {
                    const search_index = draft.findIndex((d) => d.name === "keyword");
                    if (search_index !== -1) {
                    // Set venue value as current value
                    draft[search_index].value = e.target.value;
                    }
                });
                break;
            case "assignedTo":
                setFilter((draft) => {
                    const user_index = draft.findIndex((d) => d.name === "assignedTo");
                    if (user_index !== -1) {
                      // Set assignedTo value as current value
                      draft[user_index].value = e;
                    }
                  });
                break;
            default:
                break;
        }
    };
    const filterInitialState = [
        {
            placeholder: "Search",
            type: "text",
            value: null,
            name: "keyword",
            options: [],
            onChange: handleFilterChange,
        },
        {
            placeholder: "Status",
            type: "select",
            value: null,
            name: "issueType",
            options: [
                {
                    id: "",
                    value: "",
                    label: "All"
                },
                {
                    id: "Open",
                    value: "Open",
                    label: "Open"
                },
                {
                    id: "Inprogress",
                    value: "Inprogress",
                    label: "Inprogress"
                },
                {
                    id: "Closed",
                    value: "Closed",
                    label: "Closed"
                }

            ],
            onChange: handleFilterChange,
        },
        {
            placeholder: "Assignee",
            type: "select",
            value: null,
            name: "assignedTo",
            options: [],
            onChange: handleFilterChange,
          }
    ]
    const [getFilter, setFilter] = useImmer(filterInitialState)
    const reset = () => {
        // Set all filter values to null
        setFilter((draft) => {
            draft.forEach((f) => (f.value = ""));
        });
        getIssues();
    }
    const getFilterValues = () => {
        var filter_values = {};
        getFilter.forEach((f) => {
            if (f.value) {
                filter_values[f.name] = f.type === "select" ? f.value.id : f.value;            
            }
        });
        return filter_values;
    };
    const filterSubmit = () => {
        let keyVal = {
            ...getFilterValues(),
        };
        getIssues(keyVal);
    }
    const OnAddEdit = () =>{
        setshow(true)
    }
    useEffect(() => {
        const userOption = UserList.map((user) => ({
          id: user.UserID,
          value: user.UserID,
          label: user.Username,
        }));
        setFilter((draft) => {
          const systemtype_index = draft.findIndex((d) => d.name === "assignedTo");
          if (systemtype_index !== -1) {
            // Set systemType value as current value
            draft[systemtype_index].options = userOption;
          }
        });
    
      }, [UserList]) 
    return (
        <>
        {showIssueList && 
        <div className="d-flex justify-content-end">
                            <Button
                                size="sm"
                                className="btn btn-primary my-1"
                                onClick={OnAddEdit}
                                title="Add new issue"
                            >
                              <i className="bi bi-plus"></i>  Add new issue
                            </Button>
                        </div>
        }
        {showIssueList && 
            <Row>
            <Col>
                    <FilterComponent
                        filters={getFilter}
                        onApply={filterSubmit}
                        onClear={reset}
                    />
            </Col>
            </Row>
        }
        {showIssueList &&
            <IssueView 
           issues={issuesList}
           hasmore={hasmore}  
           getnextissues={getnextIssuesList}
           computerDetailsView={getComputerDetailView}  
           onDeleteIssue={onDeleteIssue}
           onEditIssue={onEditIssue}  
           />
        }
           {showComputerDetails &&
                    <ComputerDetails
                        onBack={onBack}
                        isAdmin ={role_id}
                    />
                }

            <IssueAddEdit 
             onShow={show}
             onclose ={onIssueCancel}
             computer_id={getIssueDetails.computer_id}
             issues={getIssueDetails}
             />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        issuesList: state.issues.Issues.list,
        hasmore: state.issues.Issues.hasmore,
        nextPage: state.issues.Issues.page,
        issues_status:  state.computers.issue_add_edit_status,
        UserList: state.allList.allUsers.length > 0 ? state.allList.allUsers : [],
        role_id: state.profile.RoleID == "1" ? true : false,
    };
};

export default connect(mapStateToProps, { getIssues , getNextIssues, getDetailsForEditComputer, deleteIssue, setIssueAddStatus,getAllUserExport,
    getAllComputerExport
})(IssuesPage);