import {
  GETSOFTWARES,
  GETSOFTWARES_SUCCESS,
  GETSOFTWARES_ERROR,
  getSoftwaresSuccess,
  getSoftwaresError,
  setSoftwares,

  GET_NEXT_SOFTWARES,
  GET_NEXT_SOFTWARES_SUCCESS,
  GET_NEXT_SOFTWARES_ERROR,
  getNextSoftwaresSuccess,
  getNextSoftwaresError,
  getSoftwares,

  ADD_SOFTWARES,
  ADD_SOFTWARES_SUCCESS,
  ADD_SOFTWARES_ERROR,
  addSoftwareSuccess,
  addSoftwareError,
  setSoftwareAddEditStatus,
  
  UPDATE_SOFTWARES,
  UPDATE_SOFTWARES_SUCCESS,
  UPDATE_SOFTWARES_ERROR, 
  updateSoftwareSuccess,
  updateSoftwareError,
  
  GET_SOFTWARE_DETAIL,
  GET_SOFTWARE_DETAIL_SUCCESS,
  GET_SOFTWARE_DETAIL_ERROR,
  getSoftwareDetail,
  getSoftwareDetailSuccess,
  getSoftwareDetailError,
  setSoftwareDetail,

  DELETE_SOFTWARES,
  DELETE_SOFTWARES_SUCCESS ,
  DELETE_SOFTWARES_ERROR ,
  deleteSoftwareSuccess,
  deleteSoftwareError

} from "../../actions/Softwares";
import {getAllSoftwareExport} from "../../actions/Export"
import { setDelete } from "../../actions/Delete";
import { apiRequest } from "../../actions/api";
import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";

export const getSoftwaresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GETSOFTWARES) {
    dispatch(
      apiRequest(
        "GET",
        "/apiCall.php?apiCall=getAllSoftwares",
        action.payload,
        null,
        getSoftwaresSuccess,
        getSoftwaresError,
        false,
        "[Login]"
      )
    );
  }
};

export const processgetSoftwares = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GETSOFTWARES_SUCCESS) {
    var is_hasmore =false
    if (action.payload.next){
      is_hasmore =true
    }
    const result = {
      list: action.payload.data,
      page: action.payload.next,
      hasmore:is_hasmore
    }
    dispatch(setSoftwares(result));
  }

  if (action.type === GETSOFTWARES_ERROR) {

  }
};

export const getNextSoftwaresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_NEXT_SOFTWARES) {
    dispatch(
      apiRequest(
        "GET",
        "/apiCall.php?apiCall=getAllSoftwares",
        action.payload,
        null,
        getNextSoftwaresSuccess,
        getNextSoftwaresError,
        false,
        "[software]"
      )
    );
  }
};

export const processgetNextSoftwares = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type === GET_NEXT_SOFTWARES_SUCCESS) {
    var is_hasmore =false
    if (action.payload.next){
      is_hasmore =true
    }
    const NextList = action.payload.data
    const prevList = getState().softwares.softwares.list;
    const currtickets = [...prevList, ...NextList];
    const newData = {
      list: currtickets,
      page:action.payload.next,
      hasmore:is_hasmore
    };
    dispatch(setSoftwares(newData));
  }

  if (action.type === GET_NEXT_SOFTWARES_ERROR) {

  }
};



export const addSoftwaresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_SOFTWARES) {
    dispatch(
      apiRequest(
        "POST",
        "/apiCall.php",
        null,
        action.payload,
        addSoftwareSuccess,
        addSoftwareError,
        false,
        "[Login]"
      )
    );
  }
};

export const processAddSoftwares = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === ADD_SOFTWARES_SUCCESS) {
    var status =action.payload.status
    if(status == true){
      dispatch(getSoftwares());
      const data = {
        full_result: 1
      }
      dispatch(getAllSoftwareExport(data))
      dispatch(setSoftwareAddEditStatus(true));
      dispatch(
        setNotification(
          "Software added",
          "Success",
          notification_types.success
        )
      );
    }else if(status == "exist"){
      dispatch(
        setNotification(
            "Software with similar name or serial number found",
            "Software with similar name or serial number found",
            notification_types.error
        )
      );
    }
  }

  if (action.type === ADD_SOFTWARES_ERROR) {

  }
};


export const updateSoftwaresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === UPDATE_SOFTWARES) {
    dispatch(
      apiRequest(
        "POST",
        "/apiCall.php",
        null,
        action.payload,
        updateSoftwareSuccess,
        updateSoftwareError,
        false,
        "[Login]"
      )
    );
  }
};

export const processUpdateSoftwares = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === UPDATE_SOFTWARES_SUCCESS) {
    var status =action.payload.status
    if(status == true){
    dispatch(getSoftwares());
    const data = {
      full_result: 1
    }
    dispatch(getAllSoftwareExport(data))
    dispatch(setSoftwareAddEditStatus(true));
    dispatch(
      setNotification(
        "Software updated",
        "Success",
        notification_types.success
      )
    );
    }else if(status == "exist"){
      dispatch(
        setNotification(
            "Software with similar Name or serial number found",
            "Software with similar Name or serial number found",
            notification_types.error
        )
      );
    }
  }

  if (action.type === UPDATE_SOFTWARES_ERROR) {

  }
};

export const getSoftwareDetailsFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === GET_SOFTWARE_DETAIL) {
    dispatch(
      apiRequest(
        "POST",
        "/apiCall.php?apiCall=getSoftwareDetails",
        null,
        action.payload,
        getSoftwareDetailSuccess,
        getSoftwareDetailError,
        false,
        "[Softwares]"
      )
    );
  }
};

export const processgetSoftwareDetails = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GET_SOFTWARE_DETAIL_SUCCESS) {
    dispatch(setSoftwareDetail(action.payload.data));
  }

  if (action.type === GET_SOFTWARE_DETAIL_ERROR) {

  }
};

export const deleteSoftwaresFlow = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === DELETE_SOFTWARES) {
    dispatch(
      apiRequest(
        "POST",
        "apiCall.php?apiCall=deleteSoftware",
        action.payload,
        null,
        deleteSoftwareSuccess,
        deleteSoftwareError,
        false,
        "[Login]"
      )
    );
  }
};

export const processdeleteSoftwares = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === DELETE_SOFTWARES_SUCCESS) {
    dispatch(getSoftwares());
    const data = {
      full_result: 1
    }
    dispatch(getAllSoftwareExport(data))
    dispatch(setSoftwareAddEditStatus(true));
    dispatch(setDelete("software"));
    dispatch(
      setNotification(
        "Software deleted",
        "Success",
        notification_types.success
      )
    );
  }

  if (action.type === DELETE_SOFTWARES_ERROR) {
  }
};
export const softwaresMdl = [
  getSoftwaresFlow,
  processgetSoftwares,
  getNextSoftwaresFlow,
  processgetNextSoftwares,
  addSoftwaresFlow,
  processAddSoftwares,
  getSoftwareDetailsFlow,
  processgetSoftwareDetails,
  updateSoftwaresFlow,
  processUpdateSoftwares,
  deleteSoftwaresFlow,
  processdeleteSoftwares
];