import { LOAD_APP_STATUS } from "../actions/app";
import produce from "immer";

const INITIAL_STATE = {
    app_status:true
};

export const appReducer = produce((draft, action) => {
  switch (action.type) {
    case LOAD_APP_STATUS:
      draft.app_status = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);