export const GETNOTIFICATION                       = "[Notifications] Notifications";
export const GETNOTIFICATION_SUCCESS                = "[Notifications] Notifications success";
export const GETNOTIFICATION_ERROR                  = "[Notifications] Notifications error";
export const SET_NOTIFICATION                       = "[Notifications] Notifications set";

export const getNotifications = (data) => ({
    type: GETNOTIFICATION,
    payload: data
  });
  export const getNotificationsSuccess = (data) => ({
    type: GETNOTIFICATION_SUCCESS,
    payload: data,
  });
  
  export const getNotificationsError = (data) => ({
    type: GETNOTIFICATION_ERROR,
    payload: data,
  });

  export const setNotifications = (data) => ({
    type: SET_NOTIFICATION,
    payload: data,
  });
