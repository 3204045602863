import React from "react";
import { connect } from "react-redux";

import { Col, Row, Button, Container } from 'react-bootstrap';
import CardComponent from "./HardwareCard/Card"
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from "../Loader/Loader"

import "./Hardware.css";

const HardwareView = ({
    hardwares,
    onShowAddModel,
    hardware,
    getHardwareDetailView,
    editHardware,
    deleteHardware,
    getnextHardwares,
    hasmore,
    isAdmin
}) => {
    return (
        <>
                <Row xs={1} md={1} className="">
                    <Col>
                        <InfiniteScroll
                            dataLength={hardwares.length}
                            next={getnextHardwares}
                            style={{ overflowX: "hidden" }}
                            hasMore={hasmore}
                            loader={
                                <h4 style={{ textAlign: "center" }}>
                                    <Spinner as="span" animation="border" role="status" />
                                </h4>
                            }
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {hardwares.length !== 0 ? (
                                         <>
                                         {hardwares.length > 5 && (
                                             <b>No more hardwares found</b>
                                         )

                                         }</>
                                       
                                    ) : (
                                        <b>No hardwares Found</b>
                                    )}
                                </p>
                            }
                        >
                            {
                                hardwares.map((hardware, idx) => {
                                    return (
                                        <CardComponent
                                            key={idx}
                                            s_no={idx + 1}
                                            HardwareName={hardware.HardwareName}
                                            HardwareID={hardware.HardwareID}
                                            SerialNumber={hardware.SerialNumber}
                                            Status={hardware.Status == "1" ? "Active" : "Inactive"}
                                            Description={hardware.Description}
                                            getHardwareDetailView={getHardwareDetailView}
                                            hardware={hardware}
                                            editHardware={editHardware}
                                            deleteHardware={deleteHardware}
                                            isAdmin ={isAdmin}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(HardwareView);