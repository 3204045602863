import React from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Container } from 'react-bootstrap';
import IssueCard from "./Card"
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from "../Loader/Loader";


const IssuesView = ({
    issues,
    getnextissues,
    hasmore,
    computerDetailsView,
    onDeleteIssue,
    onEditIssue
}) => {
    return (
        <>
                <Row xs={1} md={1} className="">
                    <Col>
                        <InfiniteScroll
                            dataLength={issues.length}
                            next={getnextissues}
                            style={{ overflowX: "hidden" }}
                            hasMore={hasmore}
                            loader={
                                <h4 style={{ textAlign: "center" }}>
                                    <Spinner as="span" animation="border" role="status" />
                                </h4>
                            }
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    {issues.length !== 0 ? (
                                        <>
                                            {issues.length > 5 && (
                                                <b>No more issues found</b>
                                            )

                                            }</>

                                    ) : (
                                        <b>No issues Found</b>
                                    )}
                                </p>
                            }
                        >
                            {
                                issues.map((issue, idx) => {
                                    return (
                                        <IssueCard
                                            key={idx}
                                            issue_id={issue.issue_id}
                                            computer_id={issue.computer_id}
                                            title={issue.title}
                                            description={issue.description}
                                            priority={issue.priority}
                                            status={issue.status}
                                            issued_date={issue.created_date}
                                            IssuedPerson_Name={issue.IssuedPerson_Name}
                                            assignee_Name={issue.assignee_Name}
                                            reason = {issue.ReasonToResolve}
                                            issue={issue}
                                            onclick={computerDetailsView}
                                            editIssue={onEditIssue}
                                            deleteIssue={onDeleteIssue}
                                        />
                                    )
                                })
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, null)(IssuesView);