import React from "react";
import {
    Card,
    Row,
    Col,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect } from "react";

import { getSoftwareDetail } from "../../../store/actions/Softwares";

function DetailsView({
    onBack,
    Software,
    softwareDetail,
    getSoftwareDetail
}) {

    useEffect(() => {
        var data = {
            softwareID: Software.SoftwareID
        }
        getSoftwareDetail(data);
    }, [])

    return (
        <React.Fragment>
            <Button
                className="btn btn-primary my-1"
                onClick={onBack}
                title="Go back"
            >
                <i className="bi bi-arrow-left"></i> Back
            </Button>
            <Card className="mb-4">
                <Card.Body>
                    <Row>
                        <div>
                            <strong>Software name: </strong>{Software.SoftwareName}
                        </div>
                        <Dropdown.Divider />
                        <div>
                            <strong>Software type: </strong>{Software.SoftwareType}
                        </div>
                        <div>
                            <strong>Serial number: </strong>{Software.SerialNumber}
                        </div>
                        <div>
                            <strong>Description:</strong> {Software.Description}
                        </div>
                        <Dropdown.Divider />
                        <div>
                            <strong>Computer List:</strong><br />
                            {
                                softwareDetail.map((data) => {
                                    return(
                                        <>
                                            { 
                                                data.ComputerName == null ? (
                                                    <></>
                                                ) : ( 
                                                    data.licenceType === "Licensed"  ? (
                                                        <Badge bg="success" className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                    ) : data.licenceType === "OpenSource" ? (
                                                        <Badge className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                    ) : (
                                                        <Badge bg="danger" className = "m-1 overflow-hidden">{data.ComputerName}</Badge>
                                                    )
                                                )
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    return {
        softwareDetail: state.softwares.softwareDetail ? state.softwares.softwareDetail : [],
    };
};
export default connect(mapStateToProps, { getSoftwareDetail })(DetailsView);
