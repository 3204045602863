import {
    GETHARDWARES,
    GETHARDWARES_SUCCESS,
    GETHARDWARES_ERROR,
    getHardwaresSuccess,
    getHardwaresError,
    getHardwares,
    setHardwares,

    GET_NEXT_HARDWARES,
    GET_NEXT_HARDWARES_SUCCESS,
    GET_NEXT_HARDWARES_ERROR,
    getNextHardwaresSuccess,
    getNextHardwaresError,

    GET_HARDWARE_DETAIL,
    GET_HARDWARE_DETAIL_SUCCESS,
    GET_HARDWARE_DETAIL_ERROR,
    getHardwareDetail,
    getHardwareDetailSuccess,
    getHardwareDetailError,
    setHardwareDetail,

    ADD_HARDWARES,
    ADD_HARDWARES_SUCCESS,
    ADD_HARDWARES_ERROR,
    addHardwareSuccess,
    addHardwareError,
    setHardwareAddEditStatus,
    UPDATE_HARDWARES,
    UPDATE_HARDWARES_SUCCESS,
    UPDATE_HARDWARES_ERROR, 
    updateHardwareSuccess,
    updateHardwareError,
    DELETE_HARDWARES,
    DELETE_HARDWARES_SUCCESS ,
    DELETE_HARDWARES_ERROR ,
    deleteHardwareSuccess,
    deleteHardwareError
  } from "../../actions/Hardwares";
  import {getAllHardwareExport} from "../../actions/Export"
  import { setDelete } from "../../actions/Delete";
  import { apiRequest } from "../../actions/api";
  import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";

  export const getHardwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GETHARDWARES) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllHardwares",
          action.payload,
          null,
          getHardwaresSuccess,
          getHardwaresError,
          false,
          "[Login]"
        )
      );
    }
  };

  export const processgetHardwares = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === GETHARDWARES_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const result = {
        list: action.payload.data,
        page: action.payload.next,
        hasmore:is_hasmore
      }
        dispatch(setHardwares(result));
    }

    if (action.type === GETHARDWARES_ERROR) {
     
    }
  };
  export const getNextHardwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_HARDWARES) {
      dispatch(
        apiRequest(
          "GET",
          "/apiCall.php?apiCall=getAllHardwares",
          action.payload,
          null,
          getNextHardwaresSuccess,
          getNextHardwaresError,
          false,
          "[hardwares]"
        )
      );
    }
  };
  
  export const processgetNextHardwares = ({ dispatch, getState }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_NEXT_HARDWARES_SUCCESS) {
      var is_hasmore =false
      if (action.payload.next){
        is_hasmore =true
      }
      const NextList = action.payload.data
      const prevList = getState().hardwares.hardwares.list;
      const currtickets = [...prevList, ...NextList];
      const newData = {
        list: currtickets,
        page:action.payload.next,
        hasmore:is_hasmore
      };
      dispatch(setHardwares(newData));
    }
  
    if (action.type === GET_NEXT_HARDWARES_ERROR) {
  
    }
  };

  export const addHardwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === ADD_HARDWARES) {
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php",
          null,
          action.payload,
          addHardwareSuccess,
          addHardwareError,
          false,
          "[Login]"
        )
      );
    }
  };
  
  export const processAddHardwares = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === ADD_HARDWARES_SUCCESS) {
    var status =action.payload.status
    if(status == true){
      dispatch(getHardwares());
      const data = {
        full_result: 1
      }
      dispatch(getAllHardwareExport(data))
      dispatch(setHardwareAddEditStatus(true));
      dispatch(
        setNotification(
          "Hardware added",
          "Success",
          notification_types.success
        )
      );
      }else if(status == "exist"){
        dispatch(
          setNotification(
              "Hardware with similar Name or serial number found",
              "Software with similar Name or serial number found",
              notification_types.error
          )
        );
      }
    }
  
    if (action.type === ADD_HARDWARES_ERROR) {
  
    }
  };

  export const getHardwareDetailsFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === GET_HARDWARE_DETAIL) {
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php?apiCall=getHardwareDetails",
          null,
          action.payload,
          getHardwareDetailSuccess,
          getHardwareDetailError,
          false,
          "[Softwares]"
        )
      );
    }
  };
  
  export const processgetHardwareDetails = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === GET_HARDWARE_DETAIL_SUCCESS) {
      dispatch(setHardwareDetail(action.payload.data));
    }
  
    if (action.type === GET_HARDWARE_DETAIL_ERROR) {
  
    }
  };
  

  export const updateHardwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === UPDATE_HARDWARES) {
      dispatch(
        apiRequest(
          "POST",
          "/apiCall.php",
          null,
          action.payload,
          updateHardwareSuccess,
          updateHardwareError,
          false,
          "[Login]"
        )
      );
    }
  };
  
  export const processUpdateHardwares = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === UPDATE_HARDWARES_SUCCESS) {
      dispatch(getHardwares());
      const data = {
        full_result: 1
      }
      dispatch(getAllHardwareExport(data))
      dispatch(setHardwareAddEditStatus(true));
      dispatch(
        setNotification(
          "Hardware updated",
          "Success",
          notification_types.success
        )
      );
    }
  
    if (action.type === UPDATE_HARDWARES_ERROR) {
  
    }
  };
  export const deleteHardwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);
    if (action.type === DELETE_HARDWARES) {
      dispatch(
        apiRequest(
          "POST",
          "apiCall.php?apiCall=deleteHardware",
          action.payload,
          null,
          deleteHardwareSuccess,
          deleteHardwareError,
          false,
          "[Login]"
        )
      );
    }
  };
  
  export const processdeleteHardwares = ({ dispatch }) => (next) => (action) => {
    next(action);
  
    if (action.type === DELETE_HARDWARES_SUCCESS) {
      dispatch(getHardwares());
      const data = {
        full_result: 1
      }
      dispatch(getAllHardwareExport(data))
      dispatch(setHardwareAddEditStatus(true));
      dispatch(setDelete("hardware"));
      dispatch(
        setNotification(
          "Hardware deleted",
          "Success",
          notification_types.success
        )
      );
    }
  
    if (action.type === DELETE_HARDWARES_ERROR) {
    }
  };
  export const hardwaresMdl = [
    getHardwaresFlow,
    processgetHardwares,
    getNextHardwaresFlow,
    processgetNextHardwares,
    addHardwaresFlow,
    processAddHardwares,
    getHardwareDetailsFlow,
    processgetHardwareDetails,
    updateHardwaresFlow,
    processUpdateHardwares,
    deleteHardwaresFlow,
    processdeleteHardwares
  ];