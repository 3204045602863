import React from "react";
import "../Login/Login.css"
import "../../assets/css/style.css"
import "../../assets/img/favicon.ico"
import "../../assets/vendor/bootstrap/css/bootstrap.min.css"
import "../../assets/vendor/bootstrap-icons/bootstrap-icons.css"
import "../../assets/vendor/boxicons/css/boxicons.min.css"
import "../../assets/vendor/quill/quill.snow.css"
import "../../assets/vendor/quill/quill.bubble.css"
import "../../assets/vendor/remixicon/remixicon.css"
import "../../assets/vendor/simple-datatables/style.css"
import { login, setLoginStatus } from "../../store/actions/Login"
import { connect } from "react-redux";
import { useImmer } from "use-immer";
import { Form, Button } from "react-bootstrap";
import { useEffect } from "react";
import { Link, useNavigate, NavLink } from 'react-router-dom';
import logo from '../../assets/img/logo_sys.png';
import loginImage from '../../assets/img/login_image.jpg';
import emv from '../../assets/img/emv32.png';
import rs from '../../assets/img/rs32.png';



const LoginComponent = ({ login, islogged, setLoginStatus }) => {
  const navigate = useNavigate();

  var initial_state = {
    show_Pass:false,
    email: "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  useEffect(() => {
    if (islogged) {
      navigate('/dashboard');
      // setLoginStatus(false)
    }
  }, [islogged]);
  const [loginForm, setLoginForm] = useImmer(initial_state);
  const MAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // To handle the Onchange Function
  const handleLoginInputChange = (e) => {
    setLoginForm((draft) => {
      draft[e.target.name] = e.target.value;
    });
  };
  // Form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loginForm.email.length === 0) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter the email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (!loginForm.email.match(MAIL_REGEX)) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter valid email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.email.length > 256) {
      setLoginForm((draft) => {
        draft.error.email = "Please enter upto 256 characters for email.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.email = "";
      });
    }

    if (loginForm.password.length === 0) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter the password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    if (loginForm.password.length > 256) {
      setLoginForm((draft) => {
        draft.error.password = "Please enter upto 256 characters for password.";
      });
      return false;
    } else {
      setLoginForm((draft) => {
        draft.error.password = "";
      });
    }

    var email = loginForm.email;
    var password = loginForm.password;

    let user = {
      apiCall: "userLogin",
      email: email,
      password: password
    };
    login(user);
  }
  const showPass =()=>{
    setLoginForm((draft) => {
      draft.show_Pass = !loginForm.show_Pass;
    });
  }
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
          <div className="card loginCard">
          <div className="card-body">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <img src={loginImage} alt="logo" width='90%'/>
              </div>
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="text-center">
                       <img src={logo} alt="logo" className="loginLogo"/>
                      <h2 className="text-center small">Administrator management system</h2>
                    </div>

                    <form className="row needs-validation" noValidate>
                      < Form.Group
                        className="mb-2 mt-4"
                        controlId="formBasicName"
                      >
                        < Form.Label className="requiredField form-label">
                          Email
                        </ Form.Label>
                        < Form.Control
                          type="text"
                          name="email"
                          value={loginForm.email}
                          className={loginForm.error.email.length > 0 ? "is-invalid" : ""}
                          placeholder="Enter email"
                          onChange={handleLoginInputChange}
                          autoComplete="off"
                        />
                        {loginForm.error.email.length > 0 && (
                          <span className="invalid-feedback">{loginForm.error.email}</span>
                        )}
                      </ Form.Group>

                      < Form.Group
                        className="mb-2 mt-4"
                        controlId="formBasicPassword"
                      >
                        < Form.Label className="requiredField form-label">
                          Password
                        </ Form.Label>
                        < Form.Control
                          type={loginForm.show_Pass ? "text" : "password"}
                          name="password"
                          value={loginForm.password}
                          className={loginForm.error.password.length > 0 ? "is-invalid" : ""}
                          placeholder="Enter password"
                          onChange={handleLoginInputChange}
                        />
                        <Form.Check
                        className="mt-2"
                          type="switch"
                          id="custom-switch"
                          label="Show password"
                          onChange={()=>showPass()}
                        />
                        {loginForm.error.password.length > 0 && (
                          <span className="invalid-feedback">{loginForm.error.password}</span>
                        )}
                      </ Form.Group>

                      <div className="col-12">
                        <p className="small mb-0"><NavLink to="/forgot">Forgot password?</NavLink></p>
                      </div>
                      <div className="col-12">
                        <Button className="btn btn-primary w-100 mt-2" type="submit" onClick={handleSubmit}>Login</Button>
                      </div>
                      <div className="col-12">
                        <Button  variant="outline-primary" className="w-100 mt-1 registerlink"><NavLink to="/register">Register your organization</NavLink></Button>
                      </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row powered-by">
              <div class="col-xs-12 col-md-6">
                <div class="product-label q-pt-none" align="left">
                  <span class="text-caption text-grey-7">A product of  
                  <img src={emv} alt="logo" className="align-bottom tinilogo"/>
                    <a href="https://enminvithaigal.in/" target="_blank">
                      <b>
                        <span className="e"> எ</span>
                        <span className="n">ண்</span>
                        <span className="min">மின்</span>
                        <span className="vithaigal">விதைகள்</span>
                      </b>
                    </a>
                  </span>
                </div>
              </div>
              <div class="col-xs-12 col-md-6">
                <div class="product-label q-pt-none" align="right">
                    <span class="text-caption text-grey-7">Powered by   
                    <img src={rs} alt="logo" className="align-bottom tinilogo"/>
                      <a href="https://rajasri.com/" target="_blank">
                        <b>
                          <span className="ra"> Ra</span>
                          <span className="ja">ja</span>
                          <span className="sri">sri </span>
                          <span className="system">Systems </span>
                        </b>
                      </a>
                    </span>
                </div>
              </div>
            </div>
        </div>
        </section>
      </div>
    </main>


  );
};


const mapStateToProps = (state) => {
  return {
    islogged: state.login.islogged
  };
};

export default connect(mapStateToProps,
  { login, setLoginStatus }
)(LoginComponent);

