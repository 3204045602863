import { SET_PROFILE } from "../actions/auth";
import produce from "immer";

const INITIAL_STATE = {
    UserID: "",
    Username: "",
    Email: "",
    Password: "",
    RoleID: "",
    OrgID: "",
    Status: "",
    CreatedBy: "",
    UpdatedBy: "",
    CreatedDate: "",
    UpdatedDate: ""
};

export const authReducer = produce((draft, action) => {
    switch (action.type) {
        case SET_PROFILE:
                draft.profile = action.payload.profile;
                draft.UserID = action.payload.UserID;
                draft.Username = action.payload.Username;
                draft.Email = action.payload.Email;
                draft.Password = action.payload.Password;
                draft.RoleID = action.payload.RoleID;
                draft.OrgID = action.payload.OrgID;
                draft.Status = action.payload.Status;
                draft.CreatedBy = action.payload.CreatedBy;
                draft.UpdatedBy = action.payload.UpdatedBy;
                draft.CreatedDate = action.payload.CreatedDate;
                draft.UpdatedDate = action.payload.UpdatedDate;
            break;
        default:
            break;
    }
}, INITIAL_STATE);