export const GETSOFTWARES                        = "[Softwares] Softwares ";
export const GETSOFTWARES_SUCCESS                = "[Softwares] Softwares success";
export const GETSOFTWARES_ERROR                  = "[Softwares] Softwares error";
export const SET_SOFTWARES                       = "[Softwares] Softwares set";

export const GET_NEXT_SOFTWARES                        = "[Softwares] nextSoftwares ";
export const GET_NEXT_SOFTWARES_SUCCESS                = "[Softwares] nextSoftwares success";
export const GET_NEXT_SOFTWARES_ERROR                  = "[Softwares] nextSoftwares error";
export const SET_NEXT_SOFTWARES                       = "[Softwares] nextSoftwares set";

export const GET_SOFTWARE_DETAIL                  ="[Softwares] get Software detail";
export const GET_SOFTWARE_DETAIL_SUCCESS          ="[Softwares] get Software detail success";
export const GET_SOFTWARE_DETAIL_ERROR            ="[Softwares] get Software detail error";
export const SET_SOFTWARE_DETAIL                  ="[Softwares] set Software detail";

export const ADD_SOFTWARES                       = "[Softwares] Softwares add";
export const ADD_SOFTWARES_SUCCESS               = "[Softwares] Softwares add success";
export const ADD_SOFTWARES_ERROR                 = "[Softwares] Softwares add error";
export const SET_SOFTWARES_ADD_STATUS            = "[Softwares] Softwares add set";
export const UPDATE_SOFTWARES                       = "[Softwares] Softwares update";
export const UPDATE_SOFTWARES_SUCCESS               = "[Softwares] Softwares update success";
export const UPDATE_SOFTWARES_ERROR                 = "[Softwares] Softwares update error";
export const DELETE_SOFTWARES                       = "[Softwares] Softwares delete";
export const DELETE_SOFTWARES_SUCCESS               = "[Softwares] Softwares delete success";
export const DELETE_SOFTWARES_ERROR                 = "[Softwares] Softwares delete error";


// For user login

export const getSoftwares = (data) => ({
    type: GETSOFTWARES,
    payload: data
  });
  export const getSoftwaresSuccess = (data) => ({
    type: GETSOFTWARES_SUCCESS,
    payload: data,
  });
  
  export const getSoftwaresError = (data) => ({
    type: GETSOFTWARES_ERROR,
    payload: data,
  });

  export const setSoftwares = (data) => ({
    type: SET_SOFTWARES,
    payload: data,
  });
  export const getNextSoftwares = (data) => ({
    type: GET_NEXT_SOFTWARES,
    payload: data
  });
  export const getNextSoftwaresSuccess = (data) => ({
    type: GET_NEXT_SOFTWARES_SUCCESS,
    payload: data,
  });
  
  export const getNextSoftwaresError = (data) => ({
    type: GET_NEXT_SOFTWARES_ERROR,
    payload: data,
  });

  export const setNextSoftwares = (data) => ({
    type: SET_NEXT_SOFTWARES,
    payload: data,
  });
  export const addSoftware = (data) => ({
    type: ADD_SOFTWARES,
    payload: data,
  });

  export const addSoftwareSuccess = (data) => ({
    type: ADD_SOFTWARES_SUCCESS,
    payload: data,
  });

  export const addSoftwareError = (data) => ({
    type: ADD_SOFTWARES_ERROR,
    payload: data,
  });
  export const setSoftwareAddEditStatus = (data) => ({
    type: SET_SOFTWARES_ADD_STATUS,
    payload: data,
  });

  export const getSoftwareDetail = (data) => ({
    type: GET_SOFTWARE_DETAIL,
    payload: data
  })
  export const getSoftwareDetailSuccess = (data) => ({
    type: GET_SOFTWARE_DETAIL_SUCCESS,
    payload: data
  })
  export const getSoftwareDetailError = (data) => ({
    type: GET_SOFTWARE_DETAIL_ERROR,
    payload: data
  })
  export const setSoftwareDetail = (data) => ({
    type: SET_SOFTWARE_DETAIL,
    payload: data
  })

  export const updateSoftware = (data) => ({
    type: UPDATE_SOFTWARES,
    payload: data,
  });

  export const updateSoftwareSuccess = (data) => ({
    type: UPDATE_SOFTWARES_SUCCESS,
    payload: data,
  });

  export const updateSoftwareError = (data) => ({
    type: UPDATE_SOFTWARES_ERROR,
    payload: data,
  });
  export const deleteSoftware = (data) => ({
    type: DELETE_SOFTWARES,
    payload: data,
  });

  export const deleteSoftwareSuccess = (data) => ({
    type: DELETE_SOFTWARES_SUCCESS,
    payload: data,
  });

  export const deleteSoftwareError = (data) => ({
    type: DELETE_SOFTWARES_ERROR,
    payload: data,
  });
  
  
  
