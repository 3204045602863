import {
    ALL_USER_EXPORT,
    ALL_USER_EXPORT_SUCCESS,
    ALL_USER_EXPORT_ERROR,
    getAllUserExportSuccess,
    getAllUserExportError,
    setAllUserExport,

    ALL_SOFTWARE_EXPORT,
    ALL_SOFTWARE_EXPORT_SUCCESS,
    ALL_SOFTWARE_EXPORT_ERROR,
    getAllSoftwareExportSuccess,
    getAllSoftwareExportError,
    setAllSoftwareExport,

    ALL_HARDWARE_EXPORT,
    ALL_HARDWARE_EXPORT_SUCCESS,
    ALL_HARDWARE_EXPORT_ERROR,
    getAllHardwareExportSuccess,
    getAllHardwareExportError,
    setAllHardwareExport,

    ALL_COMPUTER_EXPORT,
    ALL_COMPUTER_EXPORT_SUCCESS,
    ALL_COMPUTER_EXPORT_ERROR,
    getAllComputerExportSuccess,
    getAllComputerExportError,
    setAllComputerExport
} from "../../actions/Export"
import { apiRequest } from "../../actions/api";
import { notification_types } from "../../../js/constants";
import { setNotification } from "../../actions/notification";

export const allusersFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_USER_EXPORT) {
        dispatch(
            apiRequest(
                "GET",
                "/apiCall.php?apiCall=getAllUsers",
                action.payload,
                null,
                getAllUserExportSuccess,
                getAllUserExportError,
                false,
                "[User]"
            )
        );
    }
};

export const processAllUsers = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_USER_EXPORT_SUCCESS) {
        var data = action.payload.data
        if (data) {
            dispatch(setAllUserExport(data));
        } else {
        }

    }
    if (action.type === ALL_USER_EXPORT_ERROR) {
    }
}


export const allsoftwaresFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_SOFTWARE_EXPORT) {
        dispatch(
            apiRequest(
                "GET",
                "/apiCall.php?apiCall=getAllSoftwares",
                action.payload,
                null,
                getAllSoftwareExportSuccess,
                getAllSoftwareExportError,
                false,
                "[Login]"
            )
        );
    }
};

export const processAllSoftware = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_SOFTWARE_EXPORT_SUCCESS) {
        var data = action.payload.data
        if (data) {
            dispatch(setAllSoftwareExport(data));
        } else {
        }

    }
    if (action.type === ALL_SOFTWARE_EXPORT_ERROR) {
    }
}

export const allhardwareFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_HARDWARE_EXPORT) {
        dispatch(
            apiRequest(
                "GET",
                "/apiCall.php?apiCall=getAllHardwares",
                action.payload,
                null,
                getAllHardwareExportSuccess,
                getAllHardwareExportError,
                false,
                "[Login]"
            )
        );
    }
};

export const processAllHardware = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_HARDWARE_EXPORT_SUCCESS) {
        var data = action.payload.data
        if (data) {
            dispatch(setAllHardwareExport(data));
        } else {
        }

    }
    if (action.type === ALL_HARDWARE_EXPORT_ERROR) {
    }
}


export const allcomputerFlow = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_COMPUTER_EXPORT) {
        dispatch(
            apiRequest(
                "GET",
                "/apiCall.php?apiCall=getAllComputers",
                action.payload,
                null,
                getAllComputerExportSuccess,
                getAllComputerExportError,
                false,
                "[Login]"
            )
        );
    }
};

export const processAllComputer = ({ dispatch }) => (next) => (action) => {
    next(action);

    if (action.type === ALL_COMPUTER_EXPORT_SUCCESS) {
        var data = action.payload.data
        if (data) {
            dispatch(setAllComputerExport(data));
        } else {
        }

    }
    if (action.type === ALL_COMPUTER_EXPORT_ERROR) {
    }
}
export const exportMdl = [
    allusersFlow,
    processAllUsers,
    allsoftwaresFlow,
    processAllSoftware,
    allhardwareFlow,
    processAllHardware,
    allcomputerFlow,
    processAllComputer
];