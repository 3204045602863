import { SET_USERS, USER_EXISTS, SET_USER_ADD_STATUS ,SET_PROFILE_STATUS} from "../actions/Users";
import produce from "immer";

const INITIAL_STATE = {
  users: {
    list:[],
    page:"",
    hasmore:false
  },
  add_status: false,
  editProfileStatus: false
};

export const userReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_USERS:
      draft.users.list = action.payload.list;
      draft.users.page = action.payload.page;
      draft.users.hasmore = action.payload.hasmore;
      break;
    case SET_USER_ADD_STATUS:
      draft.add_status = action.payload;
      break;
    case SET_PROFILE_STATUS:
      draft.editProfileStatus = action.payload;
      break;

    default:
      break;
  }
}, INITIAL_STATE);