import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Modal,
    Form,
    Container,
    Row,
    Col,
    Button,
    Offcanvas
} from "react-bootstrap";
import { connect } from "react-redux";
import { updateProfile } from "../../store/actions/Users"
const AddEdit = ({
    onShow,
    onHide,
    updateProfile,
    username,
    email,
    userId
}) => {
    var addinitial_state = {

        user_name: "",
        password: "",
        conform_password:"",
        email: "",
        userId: "",
        error: {
            email: "",
            user_name: "",
            password: "",
            conform_password:""
        },
    };
    // Set State value using immer
    const [state, setstate] = useImmer(addinitial_state);

    useEffect(() => {
        if (username) {
            setstate((draft) => {
                draft.user_name = username;
            });
        }
        if (email) {
            setstate((draft) => {
                draft.email = email;
            });
        }
        if (userId) {
            setstate((draft) => {
                draft.userId = userId;
            });
        }

    }, [username, userId, email])

    const onInputChange = (e) => {
        setstate((draft) => {
            draft[e.target.name] = e.target.value;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.user_name.length === 0) {
            setstate((draft) => {
                draft.error.user_name = "Please enter the username.";
            });
            return false;
        } else {
            setstate((draft) => {
                draft.error.user_name = "";
            });
        }

        if (state.user_name.length > 256) {
            setstate((draft) => {
              draft.error.user_name = "Please enter upto 256 characters for username.";
            });
            return false;
          } else {
            setstate((draft) => {
              draft.error.user_name = "";
            });
          }


        if (state.email.length === 0) {
            setstate((draft) => {
                draft.error.email = "Please enter the email.";
            });
            return false;
        } else {
            setstate((draft) => {
                draft.error.email = "";
            });
        }

        if (state.email.length > 256) {
            setstate((draft) => {
              draft.error.email = "Please enter upto 256 characters for email.";
            });
            return false;
          } else {
            setstate((draft) => {
              draft.error.email = "";
            });
          }

          if (state.password.length > 256) {
            setstate((draft) => {
              draft.error.password = "Please enter upto 256 characters for password.";
            });
            return false;
          } else {
            setstate((draft) => {
              draft.error.password = "";
            });
          }
        if (state.password !== state.conform_password) {
            setstate((draft) => {
                draft.error.conform_password = "password mismatch.";
            });
            return false;
        } else {
            setstate((draft) => {
                draft.error.conform_password = "";
            });
        }

        const data = {
            apiCall: "updateProfile",
            userName: state.user_name,
            email: state.email,
            password: state.password
        }
        updateProfile(data);

    };
    // To View Password section
    const [statePassView, setStatePassView] = useImmer(false)
    const onPasswordView = () => {
        setStatePassView(!statePassView)
    }
    return (
        <>
            <React.Fragment>
                <Offcanvas

                    show={onShow}
                    onHide={onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    placement="end"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h6 className="font-weight-bold">Edit profile</h6>
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            User name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter User name"
                                            value={state.user_name}
                                            name="user_name"
                                            className={state.error.user_name.length > 0 ? "is-invalid" : ""}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {state.error.user_name.length > 0 && (
                                            <span className="invalid-feedback">{state.error.user_name}</span>
                                        )}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-2" controlId="formBasicFirstName">
                                        <Form.Label className="font-weight-bold requiredField">
                                            Email
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            value={state.email}
                                            name="email"
                                            className={state.error.email.length > 0 ? "is-invalid" : ""}
                                            onChange={onInputChange}
                                            autoComplete="off"
                                        />
                                        {state.error.email.length > 0 && (
                                            <span className="invalid-feedback">{state.error.email}</span>
                                        )}
                                    </Form.Group>
                                </Row>
                                <a href="#" onClick={onPasswordView}>Click to change password </a>
                                {statePassView && (
                                    <>
                                        <Row>
                                            <Form.Group className="mb-2" controlId="formBasicFirstName">
                                                <Form.Label className="font-weight-bold requiredField">
                                                    Password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Enter password"
                                                    value={state.password}
                                                    name="password"
                                                    className={state.error.password.length > 0 ? "is-invalid" : ""}
                                                    onChange={onInputChange}
                                                    autoComplete="off"
                                                />
                                                {state.error.password.length > 0 && (
                                                    <span className="invalid-feedback">{state.error.password}</span>
                                                )}
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-2" controlId="formBasicFirstName">
                                                <Form.Label className="font-weight-bold requiredField">
                                                    Confirm password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Enter confirm password"
                                                    value={state.conform_password}
                                                    name="conform_password"
                                                    className={state.error.conform_password.length > 0 ? "is-invalid" : ""}
                                                    onChange={onInputChange}
                                                    autoComplete="off"
                                                />
                                                {state.error.conform_password.length > 0 && (
                                                    <span className="invalid-feedback">{state.error.conform_password}</span>
                                                )}
                                            </Form.Group>
                                        </Row>
                                    </>
                                )
                                }

                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="btnModelSubmit"
                                onClick={handleSubmit}
                            >
                                Update
                            </Button>
                            <Button className="btnModelClose" onClick={onHide}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Offcanvas>
            </React.Fragment>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps, { updateProfile }
)(AddEdit);
