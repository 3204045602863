import { SET_SOFTWARES,SET_SOFTWARES_ADD_STATUS, SET_SOFTWARE_DETAIL} from "../actions/Softwares";
import produce from "immer";

const INITIAL_STATE = {
    softwares:{
      list:[],
      page:"",
      hasmore:false
    },
    add_status:false,
    softwareDetail: []
};

export const softwaresReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SOFTWARES:
      draft.softwares.list = action.payload.list;
      draft.softwares.page = action.payload.page;
      draft.softwares.hasmore = action.payload.hasmore;
      break;
    case SET_SOFTWARES_ADD_STATUS:
      draft.add_status = action.payload;
      break;
    case SET_SOFTWARE_DETAIL:
      draft.softwareDetail = action.payload;
      break;
    default:
      break;
  }
}, INITIAL_STATE);